import React from "react";
import I18n from "../I18n";
import Stamp from "./Stamp";

function showCountryName(item){
  const { country, item_group } = item.showingItem
  if(!country) return false
  
  return item_group!=='festive'
}

export default class StampRandomItem extends React.PureComponent {
  render() {
    const { item } = this.props;
    if (!item) return null;

    return (
      <div className="col-6 px-0 mb-3 cursor-default d-flex align-items-center">
        <div>
          <Stamp slug={item.showingItem.slug} size={60} />
        </div>
        <div className="ml-0 mx-2 my-2 store-item-desc mr-auto">
          <div className="h6 mb-0 text-primary singleline-desc">{item.title}</div>
          {item.unlocked ? (
            <span className="badge badge-light text-light smallest border mt-1">
              <i className="icon-checkmark text-calm" /> {" " + I18n.t("OWNED")}
            </span>
          ) : showCountryName(item) ? (
            <div className="text-light small singleline-desc">
              {I18n.country(item.country)}
            </div>
          ) : (
            <div className="text-light small singleline-desc">{item.desc}</div>
          )}
        </div>
      </div>
    );
  }
}
