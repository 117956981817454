import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import moment from "../lib/moment";
import { reProfileMe, totalFriends, reFriendLimit } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import Avatar from "../components/Avatar";
import data from '@emoji-mart/data/sets/14/google.json'
import Picker from '@emoji-mart/react'
import { getQuota, updateExtendPref } from "../api/me.api";
import tracking from "../lib/tracking";
import FriendProfile from "../components/FriendProfile";
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

const replyTimeList = [
  'REPLY_TIME_0',
  'REPLY_TIME_1',
  'REPLY_TIME_2',
  'REPLY_TIME_3',
  'REPLY_TIME_4',
  'REPLY_TIME_5',
];
const letterLengthList = [
  'LETTER_LENGTH_0',
  'LETTER_LENGTH_1',
  'LETTER_LENGTH_2',
  'LETTER_LENGTH_3',
  'LETTER_LENGTH_4',
  'LETTER_LENGTH_5',
];


class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      about: !!props.me.about ? props.me.about : "",
      new_request: true,
      automatch: true,
      showPicker: false,
      showProfile: false,
      photo: null,
      audio: null
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    const { me, totalFriends, friendLimit } = this.props;

    this.setState({
      new_request:
        me.new_request >= 1 && totalFriends < friendLimit ? true : false,
      automatch:
        me.new_request === 1 && totalFriends < friendLimit ? true : false
    });

    getQuota({ token: this.props.me.token }).then(result => {
      global.log('photoQuota', result)

      this.setState({
        photo: result.photo,
        audio: result.audio
      })
    })

    // this.props.refreshMe({ token: this.props.me.token })
  }

  emojiSelect = emoji => {
    global.log(emoji);
    this.props.updateMe(this.props.me.token, {
      emoji_status: emoji.native
    });

    tracking.event("update_emojistatus");

    this.setState({
      showPicker: false
    });
  };

  onChange = ev => {
    this.setState({
      about: ev.target.value,
      submitted: false
    });
  };

  _saveAbout = () => {
    this.setState({
      submitted: true
    });

    if (this.state.about === this.props.me.about) return true;

    this.props.updateMe(this.props.me.token, {
      about: this.state.about
    });
  };

  updateRequest = new_request => {
    const { me, updateMe, totalFriends, friendLimit } = this.props;

    if (totalFriends >= friendLimit) {
      return false;
    }

    this.setState({
      new_request: new_request >= 1 ? true : false,
      automatch: new_request === 1 ? true : false
    });

    updateMe(
      me.token,
      {
        new_request
      },
      true
    );
  };

  showEmojiPicker = () => {
    this.setState({
      showPicker: true
    });
  };

  updateAddById = ev => {
    const { me, updatePref } = this.props;

    updatePref(me.token, {
      add_by_id: !!me.add_by_id ? "0" : "1"
    });
  };

  previewMyProfile = () => {
    global.log('preview me', this.props.me);

    const sub_others = _.filter(
      this.props.me.subtopics,
      s => s.parent === 'OTHER_TOPICS' || !s.parent,
    );

    this.setState({
      previewMe: {
        ...this.props.me,
        last_login: this.props.me.show_last_login ? new Date() : null,
        plus: this.props.me.role>=20 && !!this.props.me.show_badge,
        deactivated: 0,
        distance: 0,
        delivery_mins: 35,
        extend: {
          ratio: this.props.me.ratioText,
          stamp_collection:
            !!this.props.me.extend_pref.stamp_collection &&
            this.props.me.extend_pref.stamp_collection.value > 0
              ? {
                  data: this.props.stamp_collection,
                }
              : null,
          fav_stamps: this.props.me.fav,
          letter_length: !!this.props.me.extend_pref.letter_length
            ? this.props.me.extend_pref.letter_length.value
            : -1,
          reply_time: !!this.props.me.extend_pref.reply_time
            ? this.props.me.extend_pref.reply_time.value
            : -1,
          subtopics:
            this.props.me.subtopics.length > 0
              ? {data: this.props.me.subtopics}
              : null,
          account_age: this.props.me.account_age,
          lang: this.props.me.lang
        },
        commonTopics: _.map(this.props.me.tags, tag => {
          return {
            tag,
            subtopics: _.filter(this.props.me.subtopics, s => s.parent === tag),
          };
        }),
        otherTopics: sub_others.length > 0 ? [
          {
            tag: 'OTHER_TOPICS',
            subtopics: sub_others,
          },
        ] : [],
      },
      showProfile: true
    });
  }

  profileClosed = () => {
    this.setState({
      showProfile: false
    })
  }

  onSelectExtendPref = async (type, value) => {
    this.props.saveExtendPref(type, value);
    try {
      const result = await updateExtendPref({
        token: this.props.me.token,
        type,
        value,
      });
      global.log('updateExtendPref success', result);
      tracking.event('profile_updated_' + type);
    } catch (error) {
      global.log('updateExtendPref failed', error);
    }
  };

  render() {
    const { me } = this.props;
    const { photo, audio } = this.state

    // global.log('emoji data', data)
    global.log('me', me)

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu idv_available={this.props.me.idv_available} />
            </div>
            <div className="col-9 p-3  ">
              <div className="card shadow-sm my-2">
                <div className="card-header">
                  <strong>{me.name}</strong>
                  { me.idv && me.idv.status===2 && (
                    <i className="icon icon-shield color-idv ml-1" />
                  )}
                </div>
                <div className="card-body">
                  <div className="w-100 position-relative">
                  <div className="avatar-wrapper-2" dir="ltr">
                    <Avatar
                      key={me.id}
                      uid={me.id}
                      id={me.avatar}
                      gender={me.gender}
                      name={me.name}
                      size={180}
                    />
                    {!this.state.showPicker && (
                      <div
                        className="bubble px-2 d-flex align-items-center link"
                        style={{ width: 68, right: -38 }}
                        onClick={this.showEmojiPicker}
                      >
                        {!!me.emoji_status ? (
                          me.emoji_status
                        ) : (
                          <i className="icon-smile text-lighter mx-1" />
                        )}
                        <i
                          className="icon-chevron-down text-keep-positive ml-1"
                          style={{ fontSize: 16 }}
                        />
                      </div>
                    )}
                  </div>
                  { me.role>=20 && (
                    <div className="plus-badge mb-2">
                      <i className="icon-plus-inline text-gold h5" />
                    </div>
                  )}
                  {!!this.props.me.idv_available && (
                      <div className="text-right mb-n1">
                          <NavLink className="link small color-idv" to="/verify">
                            <i className="icon-shield-outline" /> {I18n.t("GET_VERIFIED")}
                          </NavLink>
                      </div>
                      )}
                </div>
                  <hr />
                  <span className="badge badge-light pl-2 mb-2" dir="ltr">
                    <span
                      className="text-black-50"
                      style={{ fontWeight: "normal" }}
                    >
                      {I18n.t("Slowly ID")}
                    </span>
                    <span
                      className="badge badge-light ml-2 bg-white text-dark text-monospace"
                      style={{ fontSize: 15 }}
                    >
                      {me.slowlyid}
                    </span>
                  </span>
                  <div className="custom-control custom-checkbox ml-1 mb-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="add_by_id"
                      checked={me.add_by_id}
                      onChange={this.updateAddById}
                    />
                    <label className="custom-control-label" htmlFor="add_by_id">
                      {I18n.t("ALLOW_ADD_BY_ID")}
                    </label>
                  </div>

                  <hr />
                  <table className="table table-borderless profile-list table-sm">
                    <tbody>
                      <tr>
                        <td width={30} className="text-center">
                          <i className="icon-cake text-calm"></i>
                        </td>
                        <td>
                          {!me.dob ? (
                            <span className="text-lightest">YYYY-MM-DD</span>
                          ) : (
                            <>
                              {(me.dob_privacy === 3 ||
                                me.dob_privacy === 5 ||
                                me.dob_privacy === 6) &&
                                moment.custom(me.dob, "birthday")}
                              {(me.dob_privacy >= 4 && me.age) &&
                                " (" + me.age + ")"}
                              {!!me.zodiac && me.dob_privacy < 6 && (
                                <span>
                                  {" "}
                                  <i
                                    className={
                                      "icon-" + me.zodiac + " text-lighter"
                                    }
                                  ></i>{" "}
                                  {I18n.t(me.zodiac)}
                                </span>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-td">
                          <i
                            className={
                              me.gender === "male"
                                ? "icon-mars text-calm"
                                : me.gender === "female"
                                ? "icon-venus text-calm"
                                : "icon-circle-o text-calm"
                            }
                          ></i>
                        </td>
                        <td>{I18n.t(me.gender)}</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="icon-pin text-calm"></i>
                        </td>
                        <td>
                          {me.location_code === "REMOVED"
                            ? I18n.t("NA")
                            : I18n.country(me.location_code)}
                        </td>
                      </tr>

                      {!!me.account_age && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-clock text-calm"></i>
                          </td>
                          <td>
                            {I18n.t("JOINED", {
                              time: moment.custom(
                                me.account_age.value,
                                "joined"
                              )
                            })}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="icon-td">
                          <i className="icon-camera text-calm"></i>
                        </td>
                        <td>
                          { I18n.t("PHOTO_QUOTA") }{': '}&nbsp;&nbsp;
                          { !photo ? I18n.t('LOADING') : photo.remain + ' / ' + photo.limit }
                        </td>
                      </tr>
                      { !!photo && (<tr>
                        <td></td>
                        <td className="pt-0 mt-0 small muted text-lighter">
                            { photo.remain===photo.limit ? I18n.t("ALLOW_PHOTOS_QUOTA", {
                              quota: photo.remain,
                              maxquota: 12
                            }) : I18n.t("PHOTOSHARING_RESET_TIME", {
                                time: moment.custom(photo.reset, "fromNow")
                              })
                            }
                        </td>
                      </tr>)}
                      <tr>
                        <td className="icon-td">
                          <i className="icon-mic text-calm"></i>
                        </td>
                        <td>
                          { I18n.t("AUDIO_QUOTA") }{': '}&nbsp;&nbsp;
                          { !audio ? I18n.t('LOADING') : audio.remain + ' / ' + audio.limit }
                        </td>
                      </tr>
                      { !!audio && (<tr>
                        <td></td>
                        <td className="pt-0 mt-0 small muted text-lighter">
                          { audio.remain===audio.limit ? I18n.t("ALLOW_AUDIO_QUOTA", {
                            quota: audio.remain,
                            maxquota: 12
                          }) : I18n.t("PHOTOSHARING_RESET_TIME", {
                              time: moment.custom(audio.reset, "fromNow")
                            })
                          }
                        </td>
                      </tr>)}
                    </tbody>
                  </table>
                  <hr />
                  <div className="small text-light mb-2">
                    {I18n.t("WRITING_PREF")}
                  </div>
                  <table className="table table-borderless table-sm table-v-middle">
                    <tbody>
                      <tr>
                        <td width={30} className="text-center">
                          <i className="icon-pencil text-calm"></i>
                        </td>
                        <td>
                          {I18n.t('LETTER_LENGTH')}
                        </td>
                        <td width={250}>
                          <select
                            className="form-control ml-auto custom-select custom-select-sm"
                            defaultValue={!!me.extend_pref['letter_length'] ? me.extend_pref['letter_length'].value : -1 }
                            onChange={(e)=>{
                              this.onSelectExtendPref(
                                'letter_length',
                                e.target.value
                              );
                            }}
                          >
                            <option value={-1}>{I18n.t('SELECT')}</option>
                            { letterLengthList.map( (item, index) => (
                              <option value={index} key={"letter_length_option-"+index}>{I18n.t(item)}</option>
                            ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td width={30} className="text-center">
                          <i className="icon-clock text-calm"></i>
                        </td>
                        <td>
                          {I18n.t('REPLY_TIME')}
                        </td>
                        <td >
                          <select
                            className="form-control ml-auto custom-select custom-select-sm"
                            defaultValue={!!me.extend_pref['reply_time'] ? me.extend_pref['reply_time'].value : -1 }
                            onChange={(e)=>{
                              this.onSelectExtendPref(
                                'reply_time',
                                e.target.value
                              );
                            }}
                          >
                            <option value={-1}>{I18n.t('SELECT')}</option>
                            { replyTimeList.map( (item, index) => (
                              <option value={index} key={"reply_time_option-"+index}>{I18n.t(item)}</option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <div className="form-group mb-0">
                    <div className="small text-light mb-2">
                      {I18n.t("ABOUT_ME")}
                    </div>
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder={I18n.t("WRITE_ABOUT_ME")}
                      style={{ minHeight: 150 }}
                      value={this.state.about}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="d-flex mx-n1">
                    <button
                      className="btn btn-light btn-block mt-2 mx-1"
                      onClick={this.previewMyProfile}
                    >
                      <i className="icon-eye text-calm mr-2" />
                      {I18n.t("PREVIEW_MY_PROFILE")}
                    </button>
                  <button
                    className="btn btn-primary btn-block mt-2 mx-1"
                    onClick={this._saveAbout}
                  >
                    {!!this.state.submitted ? (
                      <i className="icon-checkmark" />
                    ) : (
                      I18n.t("SAVE")
                    )}
                  </button>
                  </div>
                </div>
              </div>
              {!!this.state.showPicker && (
                <div className="emoji-picker noto-emoji" dir="ltr">
                  <Picker
                    data={data}
                    set="native"
                    previewPosition={'none'}
                    skinTonePosition={'none'}
                    showSkinTones={false}
                    theme={this.props.darkMode ? 'dark' : 'light'}
                    categories={["people","nature","foods","activity","places","objects"]}
                    onEmojiSelect={this.emojiSelect}
                    onClickOutside={() => {
                      this.setState({ showPicker: false });
                    }}
                  />
                  <div
                    className="close link"
                    style={{ top: -10, right: -10, zIndex: 1200 }}
                    onClick={() => {
                      this.setState({ showPicker: false });
                    }}
                  >
                    <i className="icon-close text-white" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <FriendProfile
          show={this.state.showProfile}
          postID={null}
          user={this.state.previewMe}
          handleClose={this.profileClosed}
          readOnly
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    stamp_collection: state.me.collection,
    friendLimit: reFriendLimit(state),
    totalFriends: totalFriends(state),
    darkMode: state.slowly.darkMode
  };
};

const refreshMe = function refreshMe(token) {
  return {
    type: "GOT_TOKEN",
    token,
    skip: true
  };
};

const updateMe = function updateMe(token, updateFields) {
  return {
    type: "UPDATE_ME",
    token,
    updateFields
  };
};

const updatePref = function updatePref(token, updateFields) {
  return {
    type: "UPDATE_PREF",
    token,
    updateFields
  };
};

const saveExtendPref = function saveExtendPref(slug, value) {
  return {
    type: 'SAVE_EXTEND_PREF',
    slug,
    value,
  };
};

export default connect(
  mapStateToProps,
  {
    refreshMe,
    updateMe,
    updatePref,
    saveExtendPref
  }
)(MyProfile);
