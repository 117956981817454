import React from "react";
import I18n from "../I18n";
import { Modal } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import { STATIC_CDN_URL } from "../config/ApiConfig";
import _ from "lodash"
import moment from "moment";

class WeatherModal extends React.PureComponent {

  onClose = () => {
    if(this.props.handleClose) this.props.handleClose()
  }

  render() {
    if(!this.props.weather) return;
    const { show, location_code } = this.props;
    const { data=[], timezone=0 } = this.props.weather;
    if(data.length<=0) return null;

    const now = Date.now()
    const found = _.find(data, d=>{
      return d.dt >= now/1000
    })
    const current = !found ? data[0] : found

    const localTime= moment().utc().add(timezone, 'seconds').startOf('hour')
    const filtered = _.slice(_.filter(data, o => {
      return o.dt >= current.dt
    }), 0, 10 )

    return (
        <Modal
          show={show}
          onHide={this.onClose}
          dialogClassName="weather"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="p-0 w-100 position-relative bg-white">
              <div className="text-light smaller">
                <img
                  src={STATIC_CDN_URL + 'weather/' + current.weather[0].icon + ".png"}
                  alt={current.weather[0].description}
                  style={{
                    height: 24,
                    marginTop: -4,
                    marginRight: 4
                  }}
                />
                { I18n.t('NEAR_NAME', { name: this.props.name })}
              </div>
              <h1 className="text-center text-darker display-2 my-2">
                { I18n.weatherConvert(current.main.temp, location_code) }
              </h1>
              <div className="text-center text-light">
                <i className="icon-droplet text-calm mr-1" />
                { current.main.humidity+'% '}{' '}{' '}
                <i className="icon-wind text-calm  ml-2 mr-1" />
                { I18n.speedConvert(current.wind.speed, location_code) }

              </div>
              <div className="text-center text-light ">
                { I18n.t('LOCAL_TIME')}{': '}{ localTime.format('ddd LT') }
              </div>
              <div className="position-absolute" style={{ top: -10, right: -10 }}>
                <button className="btn btn-default" onClick={this.props.handleClose}>
                  <i className="icon-close h5" />
                </button>
              </div>
            </div>

          </Modal.Header>
          <Modal.Body>
            <div className="weather-wrapper bg-stable">
              <Scrollbars>
                  <table className="table w-100">
                    <tbody>
                      { filtered.map( w => {
                        return (
                          <tr key={w.dt}>
                            <td className="pl-3">
                              {
                                moment.unix(w.dt).utc().add(timezone, 'seconds').format('ddd LT')
                              }
                            </td>
                            <td className="text-right">
                              { I18n.weatherConvert(w.main.temp, location_code) }
                            </td>
                            <td width={70} className="text-center">
                              <img
                                src={STATIC_CDN_URL + "weather/" + w.weather[0].icon + ".png"}
                                alt={w.weather[0].description}
                                style={{
                                  height: 24,
                                  marginTop: -4
                                }}
                                className="pr-3"
                              />
                            </td>

                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
              </Scrollbars>
            </div>
          </Modal.Body>
        </Modal>
    );
  }
}

export default WeatherModal;
