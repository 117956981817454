import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import moment from "../lib/moment";
import { reProfileMe } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import Avatar from "../components/Avatar";
import colors from "../assets/colors";

import _ from 'lodash'
import {
  getHiddenFriends,
} from '../api/posts.api';

class HiddenFriends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: [],
      loaded: false,
      shouldRefresh: false
    };
  }

  getHidden = async() => {
    const { me: { token } } = this.props;
    const result = await getHiddenFriends({ token })

    global.log('getHiddenFriends', result)

    this.setState({
      loaded: true,
      hidden: result
    })
  }

  componentDidMount(){
    this.getHidden();
  }

  componentWillUnmount(){
    if(!!this.state.shouldRefresh){
      const { me: { token } } = this.props;
      this.props.refreshFriends(token)
    }
  }

  _unhide = user => () => {
    const { me: { token } } = this.props;
    this.props.unhide(token, user);

    this.setState({
      hidden: _.reject(this.state.hidden, o => o.id===user.id ),
      shouldRefresh: true
    })
  }

  render() {
    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu idv_available={this.props.me.idv_available} />
            </div>
            <div className="col-9 p-3">
              <div className="card shadow-sm  my-2">
                <div className="card-header">{I18n.t("HIDDEN_USERS_LABEL")}</div>
                <div className="card-body">
                  {
                    !this.state.loaded ? (
                      <small>
                        <span
                          className="spinner-grow spinner-grow-sm mr-2 text-warning"
                          role="status"
                          aria-hidden="true"
                        />
                        {I18n.t("LOADING")}
                      </small>
                    ): this.state.hidden.length===0 && (
                      <p className="small text-light">
                        { I18n.t('NO_HIDDEN') }
                      </p>
                    )
                  }
                  { this.state.hidden.map( friend => (
                    <div key={"friend-"+ friend.id} className="d-flex align-items-center p-2">
                      <Avatar
                        key={friend.id}
                        uid={friend.id}
                        id={friend.avatar}
                        gender={friend.gender}
                        name={friend.name}
                        size={42}
                        borderColor={colors.borderLight}
                      />
                      <div className="ml-3 flex-grow-1 text-primary">
                        <strong>{ friend.name }</strong>
                      </div>
                      <div className="mx-3 small text-light">
                        <i className="icon-envelope mr-1" /> { moment.custom(friend.latest_comment, 'fromNow') }
                      </div>
                      <div className="ml-3 text-right" >
                        <button className="btn btn-light btn-sm" onClick={this._unhide(friend)}>
                          { I18n.t('UNHIDE')}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <p className="small text-light p-3">
                { I18n.t('NO_HIDDEN_MSG') }
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state)
  };
};

const refreshFriends = function refreshFriends(token) {
  return {
    type: 'GET_FRIENDS',
    token
  };
};

const unhide = function unhide(token, user) {
  return {
    type: 'UNHIDE_USER',
    token,
    user
  };
};


export default connect(
  mapStateToProps,
  {
    unhide,
    refreshFriends
  }
)(HiddenFriends);
