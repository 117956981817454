import React from 'react';
import MicRecorder from 'mic-recorder-to-mp3';

export default class MiniRecorder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sec: "00",
      min: "00",
      duration: 0.0,
      playing: false,
      loading: true,
      showRecorder: false,
      recording: false,
      limit: this.props.me.role===20 ? 300 : 30
    };
  }

  secondsToTime = (secs) => {
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    return {
        "min": minutes < 10 ? "0" + minutes : minutes + "",
        "sec": seconds < 10 ? "0" + seconds : seconds + ""
    }
  }

  setupPlayback = () => {
    if(!this.state.audio_path) return false;

    this.player = new Audio(this.state.audio_path);

    if(!!this.state.duration){
      const { min, sec } = this.secondsToTime(this.state.duration)
      this.setState({
        min,
        sec,
        duration: this.state.duration,
        playing: false
      });
    }


    this.player.addEventListener("timeupdate", e => {
      if(!this.state.playing || !!this.unmounted) return true;

      const seconds = e.target.currentTime;
      const { min, sec } = this.secondsToTime(seconds)

      this.setState({
        min,
        sec
      });
    });
    this.player.addEventListener('ended', this.reset);

    this.player.addEventListener('loadedmetadata', e => {
      if(!!this.unmounted) return true;

      this.setState({
        loading: false,
        showRecorder: true
      });
    });
  }

  componentDidMount(){
    if(!!this.props.audio){
      const { min, sec } = this.secondsToTime(this.props.duration)

      this.setState({
        audio_path: this.props.audio,
        min,
        sec,
        duration: this.props.duration,
        playing: false
      }, this.setupPlayback)
    }
  }

  componentWillUnmount() {
    this.unmounted = true
    if(!!this.timerID) clearInterval(this.timerID)

    if(!!this.player){
      if(!!this.state.playing) this.player.pause()
      this.player.removeEventListener("timeupdate", ()=>{});
      this.player.removeEventListener("ended", ()=>{});
      this.player.removeEventListener("loadedmetadata", ()=>{});
    }
  }

  startRecorder = async () => {
    const result = await navigator.mediaDevices.getUserMedia({ audio: true })
    global.log('result: '+ result.active)
    if(!result.active) return false;

    this.recorder = new MicRecorder({
      bitRate: 128
    });

    this.recorder.start()
    this.startCounting()

    this.setState({
      showRecorder: true,
      recording: true,
      playing: false
    })

    this.props.startRecording()
  }

  startCounting = () => {
    this.timerID = setInterval(
      () => this.tick(),
      100
    );
  }

  tick = () => {
    if(!!this.unmounted) return true;
    const duration = this.state.duration + 0.1
    const { min, sec } = this.secondsToTime(duration)

    this.setState({
      duration,
      min,
      sec
    })

    if(duration>=this.state.limit) this.stopRecorder()
  }

  reset = () => {
    if(!!this.unmounted) return true;
    this.setupPlayback()
  }

  stopRecorder = () => {
    clearInterval(this.timerID)

    this.setState({
      showRecorder: true,
      recording: false,
      playing: false
    })

    this.recorder.stop().getMp3().then(([buffer, blob])=>{
      const file = new File(buffer, this.props.me.hashid + '-'+ Date.now() + '.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });
      this.setState({ audio: file, audio_path: URL.createObjectURL(file) })
      this.setupPlayback()

      // window.open(URL.createObjectURL(file))
      global.log(file)

      if(!!this.props.onAudioChange)
      this.props.onAudioChange({
          path: file,
          duration: Math.trunc(this.state.duration),
          uploaded: false
      })
      this.recorder = null;

      this.props.stopRecording()
    })
  }

  toggleAudio = ()=> {
    if(!!this.state.playing){
      this.player.pause();
      this.reset()
      this.setState({ playing: false })
    }else{
      this.setState({
        min: '00',
        sec: '00',
        playing: true
      });
      this.player.play()
    }
  }

  removeAudio = () => {
    this.setState({
      showRecorder: false,
      recording: false,
      playing: false,
      audio: null,
      min: '00',
      sec: '00',
      duration: 0.0
    })

    if(!!this.props.onAudioChange)
    this.props.onAudioChange(null)
  }

  render() {
    const { loading, showRecorder, recording, playing, min, sec } = this.state;

    if(!!loading && !!this.props.audio)
      return (
        <div
          className="btn btn-default btn-toolbar mr-2 text-center pt-2"
        >
          <div className="spinner-border spinner-border-sm text-lighter" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    if(!showRecorder){
      return (
        <button
          type="button"
          className="btn btn-default btn-toolbar mr-2"
          onClick={this.startRecorder}
        >
          <i className="icon-mic" />
        </button>
      )
    }
    if(!!recording)
      return (
        <div className="input-group recorder d-flex link"
          onClick={this.stopRecorder}>
          <div
            className="input-group-prepend flex-grow-1"
          >
            <div
              className="btn btn-danger d-flex w-100 align-items-center"
            >
              <span className="playbtn mr-1 ml-n1 mt-n1">
                  <span className="spinner-grow spinner-grow-sm" />
              </span>

              <span className="clock-text">{ min + ':' + sec }</span>
            </div>

          </div>
          <div className="input-group-append ">
            <div
              className="btn btn-light"
            >
              <i className="icon-checkbox text-primary" />
            </div>
          </div>
        </div>
      );

    //show recorder but stopped recording
      return (
        <div className="input-group recorder d-flex">
          <div
            className="input-group-prepend flex-grow-1"
          >
            <div className="input-group-text link w-100" onClick={this.toggleAudio}>
              <span className="playbtn mr-1 ml-n1">
                <i className={!!playing ? "icon icon-checkbox" : "icon icon-play_arrow"} />
              </span>

              <span className="clock-text">{ min + ':' + sec }</span>
            </div>

          </div>
          <div className="input-group-append ">
            {/* <button
              type="button"
              className="btn  btn-light"
              onClick={this.startRecorder}
            >
              <i className="icon-mic" />
            </button> */}
            <button
              type="button"
              className="btn  btn-light"
              onClick={this.removeAudio}
            >
              <i className="icon-trash-o text-danger" />
            </button>
          </div>
        </div>
      );
  }
}
