import React from "react";
import I18n from "../I18n";
import { Modal } from "react-bootstrap";
import flags from "../lib/flags";
import { getCountry } from "../api/slowly.api";
import { GMAP_KEY } from "../config/ApiConfig";

class CountryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countryInfo: null
    };
  }

  onClose = () => {
    if (this.props.handleClose) this.props.handleClose();
  };

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  loadData = async () => {
    try {
      const result = await getCountry({
        location_code: this.props.location_code,
        locale: this.props.locale
      });
      global.log("countryInfo", result);
      if (!!this.unmounted) return true;

      if (result.result === true) {
        this.setState({
          countryInfo: result,
          loading: false
        });
      } else {
        //not found
        this.setState({ loading: false });
      }
    } catch (error) {
      global.log("getCountry error", error);
      if (!!this.unmounted) return true;
      this.setState({ loading: false });
    }
  };

  render() {
    const { show, location_code } = this.props;
    const { countryInfo } = this.state;

    return (
      <Modal
        key={"modal-" + location_code}
        show={show}
        onHide={this.onClose}
        dialogClassName="country-info"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="w-100 p-0 m-0 h-100 position-relative" dir="ltr">
            {countryInfo && countryInfo.intro && countryInfo.intro.img ? (
              <div
                key={"img-" + countryInfo.intro.img}
                className="cover w-100 h-100"
                style={{
                  backgroundImage: `url(${countryInfo.intro.img})`
                }}
              />
            ) : (
              <iframe
                title={I18n.country(location_code)}
                style={{ width: "100%", height: "100%", border: 0 }}
                loading="lazy"
                allowfullscreen
                src={
                  "https://www.google.com/maps/embed/v1/place?key=" +
                  GMAP_KEY +
                  "&zoom=3&q=" +
                  I18n.country(location_code) +
                  "&language=" +
                  this.props.locale
                }
              ></iframe>
            )}

            {countryInfo && countryInfo.intro && countryInfo.intro.img && (
              <h1
                className="m-2 position-absolute"
                style={{ bottom: 5, left: 10 }}
              >
                {!!flags[location_code] &&  flags[location_code].emoji +' '}
                {I18n.country(location_code)}
              </h1>
            )}
            <div className="position-absolute" style={{ top: 10, right: 10 }}>
              <button
                className="btn btn-default"
                onClick={this.props.handleClose}
              >
                <i className="icon-close h5" />
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-3" dir="ltr">
          {countryInfo && !this.state.loading ? (
            <div className="weather-wrapper">
              {countryInfo.intro && countryInfo.intro.content && (
                <p className="px-2 pre">
                  {countryInfo.intro.content}
                  {countryInfo.intro && countryInfo.intro.source && (
                    <a
                      href={countryInfo.intro.source_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-2 text-lighter link"
                    >
                      <nobr>
                        <i className="icon-info" /> {countryInfo.intro.source}
                      </nobr>
                    </a>
                  )}
                </p>
              )}

              <table className="table w-100 text-left">
                <tbody>
                  <tr>
                    <th className="border-top-0">{I18n.t("Population")}</th>
                    <td className="border-top-0">
                      {I18n.toNumber(countryInfo.data.population, {
                        precision: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>{I18n.t("Area")}</th>
                    <td>
                      {I18n.toNumber(countryInfo.data.area, { precision: 0 })}{" "}
                      km<sup>2</sup>
                    </td>
                  </tr>
                  <tr>
                    <th>{I18n.t("Region")}</th>
                    <td>
                      {countryInfo.data.region === "Oceania" &&
                        I18n.t(countryInfo.data.region) + ", "}
                      {countryInfo.data.subregion
                        ? I18n.t(countryInfo.data.subregion)
                        : I18n.t(countryInfo.data.region)}
                    </td>
                  </tr>
                  <tr>
                    <th>{I18n.t("Language")}</th>
                    <td>
                      {countryInfo.data.languages.map(l => (
                        <div key={l.iso639_1}>
                          {l.nativeName}
                          {l.iso639_1 && " (" + l.iso639_1 + ")"}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>{I18n.t("Currency")}</th>
                    <td>
                      {countryInfo.data.currencies.map(l => (
                        <div key={l.code}>
                          {l.name}
                          {l.symbol && " (" + l.symbol + ")"}
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              {countryInfo.intro && countryInfo.intro.img_caption && (
                <div>
                  {countryInfo.intro.img_link ? (
                    <p className="mt-4 px-2 text-lighter small">
                      <i className="icon-camera pr-1" />
                      <a
                        href={countryInfo.intro.img_link}
                        target="_blank"
                        className="text-lighter"
                        rel="noopener noreferrer"
                      >
                        {countryInfo.intro.img_caption}
                      </a>
                    </p>
                  ) : (
                    <p className="mt-4 px-2 text-lighter small">
                      <i className="icon-camera pr-1" />{" "}
                      {countryInfo.intro.img_caption}
                    </p>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="p-5 text-center small text-lighter">
              {I18n.t("LOADING")}
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default CountryModal;
