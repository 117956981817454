import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe, reParagraphs } from "../selectors";
import { getFriendsDrafts, getMatchesDrafts } from "../api/letters.api";
import { sync, syncLabels } from '../api/drafts.api';
import DraftListItem from "../components/DraftListItem";
import Paragraph from "../components/Paragraph";
import PLabels from "../components/PLabels";
import EditParagraph from "../components/EditParagraph";
import Composer from "../components/Composer";
import _ from "lodash"

class Drafts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: true,
      currentTab: "friends",
      friends: {},
      matches: {},
      selected: null,
      loading: true,
      pFiltered: this.props.paragraphs.data,
      currentTag: 'ALL',
      body: ""
    };

    this.scrollDebounced = _.debounce(this.onScroll, 500)
  }

  componentDidMount() {
    if(this.state.currentTab==='friends') this.refreshData();
    else if(this.state.currentTab==='others') this.refreshMatches();

    window.addEventListener("scroll", this.scrollDebounced, false);
  }

  componentWillUnmount(){
    window.removeEventListener("scroll", this.scrollDebounced, false);
  }

  refreshData = async () => {
    this.setState({ refreshing: true });
    const drafts = await getFriendsDrafts({
      token: this.props.me.token,
      ids: this.props.friendIds,
      page: 1
    });
    global.log("refreshing drafts", drafts);

    this.setState({ refreshing: false, loading: false, friends: drafts }, this.onScroll);
  };

  nextPage = async() => {
    const { matches, friends, currentTab } = this.state;
    if(this.state.loading || currentTab==='paragraphs') return false;
    if(currentTab==='friends' && !friends.next_page_url ) return false;
    if(currentTab==='others' && !matches.next_page_url ) return false;

    this.setState({ loading: true })
    if(currentTab==='friends'){
      const drafts = await getFriendsDrafts({
        token: this.props.me.token,
        ids: this.props.friendIds,
        page: friends.current_page+1
      });
      global.log("loading next page drafts", drafts);

      this.setState({
        refreshing: false,
        loading: false,
        friends: {
          ...drafts,
          data: [
            ...friends.data,
            ...drafts.data
          ]
      } }, this.onScroll);
    }else{
      global.log('matches (before)', matches)
      const drafts = await getMatchesDrafts({
        token: this.props.me.token,
        page: matches.current_page+1
      });
      global.log("loading next page matches drafts", drafts);

      this.setState({
        refreshing: false,
        loading: false,
        matches: {
          ...drafts,
          data: [
            ...matches.data,
            ...drafts.data
          ]
      } }, this.onScroll);
    }
  }

  refreshMatches = async () => {
    if (!this.state.loading) this.setState({ refreshing: true });

    const matches = await getMatchesDrafts({
      token: this.props.me.token,
      page: 1
    });

    global.log("refreshing matches drafts", matches );

    this.setState({ loading: false, refreshing: false, matches }, this.onScroll);
  };

  refreshParagraphs = async()=>{
    global.log('refreshing my paragraphs');
    if (!this.state.loading) this.setState({refreshing: true});
    try {
      const last_sync = this.props.paragraphs.last_sync
        ? this.props.paragraphs.last_sync
        : null;
      const p = await sync({
        token: this.props.me.token,
        last_sync,
      });
      global.log('refreshParagraphs', p);
      const sorted = _.orderBy(p, ['updated_at_ms'], ['desc']);

      if (!last_sync) {
        const labels = await syncLabels({
          token: this.props.me.token,
        });
        const defaultLabels = [
          {
            id: 'DEFAULT_LABEL_GENERAL',
            name: I18n.t('DEFAULT_LABEL_GENERAL'),
          },
          {
            id: 'DEFAULT_LABEL_DAILYLIFE',
            name: I18n.t('DEFAULT_LABEL_DAILYLIFE'),
          },
          {
            id: 'DEFAULT_LABEL_RANDOMTHOUGHTS',
            name: I18n.t('DEFAULT_LABEL_RANDOMTHOUGHTS'),
          },
          {
            id: 'DEFAULT_LABEL_HOMETOWN',
            name: I18n.t('DEFAULT_LABEL_HOMETOWN'),
          },
        ];
        this.props.syncParagraphs({
          drafts: sorted,
          labels:
            labels.data && labels.data.length > 0 ? labels.data : defaultLabels,
          uid: this.props.me.id,
          last_sync: sorted[0] ? sorted[0].updated_at_ms : null,
        });
        setTimeout(() => {
          this.changeTag(this.state.currentTag);
        }, 500);
      } else if (sorted.length > 0) {
        const labels = _.filter(sorted, (d) => d.key === 'labels');
        this.props.syncParagraphs({
          drafts: _.reject(sorted, (d) => d.key === 'labels'),
          labels: labels[0] ? labels[0].data : null,
          uid: this.props.me.id,
          last_sync: sorted[0] ? sorted[0].updated_at_ms : null,
        });
        setTimeout(() => {
          this.changeTag(this.state.currentTag);
        }, 500);
      } else {
        this.setState({
          loading: false,
          refreshing: false,
        });
      }
    } catch (error) {
      global.log('refresh error', error);
      this.setState({
        loading: false,
        refreshing: false,
      });
    }
  }

  changeTag = (tag) => {
    if (tag === 'ALL') {
      this.setState({
        loading: false,
        refreshing: false,
        currentTag: 'ALL',
        pFiltered: this.props.paragraphs.data,
      });
      return true;
    }

    this.setState({
      loading: false,
      refreshing: false,
      currentTag: tag,
      pFiltered: _.filter(this.props.paragraphs.data, {tag: tag}),
    });
  };

  changeTab = target => {
    if (target === this.state.currentTab) return true;
    if (target === "others" && !this.state.matches.current_page) {
      this.setState({
        currentTab: target,
        loading: true,
        refreshing: true
      });
      this.refreshMatches();

      return true;
    }
    if (target === "paragraphs"
      // && !this.props.paragraphs.last_sync
      // && !!this.props.me.paragraph
    ) {
      this.setState({
        currentTab: "paragraphs",
        loading: true,
        refreshing: true
      });
      this.refreshParagraphs();
    }else{
      this.setState({
        currentTab: target,
        loading: false,
        refreshing: false
      });
    }

  };

  selectDraft = item => {
    this.setState({
      selected: item
    });
  };

  _hideEditor = () => {
    this.setState({
      selected: null
    });
  };

  reload = () => {
    this.setState({ refreshing: true })

    setTimeout(()=>{
      if(this.state.currentTab==='friends')
        this.refreshData()
      else if(this.state.currentTab==='others')
        this.refreshMatches()
      else
        this.refreshParagraphs()

    }, 1000)
  }

  syncDraft = (draft, type='friends') => {
    if(type==='friends'){
      this.setState({
        friends: {
          ...this.state.friends,
          data: [
            draft,
            ..._.reject(this.state.friends.data, { post: draft.post })
          ]
        }
      })
    }else{
      this.setState({
        matches: {
          ...this.state.matches,
          data: [
            draft,
            ..._.reject(this.state.matches.data, { user_id: draft.user_id })
          ]
        }
      })
    }
  }

  removeDraft = (postID, type='friends') => {
    if(type==='friends'){
      this.setState({
        friends: {
          ...this.state.friends,
          data: _.reject(this.state.friends.data, { post: postID })
        }
      })
    }else{
      this.setState({
        matches: {
          ...this.state.matches,
          data: _.reject(this.state.matches.data, { user_id: postID })
        }
      })
    }
  }

  onScroll = () => {
    if(this.hasReachedBottom()) this.nextPage();
  };

  hasReachedBottom() {
    return (
      document.body.offsetHeight + document.body.scrollTop >= document.body.scrollHeight
    );
  }

  onChange = (e) => {
    this.changeTag(e.target.value)
  }

  _showEditor = (item) => {
    this.setState({
      showEditParagraph: true,
      selectedParagraph: item
    })
  }

  addParagraph = () => {
    this.setState({
      showEditParagraph: true,
      selectedParagraph: null
    })
  }

  _hideEditParagprah = () => {
    this.setState({
      showEditParagraph: false
    })
  }

  onUpdate = (updated) => {
    this.props.updated(updated, this.props.me.id)
    setTimeout(()=>{
      const p = this.props.paragraphs.data;

      if (this.state.currentTag !== 'ALL') {
        this.setState({
          loading: false,
          refreshing: false,
          pFiltered: _.filter(p, {tag: this.state.currentTag}),
        });
      } else {
        this.setState({
          loading: false,
          refreshing: false,
          pFiltered: p,
        });
      }

      this._hideEditParagprah()
      window.scrollTo(0, 0);
    })
  }

  _manageLabels = () => {
    this.setState({
      manageLabels: true
    })
  }

  _hideManageLabels = () => {
    this.setState({
      manageLabels: false
    })
  }


  render() {
    const { friends, matches, currentTab, selected } = this.state;
    const data = currentTab === "friends" ? friends.data : matches.data;
    const nextPage = currentTab==='friends' ? friends.next_page_url : matches.next_page_url
    const limit = this.props.me.role>=20 ? 200 : 100

    return (
      <div
        className={
          selected
            ? "w-100 main-scroller standalone focus-mode"
            : "w-100 main-scroller"
        }
      >
        <div className="container home-wrapper">
          <div className="row">
            <div className="col-3">
              <div className="sticky-top sidebar full pt-3 pl-1">
                  <small className="font-weight-bold px-2">
                    {I18n.t("DRAFTS")}
                  </small>
                  <hr className="my-2" />
                  <ul className="nav flex-column p-0">
                    <li className="nav-item">
                      <div
                        className={
                          currentTab === "friends"
                            ? "btn-primary text-white btn text-left btn-block mt-2 link"
                            : "btn-default text-light btn text-left btn-block mt-2 link"
                        }
                        onClick={() => {
                          this.changeTab("friends");
                        }}
                      >
                        <i className="icon-users mr-1" /> {I18n.t("PENPALS")}
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        className={
                          currentTab === "others"
                            ? "btn-primary text-white btn text-left btn-block mt-2 link"
                            : "btn-default text-light btn text-left btn-block mt-2 link"
                        }
                        onClick={() => {
                          this.changeTab("others");
                        }}
                      >
                        <i className="icon-user-plus mr-1" />{" "}
                        {I18n.t("MATCHES")}
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        className={
                          currentTab === "paragraphs"
                            ? "btn-primary text-white btn text-left btn-block mt-2 link"
                            : "btn-default text-light btn text-left btn-block mt-2 link"
                        }
                        onClick={() => {
                          this.changeTab("paragraphs");
                        }}
                      >
                        <i className="icon-paragraphs mr-1" />{" "}
                        {I18n.t("MY_PARAGRAPHS")}
                      </div>
                      { currentTab==="paragraphs" && !this.state.manageLabel && (
                          <div className="input-group mt-2">
                            <div className="input-group-prepend">
                              <label className="input-group-text small" htmlFor="labelSelect">
                                {I18n.t('LABEL')}
                              </label>
                            </div>
                            <select
                              id="labelSelect"
                              className="custom-select"
                              value={this.state.currentTag}
                              onChange={this.onChange}
                            >
                              <option className="list-group-item link" value="ALL">
                                { I18n.t('ALL') }
                              </option>
                              { this.props.paragraphs.labels.map( label => {
                                return (
                                  <option
                                    className="list-group-item link"
                                    key={label.id}
                                    value={label.id}
                                  >
                                    { label.name }
                                  </option>
                                )
                              })}
                            </select>
                            <div className="input-group-append">
                              <button
                                className="btn btn-light" type="button"
                                onClick={this._manageLabels}
                                ><i className="icon-cogs" /></button>
                            </div>
                        </div>
                      )}
                    </li>
                  </ul>
              </div>
            </div>
            <div className="col-9 position-relative mb-5 mt-3">
              <div className="card w-100 shadow-sm">
                <div className="card-header font-weight-bold d-flex p-0 align-items-center">
                  <div className="col">
                    {I18n.t("DRAFTS")}
                    <i className="icon-chevron-right mx-2 text-lighter smaller" />
                    {currentTab === "friends"
                      ? I18n.t("PENPALS") : currentTab === "others"
                        ?  I18n.t("MATCHES") : I18n.t('MY_PARAGRAPHS')}
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-default btn-toolbar my-2"
                      onClick={this.reload}
                      disabled={!!this.state.loading || !!this.state.refreshing}
                    >
                      <i className={ !!this.state.loading || !!this.state.refreshing ? "icon-more" : "icon-refresh text-primary"} />
                    </button>
                  </div>
                </div>
                { currentTab==='paragraphs' && (
                  <div className="card-body p-3 bg-stable-darker">
                    { !!this.state.refreshing && (
                      <div className="text-lighter d-flex align-items-center mt-n3" style={{ height: 75 }}>
                        <div className="small">
                          <span
                            className="spinner-grow spinner-grow-sm mr-2 text-warning"
                            role="status"
                            aria-hidden="true"
                          />
                          {I18n.t("LOADING")}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      {
                        (this.state.pFiltered.length===0 && !this.state.loading ) ? (
                          <div className="col text-lighter p-5 text-center">
                            <h1>
                              <i
                                className={ "icon-paragraphs" }
                              />
                            </h1>
                            <strong className="h5">
                              {I18n.t('NO_DRAFTS_IN', {
                                  FOLDER: !this.props.paragraphs.labelKeys[this.state.currentTag]
                                    ? I18n.t('PARAGRAPHS')
                                    : this.props.paragraphs.labelKeys[this.state.currentTag].name,
                                })}
                            </strong>
                            <p>
                              { I18n.t("MY_PARAGRAPHS_SHORT") }
                            </p>
                          </div>
                        ) :  this.state.pFiltered.map( item => {
                        return (
                          <Paragraph
                            item={item}
                            key={item.key}
                            tag={item.tag && this.props.paragraphs.labelKeys[item.tag]}
                            onClick={()=>{
                              this._showEditor(item)
                            }}
                          />
                        )
                      })}
                    </div>

                  </div>
                )}
                { currentTab!=='paragraphs' && (
                  <div className="card-body p-0 bg-white drafts-list">
                    { this.state.refreshing ? (
                      <div className="text-lighter d-flex align-items-center" style={{ height: 75 }}>
                        <div className="small px-3">
                          <span
                            className="spinner-grow spinner-grow-sm mr-2 text-warning"
                            role="status"
                            aria-hidden="true"
                          />
                          {I18n.t("LOADING")}
                        </div>
                      </div>
                    ) : (data && data.length <= 0) ? (
                      <div className="text-lighter p-5 text-center">
                        <h1>
                          <i
                            className={
                              currentTab === "friends"
                                ? "icon-inbox3"
                                : "icon-user-plus"
                            }
                          />
                        </h1>
                        <strong className="h5">
                          {I18n.t("NO_DRAFTS_IN", {
                            FOLDER:
                              currentTab === "friends"
                                ? I18n.t("PENPALS")
                                : I18n.t("MATCHES")
                          })}
                        </strong>
                        <p>
                          {currentTab === "friends"
                            ? I18n.t("NO_DRAFTS_MSG")
                            : I18n.t("NO_MATCHES_DRAFTS_MSG")}
                        </p>
                      </div>
                    ) : (
                      data &&
                      data.map((item, index) => {
                        return (
                          <DraftListItem
                            friend={this.props.contacts[item.post]}
                            draft={item}
                            onClick={() => {
                              this.selectDraft(item);
                            }}
                            key={ item.new_friend ? "draft-" + item.user_id +'_'+index : item.post }
                          />
                        );
                      })
                    )}
                    { (nextPage && !!this.state.loading) && (
                      <div className="text-center bg-white p-3 border-top">
                        <small className="text-lighter">
                          {I18n.t('LOADING')}
                        </small>
                      </div>
                    )}

                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
        {selected && (
          <div className="editor-wrapper fixed-bottom print-hidden">
            <Composer
              postID={selected.post}
              fromDraft={selected}
              hideEditor={this._hideEditor}
              history={this.props.history}
              onSave={this.syncDraft}
              onSent={this.removeDraft}
              isFocus
              standalone
            />
          </div>
        )}
        <EditParagraph
          show={!!this.state.showEditParagraph}
          onHide={this._hideEditParagprah}
          item={this.state.selectedParagraph}
          currentTag={this.state.currentTag}
          labels={this.props.paragraphs.labels}
          labelKeys={this.props.paragraphs.labelKeys}
          me={this.props.me}
          onUpdate={this.onUpdate}
          darkMode={this.props.darkMode}
        />
        { currentTab==='paragraphs' && !this.state.showEditParagraph && this.props.paragraphs.data.length < limit && (
          <button type="button"
            className="btn btn-secondary btn-lg btn-reply shadow-lg print-hidden"
            onClick={this.addParagraph}
           >
            <i className="icon-add" /> { I18n.t('ADD_PARAGRAPH')}
          </button>
        )}
        <PLabels
          show={!!this.state.manageLabels}
          onHide={this._hideManageLabels}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    paragraphs: reParagraphs(state),
    contacts: state.contacts,
    friendIds: state.contacts.friendIds,
    darkMode: state.slowly.darkMode,
  };
};

const updated = function updated(payload, uid) {
  return {
    type: 'SAVE_PARAGRAPH',
    payload,
    uid,
  };
};

const syncParagraphs = function syncParagraphs(payload) {
  return {
    type: 'SYNC_PARAGRAPHS',
    ...payload,
  };
};
export default connect(mapStateToProps, {
  syncParagraphs,
  updated
})(Drafts);
