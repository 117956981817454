import React from 'react';
import Stamp from './Stamp'
import moment from '../lib/moment'
import { NavLink } from "react-router-dom"
import I18n from "../I18n";

export default class LetterSm extends React.Component {
  constructor(props){
    super(props)
    const { item } = this.props
    this.state = {
      isFuture: moment.orig.utc(item.deliver_at) > moment.orig.utc() ? true : false,
      fromNow: moment.custom(item.deliver_at, 'fromNow')
    }
  }

  tick = () => {
    global.log('Letter sm tick')
    this.setState({
      isFuture: moment.orig.utc(this.props.item.deliver_at) > moment.orig.utc() ? true : false,
      fromNow: moment.custom(this.props.item.deliver_at, 'fromNow')
    })
    if(!this.state.isFuture && !!this.timerID) this.revealThis();
  }

  revealThis = () => {
    if(!!this.props.refreshLetters) this.props.refreshLetters()
    clearInterval(this.timerID)
  }

  componentWillUnmount(){
    if(!!this.timerID) clearInterval(this.timerID)
  }

  componentDidMount(){
    const { item, meID } = this.props

    if(
      (moment.orig.utc() >= moment.orig.utc(item.deliver_at).subtract(4,'hours') && !!this.state.isFuture
      && item.user!==meID)
      || item.body===null
    ){
      global.log("Start count down")
      this.timerID = setInterval(
        () => this.tick(),
        1000
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.readAt !== nextProps.readAt ||
      ( this.state.fromNow !== nextState.fromNow) || this.props.item.body !== nextProps.item.body )
  }

  rtlDetect = text => /[\u0590-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/.test(text)

  render() {
    const { item, letterLink, meID, readAt } = this.props;
    const { isFuture, fromNow } = this.state
    const content = !!item.summary ? item.summary : item.body

    if ((!!isFuture && item.user !== meID) || item.body===null )
      return(
        <div  className="col-6 col-xl-4 mb-3 future" >
          <div className="card h-100 rounded-0 letter-sm future no-underline">
          <div className="card-header pr-3 pt-3 pb-0 mb-0">
            <div className="d-flex flex-row bg-white">
              <div className="flex-grow-1" />
              <div className="pr-2 mx-n2"  style={{ paddingTop: 5 }}>
                <i className="icon-stamp" style={{ fontSize: 63, padding: 5 }} />
              </div>
            </div>
          </div>
          <div className="card-body pt-3">
          </div>
          <div className="card-footer pt-0">
            <h6 className="mt-2 mb-1 text-lighter">{item.name}</h6>
            <p className="text-lighter small mb-2">{fromNow}</p>
          </div>
        </div>
        </div>
      )

    return (
      <div  className="col-6 col-xl-4 mb-3" >
        <NavLink className="card shadow-sm h-100 rounded-0 letter-sm link no-underline" to={letterLink}>
          { item.type===3 && (
            <div style={{ position: 'absolute', left:0, top: 0,
              width:'100%', height: '80%',
              overflow: 'hidden'
            }}>
              <img
                alt="..."
                src={ require('../assets/images/happy-bg.png')} style={{ width:'100%' }}
                // resizeMode='cover'
              />
            </div>
          )}

          <div className="card-header pr-3 pt-3 pb-0 mb-0">
            <div className="d-flex flex-row bg-white">
              <div className="flex-grow-1">
                {item.type===7 ? ( 
                  <span className="text-secondary smallest font-weight-bold">{I18n.t('OPEN_LETTER')}</span>
                ) : !!isFuture ? (
                  <i className="icon-send text-calm h5" />
                ) : (!readAt && item.user !== meID) ? (
                  <i className="icon-circle text-danger small"  />
                ) : (
                  <>
                    <i className="icon-done text-lightest" />
                    { !!readAt && (
                      <i className="icon-done text-calm" />
                    )}
                  </>
                )}
              </div>
              <div className="pr-2 position-relative mx-n2">
                <Stamp slug={item.stamp} name={item.stamp} size={74} />
                { item.type===3 && (
                  <div className="gift-icon">
                    {"🎁"}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={item.type=== 3 ? "card-body gift pt-3 text-auto" : "card-body pt-3 text-auto"}  dir="auto">
            <p className="card-text">
              { content}
           </p>
          </div>
          <div className="card-footer pt-0">
            <h5 className="m-0">
            {!!item.attachments && (
              <i className="icon-attachment-2 text-calm" />
            )}
            {item.type===5 && (
              <i className="icon-mic text-calm" />
            )}
            </h5>
            <h6 className="mt-2 mb-1 text-primary">{item.name}</h6>
            <p className="text-black-50 small mb-2">{!!isFuture
                ? moment.custom(item.deliver_at, 'fromNow')
                : moment.custom(item.deliver_at, 'calendar-full')}</p>
          </div>
        </NavLink>
      </div>
    );
  }
}
