import {API_URL, headers, handleApiErrors,  } from '../config/ApiConfig'

export const getTime = () => {
    return fetch(API_URL + '/timestamp',{
      timeout: 2000,
    })
      .then(handleApiErrors)
      .then(res => res.json())
      .then( res => res )
      .catch(error => {
        throw error;
      });
}
  
export const loginApple = (payload) => {
    return fetch(API_URL + '/auth/apple', {
        method: 'POST',
        headers,
        body: JSON.stringify(payload)
    })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
    throw error;
    });
}

export const webAuth = ({ uuid, os, browser, trusted }) => {
  return fetch(API_URL + "/web/auth", {
    method: "POST",
    headers,
    body: JSON.stringify({ uuid, os, browser, trusted }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const loginEmail = ({ email, device, checkpass = false, appCheckToken }) => {
  return fetch(API_URL + "/auth/email/passcode", {
    method: "POST",
    headers:{
      ...headers,
      'X-Firebase-AppCheck': appCheckToken,
    },
    body: JSON.stringify({
      email,
      device: JSON.stringify(device),
      checkpass,
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const checkPasscode = ({email, passcode, device, appCheckToken}) =>{
  return fetch(API_URL + '/auth/email' , {
    method: 'POST',
    headers:{
      ...headers,
      'X-Firebase-AppCheck': appCheckToken,
    },
    body: JSON.stringify({
      email,
      passcode,
      device: JSON.stringify(device),
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const socialLogin = ({ access_token, method, id, device }) => {
  return fetch(API_URL + "/auth/social", {
    method: "POST",
    headers,
    body: JSON.stringify({
      access_token,
      method,
      id,
      device: JSON.stringify(device),
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const ssoCanny = ({ token, appCheckToken }) =>{
  return fetch(API_URL + '/web/sso/canny', {
    method: 'POST',
    headers: {
      ...headers,
      'Authorization': 'Bearer '+ token,
      'X-Firebase-AppCheck': appCheckToken,
    }
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}
