import React from "react";
import Stamp from "./Stamp";
import I18n from "../I18n";
import { Modal } from "react-bootstrap";

export default class OpenLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    this.props.handleClose();
  };

  render() {
    const { item={}, show } = this.props;

    return (
      <Modal
        show={show}
        onHide={this.onClose}
        dialogClassName="modal-lg letter-modal"
        // aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="letter">
          <div className="row px-4 m-0 mt-3">
            <div className="col"></div>
            <div className="col text-right p-0 mr-n3 relative">
              <Stamp slug={item.stamp} name={item.stamp} size={100} />
              <div className="chop print-hidden">
                <div className="chop-img" />
                {!!item.sent_from ? item.sent_from : item.location_code}
              </div>
            </div>
          </div>
          <div dir="auto" className="modal-body pre-wrap">
            { item.body }
          </div>
          <div className="px-3 mt-3 mb-5 text-calm font-weight-bold">
                <i className="icon-hash mr-1" />{ item.language } <br/>
                <i className="icon-hash mr-1" />{ I18n.t(item.topic) }
            </div>
        </Modal.Body>
      </Modal>
    );
  }
}
