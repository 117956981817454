import React from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe, reParagraphs } from "../selectors";
import { sync, syncLabels } from "../api/drafts.api";
import { Scrollbars } from "react-custom-scrollbars";
import Paragraph from "../components/Paragraph";
import Select from "react-select";
import _ from "lodash";
import SlidingPane from "react-sliding-pane";

class MyParagraphs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pFiltered: [...this.props.paragraphs.data],
      currentTag: "ALL",
      labels: [
        { label: I18n.t("ALL"), value: "ALL" },
        ...this.props.paragraphs.labels.map((f) => {
          return {
            label: f.name,
            value: f.id,
          };
        }),
      ],
    };
  }

  changeTag = (e) => {
    const tag = e.value;
    if (tag === "ALL") {
      this.setState({
        loading: false,
        refreshing: false,
        currentTag: "ALL",
        pFiltered: this.props.paragraphs.data,
      });
      return true;
    }

    this.setState({
      loading: false,
      refreshing: false,
      currentTag: tag,
      pFiltered: _.filter(this.props.paragraphs.data, { tag: tag }),
    });
  };

  componentDidMount() {
    global.log("this.props.paragraphs", this.props.paragraphs);
    global.log("this.props.me.paragraph", this.props.me.paragraph);
    if (!this.props.paragraphs.last_sync && !!this.props.me.paragraph) {
      this.refreshParagraphs();
    } else {
      this.setState({ loading: false });
    }
  }

  refreshParagraphs = async () => {
    global.log("refreshing my paragraphs");
    if (!this.state.loading) this.setState({ refreshing: true });
    try {
      const last_sync = this.props.paragraphs.last_sync
        ? this.props.paragraphs.last_sync
        : null;
      const p = await sync({
        token: this.props.me.token,
        last_sync,
      });
      global.log("refreshParagraphs", p);
      const sorted = _.orderBy(p, ["updated_at_ms"], ["desc"]);

      if (!last_sync) {
        const labels = await syncLabels({
          token: this.props.me.token,
        });
        const defaultLabels = [
          {
            id: "DEFAULT_LABEL_GENERAL",
            name: I18n.t("DEFAULT_LABEL_GENERAL"),
          },
          {
            id: "DEFAULT_LABEL_DAILYLIFE",
            name: I18n.t("DEFAULT_LABEL_DAILYLIFE"),
          },
          {
            id: "DEFAULT_LABEL_RANDOMTHOUGHTS",
            name: I18n.t("DEFAULT_LABEL_RANDOMTHOUGHTS"),
          },
          {
            id: "DEFAULT_LABEL_HOMETOWN",
            name: I18n.t("DEFAULT_LABEL_HOMETOWN"),
          },
        ];
        const newLabels =
          labels.data && labels.data.length > 0 ? labels.data : defaultLabels;

        this.props.syncParagraphs({
          drafts: sorted,
          labels: newLabels,
          uid: this.props.me.id,
          last_sync: sorted[0] ? sorted[0].updated_at_ms : null,
        });

        this.setState(
          {
            labels: [
              { label: I18n.t("ALL"), value: "ALL" },
              ...newLabels.map((f) => {
                return {
                  label: f.name,
                  value: f.id,
                };
              }),
            ],
          },
          () => {
            this.changeTag({ value: this.state.currentTag });
          }
        );
      } else if (sorted.length > 0) {
        const labels = _.filter(sorted, (d) => d.key === "labels");
        const newLabels = labels[0] ? labels[0].data : null;

        this.props.syncParagraphs({
          drafts: _.reject(sorted, (d) => d.key === "labels"),
          labels: newLabels,
          uid: this.props.me.id,
          last_sync: sorted[0] ? sorted[0].updated_at_ms : null,
        });

        this.setState(
          {
            labels: [
              { label: I18n.t("ALL"), value: "ALL" },
              ...newLabels.map((f) => {
                return {
                  label: f.name,
                  value: f.id,
                };
              }),
            ],
          },
          () => {
            this.changeTag({ value: this.state.currentTag });
          }
        );
      } else {
        this.setState({
          loading: false,
          refreshing: false,
        });
      }
    } catch (error) {
      global.log("refresh error", error);
      this.setState({
        loading: false,
        refreshing: false,
      });
    }
  };

  render() {
    const { labels = [], currentTag } = this.state;
    const { labelKeys = {} } = this.props.paragraphs;
    const bgColor = !!this.props.darkMode ? " #2a2f39" : "#fff";
    const borderColor = !!this.props.darkMode
      ? "rgba(255,255,255,.15)"
      : "rgba(0,0,0,.2)";

    return (
      <SlidingPane
        className="p-0"
        overlayClassName="slider"
        hideHeader
        width="540px"
        isOpen={this.props.showing}
        onRequestClose={this.props.dismiss}
      >
        <div className="card bg-white h-100">
          <div className="card-header position-relative">
            <div className="position-relative mx-n2">
              <Select
                options={labels}
                isSearchable={false}
                placeholder={I18n.t("LABEL")}
                noOptionsMessage={() => I18n.t("NOT_FOUND")}
                onChange={this.changeTag}
                defaultValue={
                  currentTag &&
                  labelKeys[currentTag] && {
                    label: labelKeys[currentTag].name,
                    value: currentTag,
                  }
                }
                styles={{
                  input: (styles) => ({
                    ...styles,
                    marginLeft: 34,
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    marginLeft: 34,
                  }),
                  singleValue: (styles, { data }) => ({
                    ...styles,
                    marginLeft: 34,
                    color: null,
                  }),
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: null,
                    color: null,
                    borderColor,
                  }),
                  menu: (styles) => ({
                    ...styles,
                    backgroundColor: bgColor,
                  }),
                  container: (styles) => ({
                    ...styles,
                    backgroundColor: bgColor,
                  }),
                }}
                theme={
                  !!this.props.darkMode
                    ? (theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral0: "#000",
                          primary25: "#222",
                          primary: "#333",
                          neutral80: "white",
                          neutral50: "white",
                          primary50: "#222",
                          neutral90: "white",
                        },
                      })
                    : null
                }
              />
              <div className="react-select-avatar">
                <i className="icon-tag p-2 d-block text-lighter" />
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            {!!this.state.loading ? (
              <div
                className="text-lighter d-flex align-items-center mt-n3"
                style={{ height: 75 }}
              >
                <div className="small">
                  <span
                    className="spinner-grow spinner-grow-sm mr-2 text-warning"
                    role="status"
                    aria-hidden="true"
                  />
                  {I18n.t("LOADING")}
                </div>
              </div>
            ) : (
              <Scrollbars className="h-100">
                <div className="row p-2 py-3 no-gutters">
                  {this.state.pFiltered.length <= 0 ? (
                    <div className="col text-lighter p-5 text-center">
                      <h1>
                        <i className={"icon-paragraphs"} />
                      </h1>
                      <strong className="h5">
                        {I18n.t("NO_DRAFTS_IN", {
                          FOLDER: !this.props.paragraphs.labelKeys[
                            this.state.currentTag
                          ]
                            ? I18n.t("PARAGRAPHS")
                            : this.props.paragraphs.labelKeys[
                                this.state.currentTag
                              ].name,
                        })}
                      </strong>
                      <p>{I18n.t("MY_PARAGRAPHS_SHORT")}</p>
                    </div>
                  ) : (
                    this.state.pFiltered.map((item) => {
                      return (
                        <Paragraph
                          col={2}
                          item={item}
                          key={item.key}
                          tag={
                            item.tag &&
                            this.props.paragraphs.labelKeys[item.tag]
                          }
                          insert={this.props.insert}
                          dismiss={this.props.dismiss}
                        />
                      );
                    })
                  )}
                </div>
              </Scrollbars>
            )}
          </div>
        </div>
      </SlidingPane>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {
      me: reProfileMe(state),
      paragraphs: reParagraphs(state),
      darkMode: state.slowly.darkMode,
    };
  };
  return mapStateToProps;
};

const syncParagraphs = function syncParagraphs(payload) {
  return {
    type: "SYNC_PARAGRAPHS",
    ...payload,
  };
};

export default connect(makeMapStateToProps, {
  syncParagraphs,
})(MyParagraphs);
