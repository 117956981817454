import React from "react";
import Avatar from "./Avatar";
import moment from "moment";
import I18n from "../I18n";
import { connect } from "react-redux";
// import colors from "../assets/colors";
// import mailPng from "../assets/images/mail.png";
import { reIncomingCounter } from "../selectors";

import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";

import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Sphere,
  Marker
} from "react-simple-maps";

const geoUrl = require('../lib/world-110m.json')
const colorScale = scaleLinear()
  .domain([0.6, 0.4])
  .range(["#ffe79b", "#ffc300"]);

const geoStyle = {
  default: {
    // fill: "#D6D6DA",
    outline: "none"
  },
  hover: {
    // fill: "#F53",
    outline: "none"
  },
  pressed: {
    // fill: "#E42",
    outline: "none"
  }
}

const nameTagStyle = {
  fontFamily: "system-ui",
  fontSize: 14,
  fontWeight: 'bold',
  fill: '#000',
  stroke: '#fff',
  paintOrder: 'stroke',
  strokeWidth: 2,
  strokeLinecap: "butt",
  strokeLinejoin: "miter"
}

class Incoming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      center: { lat: 1, lng: 1 },
      selected: null,
      zoom: 4,
      currentIndex: 0,
      data:[]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.incoming !== nextProps.incoming &&
      nextProps.incoming.length > 0
    ) {
      let markers = [];
      let index = 0;

      nextProps.incoming.map(marker => {
        const loc = marker.user_location.split(",");
        markers.push({
          ...marker,
          index,
          loc: [Number(loc[0]), Number(loc[1])]
        });
        index++;
        return true;
      });
      if (markers.length)
        this.setState({
          markers,
          selected: markers[0],
          center: markers[0].loc,
          zoom: markers.length > 1 ? 3 : 10
        });
    }
  }

  async componentDidMount() {
    let markers = [];

    let index = 0;

    this.props.incoming.map(marker => {
      const loc = marker.user_location.split(",");
      markers.push({
        ...marker,
        index,
        markerOffset: 0,
        coordinates: [
          Number(loc[1]),
          Number(loc[0])
        ]
      });
      index++;

      return true;
    });

    this.setState({
      markers,
      selected: markers[0],
      center: markers[0].loc,
      zoom: markers.length > 1 ? 4 : 8
    });

    global.log(this.props.locale);

    csv(`/vul.csv`).then((data) => {
      this.setState({data});
    });
  }

  handleMarkerClick = marker => {
    this.setState({
      center: marker.loc,
      zoom: 8
    });

    this.setState({
      selected: marker,
      currentIndex: marker.index
    });
  };

  prev = () => {
    const { markers, currentIndex } = this.state;
    if (currentIndex === 0) return false;

    this.handleMarkerClick(markers[currentIndex - 1]);
  };

  next = () => {
    const { markers, currentIndex } = this.state;
    if (markers.length - 1 === currentIndex) return false;

    this.handleMarkerClick(markers[currentIndex + 1]);
  };

  render() {
    const { markers, selected, currentIndex, data=[] } = this.state;
    const { darkMode=false } = this.props;

    const borderLight = darkMode ? '#181b23' : '#ddd'
    return (
      <div>
        <div className="w-100 tips-bar">
          <span className="text-light small">
            <i className="icon-info-circle mr-2" />
            { I18n.t('LOCATION_TIPS') }
          </span>
        </div>
        <div className="bg-white" style={{ marginHorizontal: 8 }}>
          <ComposableMap
            projectionConfig={{
              rotate: [-10, 0, 0],
              scale: 140
            }}
            projection="geoNaturalEarth1"
            width={640}
            height={400}
          >
            <Sphere stroke={borderLight} strokeWidth={1} />
            <Graticule stroke={borderLight} strokeWidth={1} />
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const d = data.find((s) => s.ISO3 === geo.properties.ISO_A3);
                  return (<Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={ d ? colorScale(d["2017"]) : "#ffeeb8" }
                    style={geoStyle}
                  />)
                })
              }
            </Geographies>
            {markers.map(({ index, coordinates, markerOffset, name }) => (
              <Marker key={index} coordinates={coordinates}>
                <g
                  fill={'#000'}
                  transform={index!==currentIndex ?  "translate(0, -4)" : null}
                >
                  <path d="M0.2-17.4c0,0-0.1,0-0.1,0h-0.2c0,0-0.1,0-0.1,0h0c0,0,0,0-0.1,0l0,0c0,0,0,0,0,0l-11.5-9v12.1c0,0.3,0.1,0.6,0.2,0.9
                  	c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2H9.6c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.5
                  	c0.2-0.2,0.4-0.5,0.5-0.7c0.1-0.3,0.2-0.6,0.2-0.9v-12.1l-11.5,9c0,0,0,0,0,0h0c0,0,0,0,0,0C0.3-17.4,0.3-17.4,0.2-17.4L0.2-17.4z
                  	 M0-19.1c2-1.5,6-4.6,11.9-9.1c0-0.3-0.1-0.6-0.3-0.8c-0.1-0.2-0.3-0.5-0.5-0.7c-0.2-0.2-0.4-0.3-0.7-0.4c-0.3-0.1-0.5-0.2-0.8-0.2
                  	H-9.5c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.5,0.3-0.7,0.4c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.2-0.2,0.5-0.3,0.8
                  	C-9.2-26.2-5.3-23.2,0-19.1z M9.6-32c0.5,0,1.1,0.1,1.5,0.3c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.6,0.8,0.9,1.3c0.2,0.5,0.3,1,0.3,1.6
                  	v13.7c0,0.6-0.1,1.1-0.3,1.6c-0.2,0.5-0.5,0.9-0.9,1.3c-0.4,0.4-0.8,0.7-1.3,0.9c-0.5,0.2-1,0.3-1.5,0.3H-9.5
                  	c-0.5,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.3-0.9c-0.4-0.3-0.6-0.8-0.8-1.3c-0.2-0.5-0.3-1-0.3-1.6V-28c0-0.6,0.1-1.1,0.3-1.6
                  	c0.2-0.5,0.5-0.9,0.8-1.3c0.4-0.4,0.8-0.6,1.3-0.9c0.5-0.2,1-0.3,1.6-0.3H9.6z"/>
                  <ellipse className="st0" cx="0" cy="0" rx="12.7" ry="2"/>
                  <path className={"st1"} d="M0-19.1c2-1.5,6-4.6,11.9-9.1c0-0.3-0.1-0.6-0.3-0.8c-0.1-0.2-0.3-0.5-0.5-0.7c-0.2-0.2-0.4-0.3-0.7-0.4
                  	c-0.3-0.1-0.5-0.2-0.8-0.2H-9.5c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.5,0.3-0.7,0.4c-0.2,0.2-0.4,0.4-0.5,0.7
                  	c-0.1,0.2-0.2,0.5-0.3,0.8C-9.2-26.2-5.3-23.2,0-19.1z"/>
                  <path className={"st1"} d="M0.2-17.4c0,0-0.1,0-0.1,0h-0.2c0,0-0.1,0-0.1,0h0c0,0,0,0-0.1,0l0,0c0,0,0,0,0,0l-11.5-9v12.1
                  	c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2H9.6c0.3,0,0.6-0.1,0.9-0.2
                  	c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.4-0.5,0.5-0.7c0.1-0.3,0.2-0.6,0.2-0.9v-12.1l-11.5,9c0,0,0,0,0,0h0c0,0,0,0,0,0
                  	C0.3-17.4,0.3-17.4,0.2-17.4L0.2-17.4z"/>
                </g>
                { index===currentIndex && (
                  <text
                    textAnchor="middle"
                    y={20}
                    style={nameTagStyle}
                  >
                    {name}
                  </text>
                )}
              </Marker>
            ))}
          </ComposableMap>
        </div>
        <div className="map-overlay-v2 modal-footer">
          {!!selected && (
            <div className="map-overlay-wrapper-v2 d-flex w-100 align-items-center">
              <div
                className={currentIndex === 0 ? "p-2 text-lighter" : "p-2 link"}
                onClick={this.prev}
              >
                <i className="icon-chevron-left" />
              </div>
              <div className="mx-2">
                <Avatar
                  uid={selected.id}
                  gender={selected.gender}
                  borderColor="#fff"
                  id={selected.avatar}
                  size={70}
                />
              </div>
              <div className="flex-grow-1 p-1 ml-1">
                <span className="text-light">
                  <span className="h5 text-primary">{selected.name}</span>
                  <br />
                  {I18n.t(
                    moment.utc(selected.deliver_at) <= moment.utc()
                      ? "NOTI_ARRIVES"
                      : selected.incoming > 1
                      ? "MAP_ARRIVING_MORE"
                      : "MAP_ARRIVING",
                    {
                      total: selected.incoming,
                      time: moment.utc(selected.deliver_at).fromNow()
                    }
                  )}
                </span>
              </div>
              <div
                className={
                  currentIndex === markers.length - 1
                    ? "p-2 text-lighter"
                    : "p-2 link"
                }
                onClick={this.next}
              >
                <i className="icon-chevron-right" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    incoming: state.letters.incoming,
    incomingCounter: reIncomingCounter(state),
    darkMode: !!state.slowly.darkMode
  };
};

export default connect(
  mapStateToProps,
  null
)(Incoming);
