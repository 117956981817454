const colors = {
  positive: "#091d3d",
  calm: "#ffc300",
  stable: "#f8f8f8",
  stableDarker: "#eee",
  white: "#ffffff",
  lighter: "#f1f1f1",
  light: "#ccc",
  borderLight: "#ddd",
  lightGray: "#ccc",
  textLighter: "#999",
  textLight: "#666",
  textPrimary: "#444444",
  textDarker: "#222222",
  success: "#1b7538",
  alert: "#ef473a",
  red: "#b54b51",
  darkTrans: "rgba(9,29,61,.7)",
  whiteTrans: "#ffc300",
  gold: "#D4AF37",
  idv: '#006cff'
};


export default colors
