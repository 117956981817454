import {
  ME_ERROR,
  SHOW_ERROR,
  IS_MAINTENANCE,
  // GOT_TOKEN,
  REFRESH_TOKEN,
  LOGOUT
 } from "../actions/type";
import { put, call, select, takeLatest, delay} from 'redux-saga/effects';
import { getToken, getMyID, getUUID, getRetryCount, getTrust } from '../selectors';
import { refreshWebToken } from '../api/me.api';

function* handleError(action) {
  global.log('handleError', action);

  if(!action.error){
    global.log('unknown error', action);
  }else if(action.error.error==='user_not_found' || action.error.error === 'invalid_user' ){

    yield put({ type: SHOW_ERROR, error: action.error });
    if(action.error.error === 'invalid_user')
    yield put({ type: IS_MAINTENANCE, status: true });

    if(action.error.error==='user_not_found')
    yield put({ type: LOGOUT });

  }else if (action.error.error === 'token_expired' || action.error.error === 'token_invalid' || action.error.error==='invalid_token') {
    const token = !!action.payload.token ? action.payload.token : yield select(getToken);
    const uid = yield select(getMyID);
    const trusted = yield select(getTrust);
    const uuid = yield select(getUUID);

    if(!trusted){
      yield put({ type: SHOW_ERROR, error: { error: 'token_invalid' } });
      yield put({ type: LOGOUT });
      return true;
    }

    try {
      global.log('refresh token now', { token, uuid, uid })
      const response = yield call(refreshWebToken, { token, uuid, uid });
      yield put({
         type: REFRESH_TOKEN,
         token: response.token
      });

      if(!!action.action){
        yield put({
          type: action.action,
          ...action.payload,
          token: response.token
        })
      }else{
        yield put({ type: SHOW_ERROR, error: { error: 'TOKEN_EXPIRED' } });
      }

    } catch (e) {
      global.log(e);
      yield put({ type: SHOW_ERROR, error: { error: 'token_invalid' } });
      yield put({ type: LOGOUT });
    }
  }else if(action.error.error==='SERVER_TIMEOUT' && !!action.action ){
    global.log('Check retry count')
    const retry = yield select(getRetryCount)

    if(retry>3){
      yield put({ ...action, type: SHOW_ERROR })

      yield delay(1000);
      yield put({ type: SHOW_ERROR, error: {
        error: 'SERVER_OFFLINE'
      } })

    }else{
      global.log('waiting to retry')
      yield delay(1000);

      yield put({
        type: action.action,
        ...action.payload
      })
    }
  }else{
    yield put({ ...action, type: SHOW_ERROR })

    if(action.error.error==='maintenance_mode' ||action.error.error==='outdated_version' || action.error.error==='banned_user')
      yield put({ type: IS_MAINTENANCE, status: true });
  }
}

function* errorWatcher(){
  yield takeLatest(ME_ERROR, handleError);
}

const _slowlySaga = [errorWatcher];

export default _slowlySaga
