import React from 'react';
import moment from '../lib/moment'
import I18n from "../I18n";
// import { toast } from "react-toastify";

export default class Paragraph extends React.PureComponent {
  copy = () => {
    navigator.clipboard.writeText(this.props.item.body)
    // toast.info("✔︎ " +I18n.t("COPIED_TO_CLIPBOARD"), {
    //   position: "top-center",
    //   autoClose: 2000,
    //   hideProgressBar: true,
    //   closeOnClick: false,
    //   pauseOnHover: false,
    // });
    if(this.props.dismiss) this.props.dismiss()
  }

  insert = () => {
    if(this.props.insert) this.props.insert(this.props.item.body)
  }

  render() {
    const { item, tag, onClick=null, col=3 } = this.props;

    return (
      <div
        className={ col===3 ? "col-6 col-xl-4 mb-3" : "col-6 mb-2 px-1"}
        key={item.key}
      >
        <div onClick={onClick} className="card paragraph link position-relative" style={{ height: 220 }}>
            <div className="card-body d-flex flex-column">

            { tag && (
              <div className=" mb-3">
              <div className="badge badge-pill badge-secondary text-truncate">{tag.name}</div>
            </div>
            )}
            <div className="flex-fill">
              <p className="card-text">
                {item.summary}
              </p>
            </div>
            <span className="small text-light">
              {moment.custom(item.updated_at, 'calendar-full')}
            </span>
          </div>
          { col!==3 && (
            <div className="hover">
              <div className="bg"/>
              <div className="d-flex flex-column justify-content-center align-items-center h-100 px-2">
                <button className="btn btn-light btn-sm shadow-sm btn-block" onClick={this.copy}>
                  { I18n.t('COPY')}
                </button>
                <button className="btn btn-light shadow-sm btn-sm btn-block" onClick={this.insert}>
                  { I18n.t('INSERT_PARAGRAPH')}
                </button>
              </div>
            </div>
          )}

        </div>
      </div>
    );
  }
}
