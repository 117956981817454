import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe } from '../selectors'
import StaticSideMenu from "../components/StaticSideMenu";
import continent from "../I18n/countries/continent";
import flags from '../lib/flags'
import _ from "lodash"

class ExcludedRegions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      searchText: null,
      countries: [],
      cachedCountries: [],
      isFocus: false,
      selected: [],
      saved: false,
      maxExclude: props.me.role===20 ? 10 : 5,
      ready: false
    };
  }

  componentDidMount(){
    const { me } = this.props;

    // const countries = I18n.getCountries();
    const newArray = _.uniq(_.flatten(_.values(continent)));
    global.log('flattened country', newArray);

      const temp = newArray.map(code => {
        return {
          code,
          name: "",
          selected: me.excluded_countries.indexOf(code) > -1
        };
      });
      this.setState({
        countries: me.excluded_countries.length
          ? temp.filter(c => !!c.selected)
          : temp,
        currentTab: me.excluded_countries.length > 0 ? "SELECTED" : "ALL",
        cachedCountries: temp,
        selected: [...me.excluded_countries],
        ready: true,
        maxExclude:me.role===20 ? 10 : 5
      }, ()=>{
        global.log(this.state)
      });
  }

  switchTab = tabName => {
    if (tabName === this.state.currentTab) return false;
    this.setState({
      ready: false
    });

    const allCountries = this.state.cachedCountries;
    const { selected } = this.state;

    switch (tabName) {
      case "ALL":
        this.setState({
          currentTab: tabName,
          countries: allCountries.map(c => {
            return {
              ...c,
              selected: selected.indexOf(c.code) > -1
            };
          }),
          ready: true
        });
        break;

      case "SELECTED":
        this.setState({
          currentTab: tabName,
          countries: allCountries
            .filter(l => this.state.selected.indexOf(l.code) > -1)
            .map(c => {
              return {
                ...c,
                selected: selected.indexOf(c.code) > -1
              };
            }),
          ready: true
        });
        break;

      case "Africa":
      case "South America":
      case "North America":
      case "Asia":
      case "Europe":
      case "Oceania":
        this.setState({
          currentTab: tabName,
          countries: allCountries
            .filter(l => continent[tabName].indexOf(l.code) > -1)
            .map(c => {
              return {
                ...c,
                selected: selected.indexOf(c.code) > -1
              };
            }),
          ready: true
        });
        break;
      default:
        return true;
    }
  };


  toggleCountry = c => {
    const { selected, maxExclude } = this.state;
    if(selected.length >= maxExclude && selected.indexOf(c.code) < 0 ) return false;

    this.setState({
      touched: true,
      selected: selected.indexOf(c.code) >= 0
          ? _.pull(selected, c.code)
          : _.concat(selected, c.code)
    })
  }

  _syncExcludeRegions = () => {
    if(!this.state.touched) return false;

    this.props.updateMe(this.props.me.token, {
      excluded_countries: this.state.selected.toString()
    });

    this.setState({
      touched: false,
      saved: true
    })
  };

  render() {
    const { countries, maxExclude, selected } = this.state;
    const menuItems = [
      "SELECTED",
      "ALL",
      "Africa",
      "North America",
      "South America",
      "Asia",
      "Europe",
      "Oceania"
    ];

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu idv_available={this.props.me.idv_available} />
            </div>
            <div className="col-9 p-3 h-100">

              {/* TAGS */}
              <div className="card shadow-sm my-2 ">
                <div className="card-header with-tabs" >
                  <strong>{I18n.t('EXCLUDED_COUNTRIES')}</strong>
                  <span className={ selected.length >= maxExclude ? "ml-2 badge badge-danger" : "ml-2 badge badge-light"}>
                    {selected.length} / {maxExclude}
                  </span>
                  <ul className="nav nav-tabs card-header-tabs mt-2">
                    { menuItems.map( m => (
                      <li className="nav-item smaller" key={m}>
                        <div className={this.state.currentTab===m ? "nav-link active link" : "nav-link link"}
                          onClick={ ()=>{ this.switchTab(m) }}
                        >
                          {I18n.t(m)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="card-body regions-holder px-2 overflow-auto"  dir="ltr">

                    <div className="row no-gutters">
                      { countries.map( c =>{
                        return (
                          <div className="col-6 d-flex" key={c.code}>
                            <button
                            className={ selected.indexOf(c.code)>=0 ?
                              "btn btn-light bg-stable btn-block text-left px-3 py-2 m-1 mb-2 link topic d-flex align-items-center"
                            : "btn btn-default btn-block text-left px-3 py-2 m-1 mb-2 link topic d-flex align-items-center"}
                            key={c.code} onClick={()=>{ this.toggleCountry(c) }}>
                               <span className='mr-2 '>{
                                 !!flags[c.code] ? flags[c.code].emoji : null
                               }</span>
                              <span className="text-dark flex-fill">{ I18n.country(c.code) }</span>
                              { selected.indexOf(c.code)>=0 && (
                                <i className="icon-checkbox-checked text-primary " />
                              )}

                          </button>
                        </div>
                      )
                      })}
                    </div>
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-primary btn-block px-3"
                    disabled={ selected.length > maxExclude || !this.state.touched }
                    onClick={this._syncExcludeRegions}
                    >
                      { (!!this.state.saved && !this.state.touched) ? (
                        <i className="icon-checkmark text-secondary" />
                      ) : I18n.t('SAVE')}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state)
  };
};

const updateMe = function updateMe(token, updateFields) {
  return {
    type: "UPDATE_ME",
    token,
    updateFields
  };
};

export default connect(
  mapStateToProps,
  {
    updateMe
  }
)(ExcludedRegions);
