import {
  SYNC_PARAGRAPHS,
  SYNC_P_LABELS,
  SAVE_PARAGRAPH,
  DEL_PARAGRAPH,
  LOGOUT
} from '../actions/type';
import _ from 'lodash'

const defaultState = {};

const _drafts = (state = defaultState, action) => {
  switch (action.type) {
    case SYNC_PARAGRAPHS:
      //v7.0+
      const labels = action.labels ? action.labels : state['p-'+action.uid] ? state['p-'+action.uid].labels : []
      return {
        ...state,
        ['p-'+action.uid]: {
          data: _.reject(_.uniqBy(state['p-'+action.uid] ? [
            ...action.drafts,
            ...state['p-'+action.uid].data
          ] : action.drafts, 'key'), d=> !d.body),
          labels,
          labelKeys: _.keyBy(labels,'id'),
          last_sync: action.last_sync
        }
      }

    case SYNC_P_LABELS:
      //v7.0+
      return {
        ...state,
        ['p-'+action.uid]: state['p-'+action.uid] ? {
          ...state['p-'+action.uid],
          labels: action.labels ? action.labels.data : [],
          labelKeys: action.labels ? _.keyBy(action.labels.data,'id') : {}
        }: {
          data: [],
          labels: [...action.labels.data],
          labelKeys: _.keyBy(action.labels.data,'id')
        }
      }


    case SAVE_PARAGRAPH:
      if(['p-'+action.uid])
        return{
          ...state,
          ['p-'+action.uid]: {
            ...state['p-'+action.uid],
            data: _.reject([
              action.payload,
              ..._.reject(state['p-'+action.uid].data, { key: action.payload.key })
            ], d=> !d.body)
          }
        }
      else
        return state;

    case DEL_PARAGRAPH:
      if(['p-'+action.uid])
        return{
          ...state,
          ['p-'+action.uid]: {
            ...state['p-'+action.uid],
            data: _.reject(state['p-'+action.uid].data, { key: action.key })
          }
        }
      else
        return state;

    case LOGOUT:
      return defaultState

    default:
      return state;
  }
}

export default _drafts
