import React, { Component } from "react";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import StampDetailModal from "../components/StampDetailModal";
import StampStoreOverview from "../components/StampStoreOverview";
import StampStoreList from "../components/StampStoreList"; 
import StampStoreRandom from "../components/StampStoreRandom"; 
import StampStoreMenu from "../components/StampStoreMenu";
import _ from "lodash";
import hashids from "../lib/hashids"

import I18n from "../I18n";
import { syncCoins } from "../api/me.api"
import AppAlert from "../components/AppAlert";
import { toast } from "react-toastify";
import {
  CDN_URL
} from "../config/ApiConfig"
import moment from "moment"

class StampStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: null,
      showingStamp: null,
      showSurvey: false,
    };
    this.refreshStampThrottle = _.throttle(this.refreshStamps, 10000, { 'leading': false, 'trailing': true });
  }

  componentDidMount(){
    const {
      email, name, id, gender, avatar, created_at
      // location_code
     } = this.props.me

    if(!!email){
      const avatarURL = avatar.length <=2 ? CDN_URL + "/assets/images/avatar/"+gender + "/" + avatar + ".png"
                                : CDN_URL + "/assets/images/avatar/other/" + avatar + ".png"
      window['identifyCanny']({
        // Replace these values with the current user's data
        email,
        name,
        id,
        avatarURL,
        created: moment(created_at).format()
      })
    }
  }

  componentWillUnmount() { 
    global.log("store onunmount, flush now");
    this.refreshStampThrottle.flush();
  }

  onSuccess = async (coins) => {
    this.setState({
      surveyAvailable: false,
    });

    try {
      const record = await syncCoins({
        token: this.props.me.token,
      });
      if (record.coins > this.props.me.coins) {
        toast.info("💰 " + I18n.t("COINS_ADDED", { coins: coins }), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          closeOnClick: true,
        });
      }
      this.props.refreshCoins(record.coins);
    } catch (error) {
      global.log("syncCoins error", error);
    }
  };

  refreshStamps = () => {
    global.log('refresh stamps now');
    this.props.refreshStamps()
  }
  
  showStamp = (item) => {
    if(item.collection_type==='random'){
      this.setState({ showingRandom: item })
      this.props.history.push("/store/random/"+hashids.encode(item.id));
    }else if(item.isSeries){
      this.setState({ seriesTitle: item.title })
      this.props.history.push("/store/series/"+item.collection_type);
    }else{
      this.setState({
        showing: item,
        showStampModal: true,
        checking: true
      });
    }
  };

  hideStamp = () => {
    this.setState({
      showStampModal: false,
    });
  };

  purchaseCoins = () => {
    this.closeAlert();
    this.props.history.push("/topup");
  };

  render() {
    const {
      showing = false,
      showStampModal = false
    } = this.state;
    
    const { params=null } = this.props.match
    return (
      <div className="w-100 store-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <StampStoreMenu 
                showStamp={this.showStamp}
              />
            </div>
            <div className="col-9">
              { !!params && params.mode==='list' ? (
                <StampStoreList
                  showStamp={this.showStamp}
                  list={params.list}
                  purchaseCoins={this.purchaseCoins}
                />
              ) : !!params && params.mode==='random' ? (
                <StampStoreRandom
                  item={this.state.showingRandom}
                  list={params.list}
                  newPurchase={this.refreshStampThrottle}
                  purchaseCoins={this.purchaseCoins}
                />
              ) : !!params && params.mode==='series' ? (
                <StampStoreList
                  showStamp={this.showStamp}
                  list={'series'}
                  collection={params.list}
                  seriesTitle={this.state.seriesTitle}
                />
              ): (
                <StampStoreOverview
                  showStamp={this.showStamp}  
                />
              )}
            </div>
          </div>
        </div>
        <StampDetailModal
          showing={showing}
          showStampModal={showStampModal}
          hideStamp={this.hideStamp}
          purchaseCoins={this.purchaseCoins}
          newPurchase={this.refreshStampThrottle}
        />
        <AppAlert alert={this.state.alert} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    uuid: state.slowly.uuid,
    pollfishDeclined: !!state.slowly.pollfishDeclined,
  };
};

const refreshCoins = function refreshCoins(coins, from='stampStore') {
  return {
    type: "REFRESH_COINS",
    coins,
    from
  };
};

const syncSlowly = function syncSlowly(token) {
  return {
    type: "CHECK_SLOWLY",
    token,
  };
};
const refreshStamps = function refreshStamps(isSet = false) {
  return {
    type: "REFRESH_STAMPS",
    isSet,
  };
};

const refreshStampsSuccess = function refreshStampsSuccess({
  items = null,
  newStamps = null,
  isSet = null,
}) {
  return {
    type: "REFRESH_STAMPS_SUCCESS",
    items,
    newStamps,
    isSet,
  };
};

const refreshFriend = function refreshFriend(postID) {
  return {
    type: "REFRESH_FRIEND",
    postID,
  };
};

const _declinePolfish = function _declinePolfish() {
  return {
    type: "POLLFISH_DECLINE",
  };
};

export default connect(mapStateToProps, {
  refreshCoins,
  syncSlowly,
  refreshFriend,
  refreshStamps,
  refreshStampsSuccess,
  _declinePolfish
})(StampStore);
