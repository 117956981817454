import React from "react";
import StampSmall from './StampSmall'
import I18n from "../I18n";
import {
  reCollection
} from "../selectors";
import { connect } from "react-redux";

class StampCollection extends React.PureComponent {
  onClose = () => {
    this.props.onClose()
  }

  renderCollectionStamp = (item, index) => {
    if(item.got){
      return (
        <button
          key={'item'+ item.id}
          className="fav-stamp btn btn-default my-1 text-center stamp-zoomable"
          onClick={()=>{ this.props.showStamp(item.slug)}}
        >
          <StampSmall slug={item.slug} size={80} darkMode={this.props.darkMode} />
        </button>
      )
    }else{
      return(<div key={'item'+ item.id} className="fav-stamp my-1">
        <div className="stamp-empty">
          {item.country ? item.country : item.name}
        </div>
      </div>)
    }
  }

  render() {
    const { collection=[], gotCountry=0, total=0 } = this.props.data;
    const { user, onToggle, toggleValue } = this.props

    return (
      <div className="stamp-collection">
        <div className="collection-info sticky-top ">
        <div className="d-flex py-1 px-2">
          {!!onToggle && (
            <div className="flex-fill ml-1">
              <div className="custom-control custom-switch align-items-center">
                <input
                  type="checkbox"
                  className="custom-control-input link"
                  checked={toggleValue}
                  onChange={onToggle}
                  id="showStampCollectionToggle"
                />
                <label
                  className="custom-control-label link"
                  htmlFor="showStampCollectionToggle"
                  style={{ lineHeight: '1.4rem' }}>
                  {I18n.t('SHOW_IN_PROFILE')}
                </label>
              </div>
            </div>
          )}
          <div className={ user ? "flex-fill text-center" : "flex-fill text-right align-items-center"}>
            { !!user && (
              <span ><i className="icon-user-circle mr-2 text-lighter" />{user}</span>
            )}
            <i className="icon-stamp-small-01 ml-2 mr-2 text-lighter" />{total}
            <i className="icon-globe ml-2 mr-2 text-lighter" />{gotCountry}
          </div>
          </div>
        </div>
        <div className="d-flex flex-wrap" dir="auto">
            { collection.map(this.renderCollectionStamp) }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    data: reCollection(state, props),
    darkMode: state.slowly.darkMode
  };
};

export default connect(mapStateToProps, {})(StampCollection);
