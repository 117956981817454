import {
  VERSION_UPGRADED,
  FETCH_SLOWLY_SUCCESS,
  NAME_CHECK_AND_UPDATE,
  NAME_CHECK,
  NAME_CHECK_DONE,
  CHANGE_ORIENTATION,
  UPDATE_ME_SUCCESS,
  UPDATE_AUTO_LOCATION,
  UPDATE_PHOTO_REQUEST_REMINDER,
  UPDATE_SEND_LETTER_REMINDER,
  REG_DEVICE,
  USE_STAMP_TIPS_SHOWN,
  GET_LOCATION,
  SAVE_PERMISSION,
  IS_MAINTENANCE,
  SAVE_SLOWLY_VERSION,
  RESTORE_DATA,
  SHOW_ERROR,
  LOGOUT_COMPLETE,
  INIT_APP,
  INIT_APP_COMPLETE,
  SYNC_STORE_COMPLETE,
  NEW_TAG_SEEN,
  SAVE_UI_LOCALE_SUCCESS,
  SAVE_AVATAR_DATA,
  UPDATE_AVATAR_INDEX,
  GOT_UUID,
  SWITCH_DARKMODE,
  COFFEE_PROMOTED,
  CACHE_STORIES,
  CACHE_DISCOVER,
  POLLFISH_DECLINE,
  TRUST_DEVICE
} from "../actions/type";

import moment from "moment";
import { WEB_VERSION, WEB_VERSION_INT } from "../config/ApiConfig";
import preloadedData from "../lib/core.json";

const defaultState = {
  ver: 1.0, //dataVer
  appVer: WEB_VERSION_INT,
  appVerString: WEB_VERSION,
  appMinVer: 1.0,
  appLatestVer: 1.0,
  lang: [],
  items: [],
  tags: [],
  friendLimit: 100,
  topicLimit: 30,
  sharequota: 10,
  sharequotareset: 12,
  locale: "en",
  customLocale: null,
  checkName: {
    loading: false,
    success: false,
    error: null
  },
  permission: {},
  onesignal: {},
  fcm: null,
  maintenance: false,
  ios: "id1199811908",
  android: "com.slowlyapp",
  upgradeAsked: false,
  upgraded: false,
  orientation: null,
  loading: true,
  sendLetterReminder: true,
  photoRequestReminder: true,
  stampTipsShown: false,
  store: {
    status: 0,
    latest: null,
    new: false
  },
  new_tag: false,
  uuid: null,
  alert: null,
  darkMode: false,
  trust: true,
  ...preloadedData
};

const _slowly = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        draftsAlert: true,
        appVer: defaultState.appVer,
        appVerString: defaultState.appVerString,
        ver: state.appVer !== WEB_VERSION_INT ? 0 : state.ver, // force clear data
        loading: true
      }
    case POLLFISH_DECLINE:
      return {
        ...state,
        pollfishDeclined: true
      }
    case CACHE_STORIES:
      return{
        ...state,
        storyTitle: action.storyTitle,
        stories: action.stories,
        stories_cached_key: action.cached_key
      }

    case CACHE_DISCOVER:
      return{
        ...state,
        discover: action.discover,
        discover_cached_key: action.cached_key
      }

    case COFFEE_PROMOTED:
      return {
        ...state,
        coffeePromoted: true
      }
    case SWITCH_DARKMODE:
      return {
        ...state,
        darkMode: action.darkMode
      }
    case GOT_UUID:
      return {
        ...state,
        uuid: action.uuid
      }
    case TRUST_DEVICE:
      return {
        ...state,
        trust: action.trust
      }
    case VERSION_UPGRADED:
      return{
        ...state,
        upgraded: true
      }
    case UPDATE_AVATAR_INDEX:
      return {
        ...state,
        avatarData: {
          ...state.avatarData,
          last_index: state.avatarData.latest_index
        }
      }
    case SAVE_AVATAR_DATA:
      return {
        ...state,
        avatarData: action.avatarData,
        newAvatar: false
      }

    case NEW_TAG_SEEN:
      if (!state.new_tag) return state;

      return {
        ...state,
        new_tag: false
      };

    case USE_STAMP_TIPS_SHOWN:
      return {
        ...state,
        stampTipsShown: true
      };

    case SYNC_STORE_COMPLETE:
      return {
        ...state,
        store: {
          ...state.store,
          ...action.store
        },
        newAvatar: !state.avatarData ? true : 
        (!!action.store.avataritems && action.store.avataritems > state.avatarData.last_update_avatar) ? 
        true : state.newAvatar
      };
      
    case REG_DEVICE:
      if(!!action.fcm || action.fcm===0)
        return {
          ...state,
          fcm: action.fcm===0 ? null : action.fcm
        };
      else
        return state

    case CHANGE_ORIENTATION:
      return {
        ...state,
        orientation: action.mode
      };

    case UPDATE_PHOTO_REQUEST_REMINDER:
      return {
        ...state,
        photoRequestReminder: action.value
      };

    case UPDATE_SEND_LETTER_REMINDER:
      return {
        ...state,
        sendLetterReminder: action.value
      };

    case RESTORE_DATA:
      return {
        ...state,
        ...action.payload.slowly,
        upgraded: true
      };

    case UPDATE_AUTO_LOCATION:
      return {
        ...state,
        // locLastUpdate: null,
        disableAutoLoc: action.disabled
      };

    case GET_LOCATION:
      return {
        ...state,
        locLastUpdate: !!state.disableAutoLoc
          ? moment.utc().format("YYYY-MM-DD h:mm")
          : null
      };
    case SAVE_SLOWLY_VERSION:
        return {
          ...state,
          ...action.data
        };

    case SAVE_PERMISSION:
      return {
        ...state,
        permission: {
          ...state.permission,
          [action.permission]: action.result
        }
      };
    case FETCH_SLOWLY_SUCCESS:
      const {
        ver,
        sharequota,
        sharequotareset,
        lang = [],
        items = [],
        tags = [],
        idv_countries = []
      } = action.data;

      global.log("slowly", action.data);

      return {
        ...state,
        ver,
        lang,
        items,
        tags,
        sharequota,
        sharequotareset,
        idv_countries,
        new_tag:
          state.tags.length === 0
            ? false
            : tags.length !== state.tags.length
              ? true
              : false
      };

    case NAME_CHECK_AND_UPDATE:
      return {
        ...state,
        checkName: {
          loading: true,
          success: false,
          error: null
        }
      };

    case NAME_CHECK:
      return {
        ...state,
        checkName: {
          loading: true,
          success: false,
          error: null
        }
      };

    case NAME_CHECK_DONE:
      return {
        ...state,
        checkName: {
          loading: false,
          success: action.success,
          error: action.error
        }
      };

    case UPDATE_ME_SUCCESS:
      if (!!state.checkName.loading) {
        return {
          ...state,
          checkName: {
            loading: false,
            success: true,
            error: null
          }
        };
      }
      return state;

    case SHOW_ERROR:
      if (action.error.error === "SERVER_OFFLINE" || action.error.error === "outdated_version") {
        return {
          ...state,
          maintenance: true
        };
      }
      return state;

    case LOGOUT_COMPLETE:
      return {
        ...state,
        loading: false,
        onesignal: {},
        fcm: null,
        maintenance: false,
        locLastUpdate: null,
        disableAutoLoc: false,
        discover: null,
        discover_cached_key: null
      };

    case IS_MAINTENANCE:
      return {
        ...state,
        loading: false,
        maintenance: action.status
      };

    case INIT_APP_COMPLETE:
      return {
        ...state,
        loading: false
      };

    case SAVE_UI_LOCALE_SUCCESS:
      return {
        ...state,
        isRTL: (action.locale==='he' || action.locale==='ar') ? true : false,
        locale: !!action.locale ? action.locale : state.locale,
        customLocale: !!action.customLocale ? action.customLocale : state.customLocale
      };

    default:
      return state;
  }
};

export default _slowly
