import React from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe, reMyItems } from "../selectors";
import { Scrollbars } from "react-custom-scrollbars";
import Stamp from "../components/Stamp";
import _ from "lodash";
import SlidingPane from "react-sliding-pane";
import Avatar from "../components/Avatar";
import { userProfileExtend } from "../api/explore.api";

const icons = {
  NEWEST_FIRST: "clock",
  STAMP_ACHIEVEMENTS: "trophy",
  STAMP_PREMIUM: "stars",
  STAMP_SPECIAL_DAYS: "calendar",
  STAMP_SET: "grid",
  STAMP_LOCATION_BASED: "pin",
  FAV_STAMPS: "bookmark",
};

const menuItems = [
  "NEWEST_FIRST",
  "STAMP_PREMIUM",
  "STAMP_SET",
  "STAMP_ACHIEVEMENTS",
  "STAMP_SPECIAL_DAYS",
  "STAMP_LOCATION_BASED",
];

const ITEM_GROUP_SET = {
  bff: "#BFF",
  "eu-castles": "Castles in Europe",
  music: "Sound of the World",
  sports: "sports",
  lighthouse: "Beautiful Lighthouses",
};
const STAMP_BASE_CLASS = "col-4 text-center my-2 link stamp-list-item";

class StampSelection extends React.Component {
  constructor(props) {
    super(props);
    const extendLoaded = _.has(props.friend, "extend");
    const stamp_collection = _.has(props.friend, "extend.stamp_collection.data")
      ? props.friend.extend.stamp_collection.data
      : false;

    this.state = {
      loading: false,
      items: [],
      currentTab: "",
      extendLoaded,
      stamp_collection,
      highlightFriends: false,
    };

    this.onBodyChangeDelayed = _.throttle(this.searchNow, 500);
  }

  componentDidMount() {
    this.props.me.bookmarks.length > 0
      ? this.switchTab("FAV_STAMPS", true)
      : this.switchTab("NEWEST_FIRST", true);

    if (this.props.friend && !_.has(this.props.friend, "extend"))
      this.loadExtend();
  }

  toggleHighlight = (highlightFriends) => {
    this.setState({ highlightFriends }, () => {
      if (highlightFriends) this.applyHighlight(this.state.items);
    });
  };

  collectionNA = () => {
    this.setState({
      extendLoaded: true,
      stamp_collection: false,
      highlightFriends: false,
    });
  };

  loadExtend = async () => {
    const { friend } = this.props;

    if (!!friend.extend) {
      if (!!friend.extend.stamp_collection) {
        const { data = [] } = friend.extend.stamp_collection;

        this.setState({
          highlightFriends: false,
          extendLoaded: true,
          stamp_collection: data,
          //   items: _.map(this.props.myitems, function (o) {
          //     return {
          //       ...o,
          //       highlight: data.indexOf(o.item_slug)<0
          //     }
          //   })
        });
      } else {
        this.collectionNA();
      }
    }

    //get friend extend first
    try {
      const extend = await userProfileExtend({
        uid: !!friend.user_id ? friend.user_id : friend.id,
      });
      if (_.has(extend, "stamp_collection.data")) {
        this.setState({
          extendLoaded: true,
          stamp_collection: extend.stamp_collection.data,
          // items: _.map(this.state.items, function (o) {
          //   return {
          //     ...o,
          //     highlight: extend.stamp_collection.data.indexOf(o.item_slug)<0
          //   }
          // }),
          // highlightFriends: true
        });
      } else {
        this.collectionNA();
      }
    } catch (error) {
      global.log("getfriendextend error", error);
      this.collectionNA();
    }
  };

  switchTab = (tabName, skip = false) => {
    if (tabName === this.state.currentTab && this.state.items > 0) return false;

    switch (tabName) {
      case "NEWEST_FIRST":
        this.applyHighlight(
          _.orderBy(this.props.myItems, ["created_at", "id"], ["desc", "desc"]),
          tabName
        );
        break;

      case "FAV_STAMPS":
        const { bookmarks } = this.props.me;
        const myItems = _.keyBy(this.props.myItems, "item_slug");
        this.applyHighlight(
          bookmarks
            .filter((item) => !!myItems[item])
            .map((item) => myItems[item]),
          tabName
        );
        break;

      case "STAMP_PREMIUM":
        this.applyHighlight(
          _.orderBy(
            _.filter(this.props.myItems, function (o) {
              return o.price >= 2;
            }),
            ["item_group", "id"],
            ["asc", "desc"]
          ),
          tabName
        );
        break;

      case "STAMP_SET":
        this.applyHighlight(
          [
            ..._.sortBy(
              _.filter(this.props.myItems, function (o) {
                return !!ITEM_GROUP_SET[o.item_group];
              }),
              ["item_group", "id"]
            ),
            ..._.filter(this.props.myItems, function (o) {
              return !!o.is_stamp_set;
            }),
          ],
          tabName
        );
        break;

      case "STAMP_ACHIEVEMENTS":
        this.applyHighlight(
          _.filter(this.props.myItems, { item_group: "achievement" }),
          tabName
        );
        break;

      case "STAMP_SPECIAL_DAYS":
        this.applyHighlight(
          _.filter(this.props.myItems, function (o) {
            return o.item_group === "int-days" || o.item_group === "festive";
          }),
          tabName
        );
        break;

      case "STAMP_LOCATION_BASED":
        this.applyHighlight(
          _.filter(this.props.myItems, (o) => !!o.country),
          tabName
        );
        break;

      default:
        this.applyHighlight(
          _.filter(this.props.myItems, function (o) {
            return !!o.is_stamp_set ? o.desc === tabName : false;
          }),
          tabName
        );
        break;
    }
  };

  changeStamp = (item) => {
    // this.setState({
    //   stamp: item.item_slug,
    // });
    this.props.onStampSelected(item.item_slug);

    this.props._changeStamp({
      selectedStamp: item.item_slug,
    });
  };

  hoverStamp = (item) => {
    this.setState({
      hover: item,
    });
  };

  onBodyChange = (e) => {
    this.setState({ searching: true, searchText: e.target.value });
    this.onBodyChangeDelayed();
  };

  searchNow = () => {
    const txt = this.state.searchText;
    if (!this.state.searching) return false;

    const searchText = _.lowerCase(_.trim(txt));

    this.applyHighlight(
      !!searchText
        ? _.filter(this.props.myItems, function (o) {
            return (
              _.includes(_.lowerCase(o.item_name), searchText) ||
              _.includes(I18n.t(o.item_name), txt) ||
              _.includes(_.lowerCase(o.desc), searchText) ||
              _.includes(I18n.t(o.desc), txt) ||
              _.includes(_.lowerCase(o.item_slug), searchText) ||
              (!!o.country && _.includes(_.lowerCase(o.country), searchText)) ||
              (!!o.country &&
                _.includes(_.lowerCase(I18n.country(o.country)), searchText)) ||
              (!!o.item_group &&
                o.item_group !== "default" &&
                _.includes(_.lowerCase(o.item_group), searchText))
            );
          })
        : [],
      null
    );
  };

  applyHighlight = (items, tabName) => {
    global.log("applyHighlight", this.state);

    const currentTab = !!tabName ? tabName : this.state.currentTab;

    if (
      !!this.state.highlightFriends &&
      !!this.state.extendLoaded &&
      !!this.state.stamp_collection
    ) {
      this.setState({
        currentTab,
        items: _.map(items, (o) => {
          return {
            ...o,
            highlight: this.state.stamp_collection.indexOf(o.item_slug) < 0,
          };
        }),
      });
    } else {
      this.setState({
        currentTab,
        items,
      });
    }

    if(this._scroller) this._scroller.scrollToTop()
  };

  render() {
    const { currentTab, items, highlightFriends = false } = this.state;
    const { friend = {} } = this.props;

    return (
      <SlidingPane
        // className="for-stamp"
        overlayClassName="slider"
        hideHeader
        width={"540px"}
        isOpen={this.props.showing}
        onRequestClose={this.props.dismiss}
        onAfterOpen={this.checkAgain}
      >
        <div className="card h-100">
          <div className="card-header position-relative pl-3">
            <div className="input-group search-bar bg-white input-group-sm">
              <div className="input-group-prepend">
                <i className="input-group-text icon-search pr-0" />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder={I18n.t("SEARCH")}
                ref={(input) => {
                  this.searchInput = input;
                }}
                onChange={this.onBodyChange}
              />
            </div>
            {!!this.state.extendLoaded && !!this.state.stamp_collection && (
              <div className="d-flex mt-3">
                <Avatar
                  key={friend.id}
                  uid={friend.id}
                  id={friend.avatar}
                  gender={friend.gender}
                  name={friend.name}
                  size={48}
                />
                <div className="ml-2">
                  <div>
                    <strong>{friend.name} (
                    {!!this.state.stamp_collection &&
                      this.state.stamp_collection.length}
                    )</strong>
                  </div>
                  <div className="custom-control custom-switch link mt-0">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={!!highlightFriends}
                    onChange={()=>{
                        this.toggleHighlight(!highlightFriends)
                    }}
                    id="toggleDim"
                  />
                  <label className="custom-control-label text-light link" htmlFor="toggleDim">
                    {I18n.t("NOT_IN_FRIEND_COLLECTION")}
                  </label>
                </div>
                </div>
                
              </div>
            )}
          </div>
          <div className="card-body p-0">
            <Scrollbars 
                className="flex-grow-1"
                ref={(ref) => (this._scroller = ref)}
              >
              <div className="row no-gutters p-1 py-3">
                { items.length<=0 ? (
                    <p className="w-100 p-5 text-light text-center">
                        {I18n.t('NOT_FOUND')}
                    </p>
                ) : _.map(items, (item) => {
                  return (
                    <div
                      className={
                        highlightFriends && !item.highlight
                          ? STAMP_BASE_CLASS + " trans"
                          : STAMP_BASE_CLASS
                      }
                      onClick={() => {
                        this.changeStamp(item);
                      }}
                      key={"item-" + item.item_slug + "_" + highlightFriends}
                    >
                      <Stamp slug={item.item_slug} size={100} />
                      <div
                        className="text-center small text-light text-truncate"
                        style={{
                          width: 160,
                          margin: "auto",
                          marginTop: 6,
                          marginBottom: 12,
                        }}
                      >
                        {I18n.t(item.name)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Scrollbars>
          </div>
          <div className="card-footer d-flex">
            {menuItems.map((item) => {
              return (
                <div
                  key={"menu" + item}
                  className={"link"}
                  style={{ flex: 1, textAlign: "center", fontSize: 24 }}
                  onClick={() => {
                    this.switchTab(item);
                  }}
                >
                  <i
                    className={
                      currentTab === item
                        ? "icon-" + icons[item] + " text-darker"
                        : "icon-" + icons[item] + " text-lighter lighter"
                    }
                  />
                </div>
              );
            })}
            <div
              key={"menu-bookmark"}
              className={"link"}
              style={{ flex: 1, textAlign: "center", fontSize: 24 }}
              onClick={() => {
                this.switchTab("FAV_STAMPS");
              }}
            >
              <i
                className={
                  currentTab === "FAV_STAMPS"
                    ? "icon-bookmark text-darker"
                    : "icon-bookmark text-lighter lighter"
                }
              />
            </div>
          </div>
        </div>
      </SlidingPane>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {
      me: reProfileMe(state),
      myItems: reMyItems(state),
      darkMode: state.slowly.darkMode,
    };
  };
  return mapStateToProps;
};

const _changeStamp = function _changeStamp({
  selectedStamp,
  automatch = false,
}) {
  return {
    type: "CHANGE_STAMP",
    selectedStamp,
    automatch,
  };
};

export default connect(makeMapStateToProps, {
  _changeStamp,
})(StampSelection);
