import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import moment from "../lib/moment";
import { Link } from "react-router-dom";
import Login from "./Login";

import {
  getSub,
  getPortalLink,
  resumePlan,
  cancelPlan,
  updateWebPayment,
} from "../api/payment.api";

class Plus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      cancelled: false,
      subscription: {},
      error: false,
    };
  }

  _getSub = async () => {
    const { token, role } = this.props.me;

    if (role < 20) {
      this.setState({
        loading: false,
      });
      return true;
    }
    try {
      const result = await getSub({ token });
      global.log("_getSub", result);

      this.setState({
        sub: result,
        loading: false,
      });
    } catch (error) {
      global.log("getSub error", error);
      this.setState({
        loading: false,
        error: true,
      });
      this.props.showError({
        message: "UNABLE_TO_REFRESH_DATA",
      });
    }
  };

  componentDidMount() {
    const { me } = this.props;
    const loggedIn = !!me && !!me.token && !!me.id;

    if (loggedIn) this._getSub();
  }

  _manage = async () => {
    if (
      this.state.sub.store === "STRIPE" ||
      this.state.sub.store === "STRIPE_UK"
    ) {
      this.setState({ redirecting: true });
      const result = await getPortalLink({
        token: this.props.me.token,
        locale: this.props.locale,
      });
      global.log("getPortalLink result", result);
      if (result.url) window.top.location.href = result.url;
      this.setState({ redirecting: false });
      return true;
    } else if (this.state.sub.store === "APP_STORE") {
      window.open(
        "https://buy.itunesub.apple.com/WebObjects/MZFinance.woa/wa/DirectAction/manageSubscriptions"
      );
    } else if (this.state.sub.store === "PLAY_STORE") {
      window.open("https://play.google.com/store/account/subscriptions");
    }
  };

  _updatePayment = async () => {
    try {
      this.setState({ redirecting: true });
      const result = await updateWebPayment({
        token: this.props.me.token,
        sub_id: this.state.sub.id,
        customer_id: this.state.sub.customer.id,
        currency: this.state.sub.currency,
        locale: this.props.locale,
      });
      global.log("updateWebPayment result", result);
      if (result.url) window.top.location.href = result.url;
      this.setState({ redirecting: false });
    } catch (error) {
      this.setState({ redirecting: false });
      global.log("updateWebPayment error", error);
    }
  };

  paymentMethod = (pm) => {
    if (pm.type === "paypal")
      return (
        <div>
          <span className="badge badge-primary px-1 mr-2">PayPal</span>
          {!!pm.details && pm.details.payer_email}
        </div>
      );
    if (pm.type === "card" && !!pm.details)
      return (
        <div className="d-flex align-items-center">
          <span className="badge badge-primary px-1 mr-1">
            {pm.details.brand.toUpperCase()}
          </span>
          <span className="mr-4">
            {" "}
            {"..."}
            {pm.details.last4}{" "}
          </span>

          <span>
            {I18n.t("Expires")} {pm.details.exp_month}
            {"/"}
            {pm.details.exp_year}
          </span>
        </div>
      );
    return pm.type;
  };

  _cancelPlan = async () => {
    try {
      this.setState({ redirecting: true });
      const result = await cancelPlan({
        token: this.props.me.token,
        external_id: this.state.sub.external_id,
      });

      global.log("result", result);

      this.setState({
        redirecting: false,
        sub: {
          ...this.state.sub,
          cancel_at: result.cancel_at,
        },
      });
    } catch (error) {
      this.setState({ redirecting: false });
      global.log("updateWebPayment error", error);
    }
  };

  _resumePlan = async () => {
    try {
      this.setState({ redirecting: true });
      const result = await resumePlan({
        token: this.props.me.token,
        external_id: this.state.sub.external_id,
      });

      global.log("result", result);

      this.setState({
        redirecting: false,
        sub: {
          ...this.state.sub,
          cancel_at: null,
        },
      });
    } catch (error) {
      this.setState({ redirecting: false });
      global.log("updateWebPayment error", error);
    }
  };

  hideAllModals = () => {
    this.setState({
      showLogin: false,
      showFeatures: false,
    });
  };

  render() {
    const { me } = this.props;
    const loggedIn = !!me && !!me.token && !!me.id;
    const { loading, sub = {} } = this.state;
    if (!loggedIn) {
      return <Login onSuccess={this._getSub} />;
    }

    return (
      <div className="split-md mx-auto">
        <div className="container  no-min-width">
          <div className="row justify-content-md-center">
            <div className="col-3  d-none d-md-block">
              <StaticSideMenu idv_available={this.props.me.idv_available} />
            </div>
            <div className="col p-3">
              {/* Plus */}
              <div
                className="card shadow-sm bg-white dismiss-card-on-mobile"
                dir="ltr"
              >
                <div className="card-body">
                  {me.role >= 20 && (
                    <div className="bg-gift" style={{ maxHeight: 200 }}>
                      <img
                        alt="..."
                        src={require("../assets/images/happy-bg.png")}
                        style={{
                          width: "90%",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </div>
                  )}
                  <div className="text-center py-3">
                    <img
                      src="https://promo.slowly.app/paywall-header-mailbox.png"
                      alt="Slowly Plus"
                      style={{ width: 380, maxWidth: "75%" }}
                    />
                  </div>
                  {sub.store === "STRIPE_UK" && sub.expired ? (
                    <div className="badge badge-light mb-2">
                      {I18n.t("Expired")}
                    </div>
                  ) : (
                    sub.store === "STRIPE_UK" &&
                    sub.cancel_at && (
                      <div className="badge badge-light mb-2">
                        {I18n.t("Cancelled")}
                      </div>
                    )
                  )}
                  <h4
                    className="mb-1 text-darker text-extra-bold"
                  >
                    {me.role >= 20
                      ? I18n.t("Upgraded to SLOWLY PLUS")
                      : "Slowly Plus"}
                  </h4>

                  {!!loading ? (
                    <div className="text-light text-center py-5 small">
                      {I18n.t("LOADING")}
                    </div>
                  ) : me.role < 20 ? (
                    <div className="text-light">
                      {I18n.t("No active subscription")}
                      <Link
                        to="/upgrade"
                        className="btn btn-secondary btn-block mt-5"
                      >
                        {I18n.t("UPGRADE_NOW")}
                      </Link>
                    </div>
                  ) : (
                    <div>
                      {sub.store === "STRIPE_UK" &&
                        sub.plan &&
                        sub.plan !== "plus_oneoff" && (
                          <div className="mt-2 h5">
                            {sub.plan.symbol}
                            {sub.plan.amount}{" "}
                            <span className="small">
                              {sub.plan.recurring === 1
                                ? I18n.t("PER_MONTH")
                                : sub.plan.recurring === 12
                                ? I18n.t("PER_YEAR")
                                : " / " +
                                  sub.plan.recurring +
                                  " " +
                                  I18n.t("Months")}
                            </span>
                          </div>
                        )}
                      {sub.created_at && (
                        <div className="mb-3 mt-2">
                          {moment.custom(sub.created_at, "short") +
                            " — " +
                            moment.custom(sub.ends_at, "short")}
                        </div>
                      )}

                      {sub.store === "STRIPE_UK" &&
                        !sub.expired &&
                        sub.plan !== "plus_oneoff" && (
                          <div className="mb-3">
                            <button
                              type="button"
                              className="btn btn-outline-dark mr-2"
                              disabled={!!this.state.redirecting}
                              onClick={
                                !!sub.cancel_at
                                  ? this._resumePlan
                                  : this._cancelPlan
                              }
                            >
                              {!!sub.cancel_at
                                ? I18n.t("RESUME_PLAN")
                                : I18n.t("CANCEL_PLAN")}
                            </button>
                          </div>
                        )}
                    </div>
                  )}
                  {sub.store === "STRIPE_UK" &&
                    !sub.expired &&
                    sub.default_payment && (
                      <div className="mt-4 py-3 border-top">
                        <h5>{I18n.t("PAYMENT_METHOD")}</h5>
                        <div className="mt-1">
                          {this.paymentMethod(sub.default_payment)}
                        </div>
                        {sub.currency && (
                          <div className="mt-1">
                            {I18n.t("CURRENCY_WITH_CODE", {
                              code: sub.currency,
                            })}
                          </div>
                        )}

                        <div className="mt-3">
                          <button
                            type="button"
                            className="btn btn-outline-dark mr-2"
                            disabled={!!this.state.redirecting}
                            onClick={this._updatePayment}
                          >
                            {I18n.t("UPDATE")}
                          </button>
                        </div>
                      </div>
                    )}
                </div>
                {!loading && me.role >= 20 && (
                  <div className="card-footer">
                    <div className="row">
                      {sub.store === "STRIPE_UK" &&
                      sub.cancel_at &&
                      !sub.expired ? (
                        <div className="col small py-2">
                          <i className="icon-calendar mr-2 text-light" />
                          {I18n.t("PLAN_CANCELLED", {
                            date: moment.custom(sub.ends_at, "short"),
                          })}
                        </div>
                      ) : !sub.cancel_at &&
                        !sub.expired &&
                        sub.plan !== "plus_oneoff" ? (
                        <div className="col small py-2">
                          <i className="icon-refresh mr-2 text-success" />
                          {I18n.t("RECURRING_BILLING")}
                        </div>
                      ) : (
                        <div className="col-12 col-md-8 small py-2">
                          <i className="icon-info-circle mr-2 text-light" />
                          {I18n.t("BILLED_THROUGH", {
                            channel:
                              sub.store === "STRIPE" ||
                              sub.store === "STRIPE_UK"
                                ? "Stripe"
                                : sub.store === "PLAY_STORE"
                                ? "Google Play"
                                : sub.store === "APP_STORE"
                                ? "App Store"
                                : "Unknown",
                          })}
                        </div>
                      )}

                      {sub.store !== "STRIPE_UK" &&
                        !this.state.loading &&
                        !this.state.error && (
                          <div className="col-12 col-md-4 text-right">
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              disabled={!!this.state.redirecting}
                              onClick={this._manage}
                            >
                              {I18n.t("MANAGE")}
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    locale: state.slowly.locale,
  };
};

const showError = function showError(error) {
  return {
    type: "SHOW_ERROR",
    error,
  };
};

export default connect(mapStateToProps, { showError })(Plus);
