import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import * as Sentry from "@sentry/react";
import { WEB_VERSION } from "./config/ApiConfig";

const log = (...msgs) => {
  if (process.env.NODE_ENV === 'development') console.log(...msgs)
}

const firebaseConfig = {
  apiKey: "AIzaSyCkuEKg8FJbirZJDLkZom1Q8zRg0zD4_Qo",
  authDomain: "slowlyapp.firebaseapp.com",
  databaseURL: "https://slowlyapp.firebaseio.com",
  projectId: "slowlyapp",
  storageBucket: "slowlyapp.appspot.com",
  messagingSenderId: "665290019748",
  appId: "1:665290019748:web:343a1f942a382b5a"
};

global.log = log
const app = initializeApp(firebaseConfig);

try{
  global.appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Ld6m5soAAAAAIB2V04yIVAvYIsm_BtmRQyIKY7U'),
    isTokenAutoRefreshEnabled: true
  });
}catch(error){
  console.log('Unable to init app-check')
}

Sentry.init({
  dsn: "https://7be1dbf4b573435db74215166cdfdbdd@o172074.ingest.sentry.io/4506083017490432",
  release: WEB_VERSION
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
