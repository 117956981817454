import { call, select, put, take } from 'redux-saga/effects';
import {
  favPost,
  unfavPost,
  blockPost,
  unblockPost,
  hideUser,
  unhideUser,
  saveRemarks,
  getFriends,
  getHiddenFriends,
  getBlockedFriends,
  reportUser,
  deletePost,
  updatePhotoShare,
  updateAudioShare
} from '../api/posts.api';

import { getMyID, getRemindedIDs, getToken } from '../selectors';


import {
  checkFriendRequest
} from '../api/explore.api'

import tracking from '../lib/tracking';
import { toast } from 'react-toastify';

import I18n from '../I18n'

import {
  GET_FRIENDS,
  GET_FRIENDS_SUCCESS,
  CHECK_FRIEND_REQUEST,
  CHECK_FRIEND_REQUEST_FINISH,
  GET_HIDDEN,
  GET_HIDDEN_SUCCESS,
  GET_BLOCKED,
  GET_BLOCKED_SUCCESS,
  FAV_POST,
  UNFAV_POST,
  FAV_POST_SUCCESS,
  UNFAV_POST_SUCCESS,
  BLOCK_USER,
  BLOCK_USER_SUCCESS,
  UNBLOCK_USER,
  SAVE_REMINDER,
  HIDE_USER,
  HIDE_USER_SUCCESS,
  UNHIDE_USER,
  // UNHIDE_USER_SUCCESS,
  REPORT_USER,
  UPDATE_ME,
  REPORT_NEW_USER,
  HIDE_NEW_USER,
  HIDE_NEW_USER_SUCCESS,

  SAVE_REMARK,
  SAVE_REMARK_SUCCESS,
  GET_UNREAD,
  GET_LATEST,
  UPDATE_PHOTO_SHARING,
  UPDATE_PHOTO_SHARING_SUCCESS,
  UPDATE_AUDIO_SHARING,
  UPDATE_AUDIO_SHARING_SUCCESS,
  ME_ERROR,
  RESET_ERROR,
  // CHECK_INCOMING_SUCCESS
} from '../actions/type';

import moment from "moment";
import _ from 'lodash'

function* friendsWatcher() {
  while (true) {
    const { token=false } = yield take(GET_FRIENDS);
    const useToken = !token ? yield select(getToken) : token

    try {
      const response = yield call(getFriends, { token: useToken });
      // yield put({ type: GET_FRIENDS_SUCCESS, response });
      const today = new Date();
      const currYear= today.getFullYear();
      const thisMoment = moment()
      const reminded = yield select(getRemindedIDs);

      const mappedFriends = _.map(response.friends, f => {
        if(!!f.dob){
          let comingBirthday = moment(currYear +"-"+ moment(f.dob).format("MM-DD"))
          let birthdayDiff = comingBirthday.diff(thisMoment, 'days')

          if(birthdayDiff<0){
            //next year then
            comingBirthday = moment((currYear+1) +"-"+ moment(f.dob).format("MM-DD"))
            birthdayDiff = comingBirthday.diff(thisMoment, 'days')
          }

          const birthdaySoon = birthdayDiff>=0 && birthdayDiff<3
          var _reminded = false;

          if(birthdaySoon && reminded.indexOf(f.id)<0 ){
            toast.warn(I18n.t("BIRTHDAY_REMINDER_TITLE", {
              name: f.name,
              date: moment(f.dob).format('MMM Do')
            }));
            _reminded = true
          }

          return {
            ...f,
            comingBirthday: comingBirthday.format('YYYY-MM-DD'),
            reminded: _reminded,
            birthdayDiff,
            birthdaySoon
          }

        }else{
          return {
            ...f,
            birthdayDiff: -1,
            birthdayReminder: null,
            bithdaySoon: false
          }
        }
      } )

      yield put({ type: GET_FRIENDS_SUCCESS, response:{
        ...response,
        friends: mappedFriends
      } });

      const toUpdate = _.map(_.filter( mappedFriends, f => !!f.reminded ), 'id')
      global.log('toUpdate', toUpdate)
      if(toUpdate.length>0){
        const meID = yield select(getMyID);
        yield put({ type: SAVE_REMINDER, ids: toUpdate, meID  })

        global.log('SAVE_REMINDER')
      }


      yield put({ type: RESET_ERROR })

    } catch (error) {
      yield put({ type: ME_ERROR, error, action: GET_FRIENDS, payload: { token } });
    }
  }
}

function* blockedWatcher() {
  while (true) {
    const { token } = yield take(GET_BLOCKED);
    try {
      const response = yield call(getBlockedFriends, { token });
      yield put({ type: GET_BLOCKED_SUCCESS, response });
      yield put({ type: RESET_ERROR })

    } catch (error) {
      yield put({ type: ME_ERROR, error, action: GET_BLOCKED, payload: { token } });
    }
  }
}

function* hiddenWatcher() {
  while (true) {
    const { token } = yield take(GET_HIDDEN);
    try {
      const response = yield call(getHiddenFriends, { token });
      yield put({ type: GET_HIDDEN_SUCCESS, response });
      yield put({ type: RESET_ERROR })

    } catch (error) {
      yield put({ type: ME_ERROR, error, action: GET_HIDDEN, payload: { token } });
    }
  }
}

function* unfavWatcher() {
  while (true) {
    const { token, postID } = yield take(UNFAV_POST);

    try {
      yield call(unfavPost, { token, postID });
      yield put({ type: UNFAV_POST_SUCCESS, postID });

      tracking.event('Friends', 'unfav', 'post', postID);
      yield put({ type: RESET_ERROR })

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: UNFAV_POST, payload: { token, postID }  });
    }
  }
}

function* favWatcher() {
  while (true) {
    const { token, postID } = yield take(FAV_POST);

    try {
      yield call(favPost, { token, postID });
      yield put({ type: FAV_POST_SUCCESS, postID });
      tracking.event('Friends', 'fav', 'post', postID);
      yield put({ type: RESET_ERROR })

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: FAV_POST, payload: { token, postID }  });
    }
  }
}

function* reportWatcher() {
  while (true) {
    const { token, fields, user } = yield take(REPORT_USER);

    try {
      yield call(reportUser, { token, fields });
      tracking.event("Friends", "report", "user", user.id);
      yield call(deletePost, { token, postID: user.id });

      yield put({ type: BLOCK_USER_SUCCESS, user });
      toast.info(I18n.t('BLOCK_USER_MSG_WITH_NAME', { name: user.name }));

      yield put({ type: RESET_ERROR });
    } catch (error) {
      global.log(error);

      if (error.error === "SERVER_TIMEOUT")
        yield put({
          type: ME_ERROR,
          error,
          action: REPORT_USER,
          payload: { token, fields, user }
        });
    }
  }
}
function* reportNewUserWatcher() {
  while (true) {
    const { token, fields, user, meID } = yield take(REPORT_NEW_USER);
    try {
      yield call(reportUser, { token, fields });
      tracking.event('ReportProfile', 'report', 'user', user.id);
      yield put({ type: HIDE_NEW_USER, token, user, meID });
      yield put({ type: RESET_ERROR })

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: REPORT_NEW_USER, payload: { token, fields, user }  });
    }
  }
}

function* hideNewUserWatcher(){
  while (true) {
    const { token, user } = yield take(HIDE_NEW_USER);

    yield put({
      type: UPDATE_ME,
      token: token,
      updateFields: { hidden: user.id },
      skip: true
    });

    yield put({ type: HIDE_NEW_USER_SUCCESS })
  }
}

function* blockWatcher() {
  while (true) {
    const { token, user } = yield take(BLOCK_USER);

    try {
      // const meID = yield select(getMyID);
      yield call(blockPost, { token, postID: user.id });
      yield put({ type: GET_UNREAD, token });
      yield put({ type: GET_LATEST, token, page: 1, after: null });
      yield put({ type: BLOCK_USER_SUCCESS, user });

      tracking.event('Friends', 'block', 'user', user.id);
      yield put({ type: RESET_ERROR })

      toast.info(I18n.t('BLOCK_USER_MSG_WITH_NAME', { name: user.name }));

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: BLOCK_USER, payload: { token, user }  });
    }
  }
}

function* unblockWatcher() {
  while (true) {
    const { token, user } = yield take(UNBLOCK_USER);

    try {
      yield call(unblockPost, { token, postID: user.id });
      // yield put({ type: GET_UNREAD, token });
      // yield put({ type: GET_LATEST, token, page: 1, after: null });
      // yield put({ type: UNBLOCK_USER_SUCCESS, user });

      tracking.event('Friends', 'unblock', 'user', user.id);
      yield put({ type: RESET_ERROR })

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: UNBLOCK_USER, payload: { token, user }  });
    }
  }
}

function* hideWatcher() {
  while (true) {
    const { token, user } = yield take(HIDE_USER);

    try {
      yield call(hideUser, { token, postID: user.id });
      yield put({ type: GET_UNREAD, token });
      yield put({ type: GET_LATEST, token, page: 1, after: null });
      yield put({ type: HIDE_USER_SUCCESS, user });
      tracking.event('Friends', 'hide', 'user', user.id);
      yield put({ type: RESET_ERROR })

      // toast.info(I18n.t('BLOCK_USER_MSG_WITH_NAME', { name: user.name }));

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: HIDE_USER, payload: { token, user }  });
    }
  }
}
function* unhideWatcher() {
  while (true) {
    const { token, user } = yield take(UNHIDE_USER);

    try {
      yield call(unhideUser, { token, postID: user.id });
      // yield put({ type: GET_FRIENDS, token });
      tracking.event('Friends', 'unhide', 'user', user.id);
      yield put({ type: RESET_ERROR })

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: UNHIDE_USER, payload: { token, user }  });
    }
  }
}

function* remarkWatcher() {
  while (true) {
    const { token, postID, remarks } = yield take(SAVE_REMARK);

    try {
      yield call(saveRemarks, { token, postID, remarks });
      yield put({ type: SAVE_REMARK_SUCCESS, postID, remarks });
      yield put({ type: RESET_ERROR })

    } catch (error) {
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: SAVE_REMARK, payload: { token, postID, remarks }  });
    }
  }
}

function* sharingWatcher() {
  while (true) {
    const { me, postID, allow } = yield take(UPDATE_PHOTO_SHARING);

    try {
      yield call(updatePhotoShare, { token: me.token, postID, allow });
      yield put({ type: UPDATE_PHOTO_SHARING_SUCCESS, postID, me, allow });
      yield put({ type: RESET_ERROR });
    } catch (error) {
      if (error.error === "SERVER_TIMEOUT")
        yield put({
          type: ME_ERROR,
          error,
          action: UPDATE_PHOTO_SHARING,
          payload: { me, postID, allow }
        });
    }
  }
}

function* audioSharingWatcher() {
  while (true) {
    const { me, postID, allow } = yield take(UPDATE_AUDIO_SHARING);

    try {
      yield call(updateAudioShare, { token: me.token, postID, allow });
      yield put({ type: UPDATE_AUDIO_SHARING_SUCCESS, postID, me, allow });

      yield put({ type: RESET_ERROR });
    } catch (error) {
      if (error.error === "SERVER_TIMEOUT")
        yield put({
          type: ME_ERROR,
          error,
          action: UPDATE_AUDIO_SHARING,
          payload: { me, postID, allow }
        });
    }
  }
}

function* checkFriendRequestWatcher(){
  while(true){
    const { token } = yield take(CHECK_FRIEND_REQUEST)

    try{
      const result = yield call(checkFriendRequest, { token });
      yield put({ type: CHECK_FRIEND_REQUEST_FINISH, requests: result.requests })

    }catch(error){
      if(error.error==='SERVER_TIMEOUT')
      yield put({ type: ME_ERROR, error, action: CHECK_FRIEND_REQUEST, payload: { token }  });
    }
  }
}

const _contactsSaga = [
  friendsWatcher,
  hiddenWatcher,
  blockedWatcher,
  favWatcher,
  unfavWatcher,
  blockWatcher,
  unblockWatcher,
  remarkWatcher,
  hideWatcher,
  unhideWatcher,
  reportWatcher,
  sharingWatcher,
  audioSharingWatcher,
  reportNewUserWatcher,
  hideNewUserWatcher,
  checkFriendRequestWatcher
]

export default _contactsSaga;
