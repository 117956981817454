import React from 'react';
import { STATIC_CDN_URL } from "../config/ApiConfig";

export default class StampSmall extends React.PureComponent {
  render() {
    const { slug, size, name, darkMode=false } = this.props;
    const url = (slug==="nostamps" && !!darkMode) ? STATIC_CDN_URL + "stamp-sm/nostamps-inverted.png" : STATIC_CDN_URL + "stamp-sm/" + slug + ".png"

    return (
      <img
        src={url} width={size} height={size}
        key={'stamp-'+slug}
        alt={name} className="stamp loaded"
        loading="lazy"
      />
    );
  }
}
