import React from 'react';
import I18n from "../I18n";
import Avatar from "./Avatar";
import moment from '../lib/moment'

export default class DraftListItem extends React.PureComponent {
  render() {
    const { draft, onClick } = this.props;
    const attachments = !!draft.attachments ? draft.attachments.split(',') : []

    const friend = this.props.draft.new_friend ? this.props.draft.new_friend : this.props.friend
    if(!friend) return null;
    global.log('draft', draft)

    return (
      <div onClick={onClick} className="no-underline link">
        <div className="d-flex align-items-center border-top" style={{ marginTop: -1 }}>
          <div className="col-pixel-width-50 pl-3 py-3">
            <Avatar
                uid={friend.id}
                id={friend.avatar}
                gender={friend.gender}
                name={friend.name}
                size={42}
            />
          </div>
          <div className="col px-3 text-truncate">
            <div className="text-truncate">
              <h6 className="pl-0 pr-0 mt-1 mb-0 text-truncate ">
                {draft.channel!=='openletter' && !!draft.new_friend ? I18n.t("TO:") : I18n.t("RE:")}
                {' ' + friend.name}
                { draft.channel==='openletter' ? (
                  <span className="text-calm mx-2 smallest font-weight-bold">
                    {I18n.t('OPEN_LETTER')}
                  </span>
                ): !!draft.new_friend && (
                  <span className="text-danger mx-2 smallest">
                    {I18n.t('NEW')}
                  </span>
                )}
                { !!friend.identity && (
                  <span className="icon icon-shield color-idv ml-1" />
                )}
              </h6>
            </div>
            <div className="small text-black-50 text-truncate">
              { !!draft.body && draft.body.toString()  }{'...'}
            </div>
          </div>
          <div className="col-auto px-3 text-right" style={{ minWidth: 150 }}>
            { !!draft.audio && (
              <span className="text-lighter small">
                <i className="icon-mic mx-2" />
              </span>
            )}
            { attachments.length > 0 && (
              <span className="text-lighter small">
                <i className="icon-images ml-1" />{" x " + attachments.length}
              </span>
            )}

          </div>
          <div className="col-auto px-3 text-right" style={{ minWidth: 150 }}>
            <span className="text-lighter small">
              <i className="icon-clock mr-1" />
              {moment.custom(draft.updated_at, "calendar")}{" "}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
