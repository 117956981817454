export const INIT_APP = 'INIT_APP';
export const RESTART_APP = 'RESTART_APP'

//v4
export const UPDATE_FRIENDSHIP_STATUS = 'UPDATE_FRIENDSHIP_STATUS'
export const UPDATE_IDV_STATUS = 'UPDATE_IDV_STATUS'

export const SYNC_PARAGRAPHS = 'SYNC_PARAGRAPHS' //v7.0
export const SYNC_P_LABELS = 'SYNC_P_LABELS' //v7.0
export const SAVE_PARAGRAPH = 'SAVE_PARAGRAPH' //v7.0
export const DEL_PARAGRAPH = 'DEL_PARAGRAPH' //v7.0

export const TRUST_DEVICE = 'TRUST_DEVICE' //v3.6
export const POLLFISH_DECLINE = 'POLLFISH_DECLINE' //v3.2

export const SWITCH_DARKMODE = 'SWITCH_DARKMODE'
export const COFFEE_PROMOTED = 'COFFEE_PROMOTED'

export const VERSION_UPGRADED = 'VERSION_UPGRADED'
export const GOT_UUID = 'GOT_UUID'

export const SAVE_REMINDER = 'SAVE_REMINDER'
export const SAVE_SUBTOPICS = 'SAVE_SUBTOPICS'

export const CACHE_STORIES = 'CACHE_STORIES'
export const CACHE_DISCOVER = 'CACHE_DISCOVER'

export const SET_LOADING = 'SET_LOADING'
export const CHECK_SLOWLY = 'CHECK_SLOWLY';
// export const CHECK_TOKEN = 'CHECK_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const INIT_ERROR = 'INIT_ERROR';
export const INIT_APP_COMPLETE = 'INIT_APP_COMPLETE';
export const CHANGE_ORIENTATION = 'CHANGE_ORIENTATION'
export const UPDATE_PHOTO_REQUEST_REMINDER = 'UPDATE_PHOTO_REQUEST_REMINDER'
export const UPDATE_SEND_LETTER_REMINDER = 'UPDATE_SEND_LETTER_REMINDER'
export const NEW_TAG_SEEN = 'NEW_TAG_SEEN'
export const NEW_STAMP_UNLOCKED = 'NEW_STAMP_UNLOCKED'
export const SAVE_MYSTAMPS_SORTING = 'SAVE_MYSTAMPS_SORTING'

export const SAVE_UI_LOCALE_SUCCESS = 'SAVE_UI_LOCALE_SUCCESS'
export const SAVE_UI_LOCALE = 'SAVE_UI_LOCALE'

export const SAVE_AVATAR_DATA = 'SAVE_AVATAR_DATA'
export const UPDATE_AVATAR_INDEX = 'UPDATE_AVATAR_INDEX'

export const UPDATE_OS_ID = 'UPDATE_OS_ID'

export const CHECK_FCM_ID = 'CHECK_FCM_ID'
export const UPDATE_FCM_ID = 'UPDATE_FCM_ID'

export const NOTI_TEST = 'NOTI_TEST'
export const NOTI_TEST_COMPLETE = 'NOTI_TEST_COMPLETE'

export const UPDATE_PREF = 'UPDATE_PREF'
export const UPDATE_PREF_SUCCESS = 'UPDATE_PREF_SUCCESS'

export const SAVE_EXTEND_PREF = 'SAVE_EXTEND_PREF'

export const REFRESH_RATIO = 'REFRESH_RATIO'
export const REFRESH_COINS = 'REFRESH_COINS'
export const REFRESH_STAMPS = 'REFRESH_STAMPS'
export const REFRESH_STAMPS_SUCCESS = 'REFRESH_STAMPS_SUCCESS'

export const REFRESH_FRIEND = 'REFRESH_FRIEND'

export const SYNC_STORE = 'SYNC_STORE'
export const SYNC_STORE_COMPLETE = 'SYNC_STORE_COMPLETE'

export const CHECK_FRIEND_REQUEST = 'CHECK_FRIEND_REQUEST'
export const CHECK_FRIEND_REQUEST_FINISH = 'CHECK_FRIEND_REQUEST_FINISH'

export const REGISTER_INIT = 'REGISTER_INIT'
export const REGISTER = 'REGISTER';
export const REGISTER_UPDATE_STEP = 'REGISTER_UPDATE_STEP';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_CANCEL = 'REGISTER_CANCEL'
export const SAVE_TEMP_AVATAR = 'SAVE_TEMP_AVATAR'

export const GET_LOCATION = 'GET_LOCATION'
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_LOCATION_AND_UPDATE = 'GET_LOCATION_AND_UPDATE'
export const SAVE_TEMP_LOCATION = 'SAVE_TEMP_LOCATION'
export const UPDATE_AUTO_LOCATION = 'UPDATE_AUTO_LOCATION'
export const MANUAL_UPDATE_LOC = 'MANUAL_UPDATE_LOC'

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const LOGIN_EMAIL_SENT = 'LOGIN_EMAIL_SENT';
export const LOGIN_REQUIRE_PASSWORD = 'LOGIN_REQUIRE_PASSWORD';
export const LOGIN_EMAIL = 'LOGIN_EMAIL';
export const LOGIN_EMAIL_PASSWORD = 'LOGIN_EMAIL_PASSWORD';
export const LOGIN_EMAIL_OTP = 'LOGIN_EMAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const DELETE_ME = 'DELETE_ME';

export const GOT_TOKEN = 'GOT_TOKEN';
export const GOT_DEVICE = 'GOT_DEVICE';

export const REG_DEVICE = 'REG_DEVICE'

export const GET_ME = 'GET_ME';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_COLLECTION = 'GET_COLLECTION';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const BUILD_COLLECTION = 'BUILD_COLLECTION';
export const CLOSE_NEW_STAMP_POPUP = 'CLOSE_NEW_STAMP_POPUP';

export const CLEAR_NEW_STAMPS = 'CLEAR_NEW_STAMPS'
export const CLEAR_NEW_STAMP_NOTIFY = 'CLEAR_NEW_STAMP_NOTIFY';
export const CLEAR_NEW_COLLECTION_NOTIFY = 'CLEAR_NEW_COLLECTION_NOTIFY';

export const UPDATE_BOOKMARKS = 'UPDATE_BOOKMARKS'
export const UPDATE_BOOKMARKS_SUCCESS = 'UPDATE_BOOKMARKS_SUCCESS'
export const UPDATE_FAV = 'UPDATE_FAV'
export const UPDATE_FAV_SUCCESS = 'UPDATE_FAV_SUCCESS'
export const SYNC_PREF = 'SYNC_PREF'

export const CHECK_INCOMING = 'CHECK_INCOMING';
export const CHECK_INCOMING_SUCCESS = 'CHECK_INCOMING_SUCCESS';

export const ME_ERROR = 'ME_ERROR';
export const SHOW_ERROR = 'SHOW_ERROR'
export const RESET_ERROR = 'RESET_ERROR'
export const CANCEL_LOADING = 'CANCEL_LOADING'

export const UPDATE_ME = 'UPDATE_ME';
export const UPDATE_ZODIAC = 'UPDATE_ZODIAC';
export const CHECK_DOB = 'CHECK_DOB';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';

export const ADD_AUTH = 'ADD_AUTH';
export const REMOVE_AUTH = 'REMOVE_AUTH';
export const AUTH_ADDED = 'AUTH_ADDED';
export const AUTH_REMOVED = 'AUTH_REMOVED';
export const SAVE_EMAIL_INIT = 'SAVE_EMAIL_INIT';
export const SAVE_EMAIL_CANCEL = 'SAVE_EMAIL_CANCEL';
export const PASSCODE_SENT = 'PASSCODE_SENT';
export const CHECK_PASSCODE = 'CHECK_PASSCODE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SAVE_EMAIL_SUCCESS = 'SAVE_EMAIL_SUCCESS';

export const CHECK_PASSWORD = 'CHECK_PASSWORD';
export const CHECK_PASSWORD_SUCCESS = 'CHECK_PASSWORD_SUCCESS';
export const SETUP_PASSWORD = 'SETUP_PASSWORD';
export const SETUP_PASSWORD_CANCEL = 'SETUP_PASSWORD_CANCEL';
export const SETUP_PASSWORD_SUCCESS = 'SETUP_PASSWORD_SUCCESS';

export const CHECK_PHOTO_QUOTA = 'CHECK_PHOTO_QUOTA';
export const USE_PHOTO_QUOTA = 'USE_PHOTO_QUOTA';

export const SET_TOPICS_SAVE = 'SET_TOPICS_SAVE';
export const SET_TOPICS_SUCCESS = 'SET_TOPICS_SUCCESS';
export const SYNC_EXCLUDE_TOPICS = 'SYNC_EXCLUDE_TOPICS'
export const SYNC_EXCLUDE_TOPICS_SUCCESS = 'SYNC_EXCLUDE_TOPICS_SUCCESS';

export const LANG_INIT = 'LANG_INIT';
export const LANG_ADD_SUCCESS = 'LANG_ADD_SUCCESS';
export const LANG_UPDATE_SUCCESS = 'LANG_UPDATE_SUCCESS';
export const LANG_REMOVE_SUCCESS = 'LANG_REMOVE_SUCCESS';

export const GET_FRIENDS = 'GET_FRIENDS';
export const GET_FRIENDS_SUCCESS = 'GET_FRIENDS_SUCCESS';
export const GET_FRIENDS_ERROR = 'GET_FRIENDS_ERROR';
export const SORT_FRIENDS = 'SORT_FRIENDS';

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';

export const FAV_POST = 'FAV_POST';
export const FAV_POST_SUCCESS = 'FAV_POST_SUCCESS';
export const UNFAV_POST = 'UNFAV_POST';
export const UNFAV_POST_SUCCESS = 'UNFAV_POST_SUCCESS';
export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_REMOVE_LETTERS = 'BLOCK_USER_REMOVE_LETTERS';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const UNBLOCK_USER = 'UNBLOCK_USER';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const HIDE_USER = 'HIDE_USER';
export const HIDE_USER_SUCCESS = 'HIDE_USER_SUCCESS';
export const UNHIDE_USER = 'UNHIDE_USER';
export const UNHIDE_USER_SUCCESS = 'UNHIDE_USER_SUCCESS';

// export const UPDATE_WEATHER = 'UPDATE_WEATHER'
export const UPDATE_FRIEND_EXTEND = 'UPDATE_FRIEND_EXTEND'

export const UPDATE_PHOTO_SHARING = 'UPDATE_PHOTO_SHARING';
export const UPDATE_PHOTO_SHARING_SUCCESS = 'UPDATE_PHOTO_SHARING_SUCCESS';
export const UPDATE_AUDIO_SHARING = 'UPDATE_AUDIO_SHARING';
export const UPDATE_AUDIO_SHARING_SUCCESS = 'UPDATE_AUDIO_SHARING_SUCCESS';

export const REPORT_USER = 'REPORT_USER';

export const REPORT_NEW_USER = 'REPORT_NEW_USER';
export const HIDE_NEW_USER = 'HIDE_NEW_USER';
export const HIDE_NEW_USER_SUCCESS = 'HIDE_NEW_USER_SUCCESS';

export const SAVE_REMARK = 'SAVE_REMARK';
export const SAVE_REMARK_SUCCESS = 'SAVE_REMARK_SUCCESS';

export const GET_HIDDEN = 'GET_HIDDEN';
export const GET_HIDDEN_SUCCESS = 'GET_HIDDEN_SUCCESS';
export const GET_HIDDEN_ERROR = 'GET_HIDDEN_ERROR';

export const GET_BLOCKED = 'GET_BLOCKED';
export const GET_BLOCKED_SUCCESS = 'GET_BLOCKED_SUCCESS';
export const GET_BLOCKED_ERROR = 'GET_BLOCKED_ERROR';

export const GET_LETTERS = 'GET_LETTERS';
export const GET_LETTERS_SUCCESS = 'GET_LETTERS_SUCCESS';
export const GET_LETTERS_ERROR = 'GET_LETTERS_ERROR';

export const SAVE_POST_PASS = 'SAVE_POST_PASS';

export const GET_LATEST = 'GET_LATEST';
export const GET_LATEST_SUCCESS = 'GET_LATEST_SUCCESS';
export const RELOAD_LATEST = 'RELOAD_LATEST';

export const GET_UNREAD = 'GET_UNREAD';
export const GET_UNREAD_SUCCESS = 'GET_UNREAD_SUCCESS';

export const READ_LETTER = 'READ_LETTER';
export const READ_LETTER_SUCCESS = 'READ_LETTER_SUCCESS';
export const CHECK_NEW_STAMP = 'CHECK_NEW_STAMP';
export const CLEAR_FOUND_STAMP = 'CLEAR_FOUND_STAMP'
export const READ_LETTERS_CLOSE = 'READ_LETTERS_CLOSE';

export const DELETE_LETTER = 'DELETE_LETTER'
export const DELETE_LETTER_SUCCESS = 'DELETE_LETTER_SUCCESS'

// export const IMG_UPLOAD_INIT = 'IMG_UPLOAD_INIT';
// export const IMG_UPLOAD_SUCCESS = 'IMG_UPLOAD_SUCCESS';
// export const IMG_UPLOAD_ERROR = 'IMG_UPLOAD_ERROR';
// export const IMG_UPLOAD_COMPLETE = 'IMG_UPLOAD_COMPLETE';

export const HIDE_STAMP = 'HIDE_STAMP'

export const BEGIN_WRITING = 'BEGIN_WRITING';
export const FINISH_WRITING = 'FINISH_WRITING';
export const CHANGE_STAMP = 'CHANGE_STAMP';
// export const LETTER_REPLY_INIT = 'LETTER_REPLY_INIT';
export const LETTER_REPLY_SUCCESS = 'LETTER_REPLY_SUCCESS';
export const LETTER_REPLY_ERROR = 'LETTER_REPLY_ERROR';
export const ADD_FRIEND = 'ADD_FRIEND'
export const USE_STAMP_TIPS_SHOWN = 'USE_STAMP_TIPS_SHOWN'

export const ADD_PHOTO = 'ADD_PHOTO';
export const ADD_PHOTO_SUCCESS = 'ADD_PHOTO_SUCCESS';
export const ADD_PHOTO_ERROR = 'ADD_PHOTO_ERROR';
export const REMOVE_PHOTO = 'REMOVE_PHOTO';
export const REMOVE_PHOTO_SYNC = 'REMOVE_PHOTO_SYNC';
export const REMOVE_PHOTO_SUCCESS = 'REMOVE_PHOTO_SUCCESS';
export const REMOVE_PHOTO_ERROR = 'REMOVE_PHOTO_ERROR';

export const SAVE_AUTOMATCH_DRAFT = 'SAVE_AUTOMATCH_DRAFT';
export const SAVE_AUTOMATCH_PREF = 'SAVE_AUTOMATCH_PREF';
export const AUTO_SEARCH = 'AUTO_SEARCH';
export const AUTO_SEARCH_SUCCESS = 'AUTO_SEARCH_SUCCESS';
export const AUTO_MATCH_COMPLETE = 'AUTO_MATCH_COMPLETE';
export const AUTO_MATCH_NO_MORE_RESULT = 'AUTO_MATCH_NO_MORE_RESULT';
export const TOGGLE_AUTOMATCH_RESULT = 'TOGGLE_AUTOMATCH_RESULT'
export const AUTO_MATCH_SEND_NOW = 'AUTO_MATCH_SEND_NOW'
export const AUTO_MATCH_SEND_FINISH = 'AUTO_MATCH_SEND_FINISH'

export const NEW_FRIEND_ADDED = 'NEW_FRIEND_ADDED';

export const FETCH_USERS = 'FETCH_USERS';
export const RESET_EXPLORE = 'RESET_EXPLORE'
export const FETCH_USERS_SUCCESS = 'FETCH_USER_SUCCESS';
export const SAVE_EXPLORE_FILTERS = 'SAVE_EXPLORE_FILTERS'
export const SAVE_EXPLORE_TEMP_COUNTRIES = 'SAVE_EXPLORE_TEMP_COUNTRIES'

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const FETCH_SLOWLY = 'FETCH_SLOWLY';
export const FETCH_SLOWLY_SUCCESS = 'FETCH_SLOWLY_SUCCESS';
export const FETCH_SLOWLY_ERROR = 'FETCH_SLOWLY_ERROR';
export const GET_COUNTRIES_INIT = 'GET_COUNTRIES_INIT';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';

export const UPDATE_HELLO = 'UPDATE_HELLO'
export const UPDATE_LANG = 'UPDATE_LANG'

export const NAME_CHECK = 'NAME_CHECK';
export const NAME_CHECK_DONE = 'NAME_CHECK_DONE';
export const NAME_CHECK_AND_UPDATE = 'NAME_CHECK_AND_UPDATE';

export const SAVE_PERMISSION = 'SAVE_PERMISSION'

export const IS_MAINTENANCE = 'IS_MAINTENANCE'
export const SAVE_SLOWLY_VERSION = 'SAVE_SLOWLY_VERSION'
export const UPGRADE_ASKED = 'UPGRADE_ASKED'

export const RESTORE_DATA = 'RESTORE_DATA'
