import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import { NavLink } from 'react-router-dom'
import Avatar from "../components/Avatar";
import { idvInit } from '../api/slowly.api';
// import _ from "lodash";
import moment from "moment"


class VerifyNow extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: false,
        pending: false
    };
  }

  componentDidMount() {
    const { me } = this.props;
    global.log('me', me)
    //check idv status
    if(!!me.idv && me.idv.status>2){
        const last_update = moment.utc(me.idv.updated_at)
        const _now = moment.utc()
        const mins = _now.diff(last_update, 'minutes')

        global.log('idv last scan: ' + mins + ' minutes ago')

        if(mins<35){
            this.setState({
                pending: true
            })
            this.props.refreshMe(me.token)
        }
    }
  }

  init = async () => {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    const { token } = this.props.me;

    try {
      const result = await idvInit({ token });
      if(result.url) window.top.location.href= result.url;

    } catch (error) {
      global.log('idv error', error);
    }
  };

  render() {
    const { me } = this.props;

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu idv_available={this.props.me.idv_available} />
            </div>
            <div className="col-9 p-3  ">
              <div className="card shadow-sm my-2">
                <div className="card-body">
                  <div className="w-100 position-relative my-3">
                    <div className="avatar-wrapper-2" dir="ltr">
                      <Avatar
                        key={me.id}
                        uid={me.id}
                        id={me.avatar}
                        gender={me.gender}
                        name={me.name}
                        size={180}
                      />
                      <div
                        className="idv-badge bg-white avatar-border"
                      >
                        <i className="icon-shield color-idv" />
                      </div>
                    </div>
                  </div>
                  <h4
                    className="mb-1 text-darker text-extra-bold"
                  >
                    {I18n.t('GET_VERIFIED')}
                  </h4>
                  <p>{I18n.t('VERIFT_DESC')}</p>
                  <p className="text-lighter strong">{I18n.t('HOW_THIS_WORKS')}</p>
                  <ul>
                    <li>
                    {I18n.t('VERIFY_REQ_PT1')}
                    </li>
                    <li>
                    {I18n.t('VERIFY_REQ_PT3')}
                    </li>
                    <li>
                    {I18n.t('VERIFY_REQ_PT4')}
                    </li>
                  </ul>
                  <p><a href="https://slowly.app/id-verification/" target="_blank" rel="noopener noreferrer" ><u>{I18n.t('LEARN_MORE')}</u></a></p>
                  <hr />
                  { !!me.idv && me.idv.status===2 ? (
                    <div
                        className="btn btn-light btn-block"
                    >
                        <i className="icon-shield mr-1 color-idv" /> {I18n.t("VERIFIED_LABEL")}
                    </div>
                  ): me.role<20 ? (
                    <NavLink className="btn btn-dark btn-block" to="/upgrade">
                        {I18n.t("CONTINUE")}
                    </NavLink>
                  ) : !!this.state.pending ? (
                    <button
                        type="button"
                        className="btn btn-light btn-block"
                        disabled
                    >
                        {I18n.t("PENDING_REQUEST")}
                    </button>
                  ):(
                    <button
                        type="button"
                        className="btn btn-dark btn-block"
                        disabled={!!this.state.loading}
                        onClick={this.init}
                    >
                        {I18n.t("CONTINUE")}
                    </button>
                  )}
                    
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    darkMode: state.slowly.darkMode,
  };
};

const refreshMe = function refreshMe(token) {
  return {
    type: "GOT_TOKEN",
    token,
    skip: true,
  };
};

const updateMe = function updateMe(token, updateFields) {
  return {
    type: "UPDATE_ME",
    token,
    updateFields,
  };
};

export default connect(mapStateToProps, {
  refreshMe,
  updateMe
})(VerifyNow);
