/* global gtag */

const view = viewName => {
  if(!viewName || viewName.length<=7 ) return true;
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

  try{
    const newName = viewName.slice(7);
    global.log('view',newName)
    // ReactGA.pageview(newName);
  }catch(error){
    global.log(error)
  }
};

const user = user_id => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  gtag('config', 'G-0LWCNV8FXQ', { user_id });
  global.log('[GA] user signed in, ads consent granted')

  // if(localStorage.getItem("consentGranted")!=="true"){
  // global.log('[GA] flag not found, setup script now')
  // localStorage.setItem("consentGranted", "true");
  gtag('consent', 'updated', {
    'ad_user_data': 'granted',
    'ad_personalization': 'granted',
    'ad_storage': 'granted',
    'analytics_storage': 'granted',
    'wait_for_update': 500,
  });

  var gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-0LWCNV8FXQ'
  
  var firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(gtagScript,firstScript);
  // }
};

const event = (cat, action=null, label=null, payload={}) => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  if(action===null){
    global.log('event', cat)
    gtag('event', cat);
  }else{
    global.log('event', action)
    gtag('event', action, {
      'event_category': cat,
      'event_label': label,
      payload
    });
  }
};

const purchase = (transaction_id, value, currency, tax=0, items) => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  gtag('event', 'purchase', {
    transaction_id,
    "affiliation": "Web Store",
    value,
    currency,
    tax,
    shipping: 0,
    items
  });
}

const setProp = ( name, value ) =>{
  // try{
  //   firebase.analytics().setUserProperty(name, value)
  // }catch(error){
  //   global.log(error)
  // }
}

const _track = {
  user,
  view,
  event,
  purchase,
  setProp
}

export default _track;
