import moment from 'moment';
const calShort = {
  'en': {
    sameDay: 'h:mm A',
    nextDay: '[Tomorrow]',
    nextWeek: 'ddd',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'pl': {
    sameDay: 'h:mm A',
    nextDay: '[Jutro]',
    nextWeek: 'ddd',
    lastDay: '[Wczoraj]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'hu': {
    sameDay: 'h:mm A',
    nextDay: '[Holnap]',
    nextWeek: 'ddd',
    lastDay: '[Tegnap]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'tr': {
    sameDay: 'h:mm A',
    nextDay: '[Yarın]',
    nextWeek: 'ddd',
    lastDay: '[Dün]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'ru': {
    sameDay: 'h:mm A',
    nextDay: '[Завтра]',
    nextWeek: 'ddd',
    lastDay: '[Вчера]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'sk': {
    sameDay: 'h:mm A',
    nextDay: '[zajtra]',
    nextWeek: 'ddd',
    lastDay: '[včera]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'es': {
    sameDay: 'h:mm A',
    nextDay: '[mañana]',
    nextWeek: 'ddd',
    lastDay: '[Ayer]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'de': {
    sameDay: 'h:mm A',
    nextDay: '[Morgen]',
    nextWeek: 'ddd',
    lastDay: '[Gestern]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'ko': {
    sameDay: 'h:mm A',
    nextDay: '[내일]',
    nextWeek: 'ddd',
    lastDay: '[어제]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'pt': {
    sameDay: 'h:mm A',
    nextDay: '[Amanhã]',
    nextWeek: 'ddd',
    lastDay: '[Ontem]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'it': {
    sameDay: 'h:mm A',
    nextDay: '[Domani]',
    nextWeek: 'ddd',
    lastDay: '[Ieri]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  },
  'zh-tw': {
    sameDay: 'a h:mm',
    nextDay: '[明天]',
    nextWeek: '[下]dddd',
    lastDay: '[昨天]',
    lastWeek: 'MMMD[日]',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMMD[日]';
      } else {
        return 'YY[年]MMMD[日]';
      }
    }
  },
  'zh-cn': {
    sameDay: 'a h:mm',
    nextDay: '[明天]',
    nextWeek: '[下]dddd',
    lastDay: '[昨天]',
    lastWeek: 'MMMD[日]',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMMD[日]';
      } else {
        return 'YY[年]MMMD[日]';
      }
    }
  },
  'ja': {
    sameDay: 'h:mm a',
    nextDay: '[明日]',
    nextWeek: '[次の]dddd',
    lastDay: '[昨日]',
    lastWeek: 'MMM Do',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM Do';
      } else {
        return 'LL';
      }
    }
  },
  'si': {
    sameDay: 'h:mm a',
    nextDay: '[හෙට]',
    nextWeek: 'ddd',
    lastDay: '[ඊයේ]',
    lastWeek: 'MMM Do',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM Do';
      } else {
        return 'LL';
      }
    }
  },
  'ca': {
    sameDay: 'h:mm a',
    nextDay: '[Demà]',
    nextWeek: 'ddd',
    lastDay: '[Ahir]',
    lastWeek: 'MMM Do',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM Do';
      } else {
        return 'LL';
      }
    }
  },
  'sv': {
    sameDay: 'h:mm a',
    nextDay: '[I morgon]',
    nextWeek: 'ddd',
    lastDay: '[I går]',
    lastWeek: 'MMM Do',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM Do';
      } else {
        return 'LL';
      }
    }
  },
  'fil': {
    sameDay: 'h:mm A',
    nextDay: '[Bukas]',
    nextWeek: 'ddd',
    lastDay: '[Kahapon]',
    lastWeek: 'MMM D',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMM D';
      } else {
        return 'l';
      }
    }
  }
};
const calFull = {
  en: {
    sameDay: '[Today] h:mm A',
    nextDay: '[Tomorrow] h:mm A',
    nextWeek: '[Next] ddd h:mm A',
    lastDay: '[Yesterday] h:mm A',
    lastWeek: '[Last] ddd h:mm A',
    sameElse: 'lll'
  },
  'zh-tw': {
    sameDay: '[今天]a h:mm',
    nextDay: '[明天]a h:mm',
    nextWeek: '[下]dddd a h:mm ',
    lastDay: '[昨天]a h:mm',
    lastWeek: 'MMMD[日]a h:mm',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMMD[日]a h:mm';
      } else {
        return 'YY[年]MMMD[日]';
      }
    }
  },
  'zh-cn': {
    sameDay: '[今天]a h:mm',
    nextDay: '[明天]a h:mm',
    nextWeek: '[下]dddd a h:mm',
    lastDay: '[昨天]a h:mm',
    lastWeek: 'MMMD[日] a h:mm',
    sameElse: function(now) {
      if (this.year() === now.year()) {
        return 'MMMD[日] a h:mm';
      } else {
        return 'YY[年]MMMD[日]';
      }
    }
  },
  'pt': {
    sameDay: '[Hoje] h:mm a',
    nextDay: '[Amanhã] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Ontem] h:mm a',
    sameElse: 'll'
  },
  'pl': {
    sameDay: '[Dzisiaj] h:mm a',
    nextDay: '[Jutro] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Wczoraj] h:mm a',
    sameElse: 'll'
  },
  'hu': {
    sameDay: '[Ma] h:mm a',
    nextDay: '[Holnap] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Tegnap] h:mm a',
    sameElse: 'll'
  },
  'tr': {
    sameDay: '[Bugün] h:mm a',
    nextDay: '[Yarın] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Dün] h:mm a',
    sameElse: 'll'
  },
  'ru': {
    sameDay: '[сегодня] h:mm a',
    nextDay: '[Завтра] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Вчера] h:mm a',
    sameElse: 'll'
  },
  'sk': {
    sameDay: '[dnes] h:mm a',
    nextDay: '[zajtra] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[včera] h:mm a',
    sameElse: 'll'
  },
  'es': {
    sameDay: '[Hoy] h:mm a',
    nextDay: '[mañana] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Ayer] h:mm a',
    sameElse: 'll'
  },
  'it': {
    sameDay: '[Oggi] h:mm a',
    nextDay: '[Domani] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Ieri] h:mm a',
    sameElse: 'll'
  },
  'de': {
    sameDay: '[Heute] h:mm a',
    nextDay: '[Morgen] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Gestern] h:mm a',
    sameElse: 'll'
  },
  'ko': {
    sameDay: '[오늘] h:mm a',
    nextDay: '[내일] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[어제] h:mm a',
    sameElse: 'll'
  },
  'ca': {
    sameDay: '[Avui] h:mm a',
    nextDay: '[Demà] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Ahir] h:mm a',
    sameElse: 'll'
  },
  'sv': {
    sameDay: '[I dag] h:mm a',
    nextDay: '[I morgon] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[I går] h:mm a',
    sameElse: 'll'
  },
  'fil': {
    sameDay: '[Ngayon] h:mm a',
    nextDay: '[Bukas] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Kahapon] h:mm a',
    sameElse: 'll'
  }
};
const timezoneOffset = new Date().getTimezoneOffset();

const custom = (dateString, format) => {
  const locale = moment.locale()

  switch (format) {
    case 'short':
      return moment.utc(dateString).utcOffset(-timezoneOffset).format('ll');
    case 'long':
      return moment.utc(dateString).utcOffset(-timezoneOffset).format('lll');
    case 'calendar-local':
      return moment(dateString).calendar(null, calShort[locale]);
    case 'calendar':
      return moment.utc(dateString).utcOffset(-timezoneOffset).calendar(null, calShort[locale]);
    case 'calendar-full':
      return moment.utc(dateString).utcOffset(-timezoneOffset).calendar(null, calFull[locale]);
    case 'fromNow':
      moment.relativeTimeThreshold('h', 24);
      return moment.utc(dateString).utcOffset(-timezoneOffset).fromNow();
    case 'mins':
      moment.relativeTimeThreshold('h', 24*4);
      return moment().add(dateString, 'minutes').fromNow();
    case 'toNow':
      moment.relativeTimeThreshold('h', 24);
      return moment.utc(dateString).utcOffset(-timezoneOffset).toNow(true);
    case 'fromNowShort':
      moment.relativeTimeThreshold('h', 24);
      return moment(dateString).fromNow(true);
    case 'year':
      return moment(dateString).format('YYYY');
    case 'shortbday':
      return moment(dateString).format('ll');
    case 'birthday':
      return moment(dateString).format('MMM Do');
    case 'joined':
      if(locale==='zh-cn' || locale==='zh-tw' || locale==='ja' )
      return moment.utc(dateString).utcOffset(-timezoneOffset).format('YYYY[年]MMM');
      if(locale==='ko')
      return moment.utc(dateString).utcOffset(-timezoneOffset).format('YYYY[년]MMM');
      return moment.utc(dateString).utcOffset(-timezoneOffset).format('MMM YYYY');
    default:
      return moment(dateString);
  }
};


const _moment = {
  orig: moment,
  locale: moment.locale,
  custom
}

export default _moment