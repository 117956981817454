import React from 'react';
import { CDN_URL } from "../config/ApiConfig";
// import colors from '../assets/colors'

export default class Avatar extends React.PureComponent {

  render() {
    const { size, id, name, gender, onClick=null, extraClass="" } = this.props;
    if(!id) return null
    
    const url = id.length <=2 ? CDN_URL + "/assets/images/avatar/"+gender + "/" + id + ".png"
                              : CDN_URL + "/assets/images/avatar/other/" + id + ".png"

    // const borderWidth = size < 60 ? 2 : size > 80 ? 4 : 3;

    return (
      <img
        src={url}
        className={ !!onClick ? "rounded-circle link avatar-border "+extraClass : "rounded-circle avatar-border "+extraClass}
        alt={name}
        width={size} height={size}
        key={'avatar-'+id}
        onClick={onClick}
      />
    );
  }
}
