import React from "react";
import I18n from "../I18n";
import Avatar from "./Avatar";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { reProfileMe, reFriendPosts, reKeyedItems } from "../selectors";
import Letter from "./Letter";
import FriendProfile from "./FriendProfile";
import hashids from "../lib/hashids";
import _ from "lodash"
import moment from '../lib/moment'
import AppAlert from "../components/AppAlert";
import { getAttachments } from '../api/letters.api'
import { filter } from 'lodash'
import { API_URL } from '../config/ApiConfig'
import { toast } from "react-toastify";
import BlueBadgeWithTooltip from "./BlueBadgeWithTooltip";

const attachmentPath = API_URL + '/web/attachments/';

class Letters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFriendModal: false,
      prevLink: null,
      nextLink: null,
      isFuture: false,
      alert: null,
      fromNow: '',
      audio: null,
      wordCount: '...',
      charCount: '...'
    }
  }

  tick = () => {
    global.log('tick');

    const isFuture = moment.orig.utc(this.props.currentLetter.deliver_at) > moment.orig.utc() ? true : false
    this.setState({
      isFuture,
      fromNow: moment.custom(this.props.currentLetter.deliver_at, 'fromNow')
    })
    if(!isFuture && this.props.currentLetter.body===null) this.revealThis();
  }

  revealThis = () => {
    global.log('Reveal now');

    this.refreshLetters()
    if(!!this.timerID) clearInterval(this.timerID)
  }

  refreshLetters = () => {
    const { me, friend, getLetters, checkIncoming } = this.props;
    getLetters(me.token, friend.id, 1);
    checkIncoming();
  }

  componentWillUnmount(){
    if(!!this.timerID) clearInterval(this.timerID)
  }

  componentDidMount() {
    this.updateLetter(this.props)
  }

  updateLetter = props => {
    if (props.scrollToTop) props.scrollToTop();
    this.setupLinks(props)
  }

  setupLinks = props => {
    const { next_page_url, data, current_page } = props.post
    const { friend, setReplyButton, me, currentLetter, readLetter, loading } = props;

    if(!currentLetter) return false;

    if(currentLetter.type===4 || currentLetter.type===5) this.loadExtra(props);
    else this.setState({ audio: null })

    const isFuture = moment.orig.utc(currentLetter.deliver_at) > moment.orig.utc() ? true : false

    if((moment.orig.utc() >= moment.orig.utc(props.currentLetter.deliver_at).subtract(4,'hours') && !!isFuture && currentLetter.user!==me.id) || currentLetter.body===null ){
      global.log("Start count down")

      this.timerID = setInterval(
        () => this.tick(),
        1000
      );
    }else{
      if(!!this.timerID) clearInterval(this.timerID)
    }

    if (
        currentLetter.user_to === me.id &&
        currentLetter.read_at === null &&
        moment.orig.utc(currentLetter.deliver_at) < moment.orig.utc()
      )
        readLetter({
          token: me.token,
          commentID: currentLetter.id,
          postID: currentLetter.post,
          stamp: currentLetter.stamp
        });

    const baseURL = "/friend/" + hashids.encode(friend.id) + '/'
    const currentIndex = _.findIndex(props.post.data, ['id', currentLetter.id])

    const prevLink = currentIndex===0 ? null : baseURL + hashids.encode(data[currentIndex-1].id)
    const nextLink = (data.length-1===currentIndex) ? null : baseURL + hashids.encode(data[currentIndex+1].id)

    this.setState({
      isFuture,
      fromNow: moment.custom(currentLetter.deliver_at, 'fromNow'),
      wordCount: I18n.wordCount(currentLetter.body),
      charCount: I18n.charCount(currentLetter.body),
      prevLink,
      nextLink
    })
    setReplyButton(!!friend.user_status
      ? friend.user_status >= 1
        ? true
        : false
      : false);

    if(data.length-2 <= currentIndex && !!next_page_url && !loading){
      this.props.getLetters(me.token, friend.id, current_page + 1);
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.letterID !== nextProps.letterID) this.updateLetter(nextProps);
  }

  showFriend = () => {
    this.setState({
      showFriendModal: true
    });
  };

  friendModalClosed = () => {
    this.setState({
      showFriendModal: false
    });
  };

  closeAlert = () => {
    this.setState({ alert: null })
  }

  _deleteLetter = () => {
    this.setState({
      alert:{
        title: I18n.t('CONFIRM_DELETE_LETTER'),
        message: I18n.t('REMOVE_LETTER_REMINDER'),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: this.closeAlert,
        confirmLabel: I18n.t("DELETE_LETTER"),
        confirmAction: () => {
          this.props.deleteLetter({
            token: this.props.me.token,
            id: this.props.currentLetter.id
          })
        },
        danger: true
      }
    })
  }

  loadExtra = async(props) => {

    const p = props.friend.pass
    const u = props.me.hashid
    const item = props.currentLetter;
    const { token } =props.me;
    const results = await getAttachments({ token, id: item.post, letter_id: item.id });

    global.log('loadExtra', results)

    this.setState({
      extra: item.type===5 ? filter(results, i => i.type!=='audio' ) : results
    })

    if(item.type===5 ){
      const audio = filter(results, i => i.file_type==='audio' )
      if(audio.length<=0) return;

      this.setState({
        audio: attachmentPath + audio[0].file_path +'?_p='+p+'&_u='+u,
        duration: audio[0].duration
      })

      global.log('audio', audio)
    }
  }

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.props.currentLetter.body)
    toast.info("✔︎ " +I18n.t("COPIED_TO_CLIPBOARD"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
    });
  }

  editLetter = () => {
    const { me } = this.props

    if(me.role<20){
      this.setState({
        alert:{
          title: I18n.t('IN_TRANSIT_EDIT'),
          message: I18n.t('PAYWALL_IN_TRANSIT_EDIT_DESC'),
          cancelLabel: I18n.t("REG_SKIP"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("UPGRADE_NOW"),
          confirmAction: () => {
            this.props.history.push("/upgrade");
          },
          emoji: '🔒'
        }
      })

      return true;
    }

    if(this.props.editLetter) this.props.editLetter(this.props.currentLetter)
  }



  render() {
    const {
      friend,
      me,
      currentLetter,
      slowlyItems,
      friendIds
    } = this.props;

    const { showFriendModal, prevLink, nextLink, isFuture } = this.state;

    if(!friend || friendIds.indexOf(friend.id)<0) return ( <Redirect to="/home" /> )
    if (!currentLetter) return <Redirect to={"/friend/" + hashids.encode(friend.id) + '/'} />;

    global.log('currentLetter', currentLetter)
    global.log('friend', friend)

    return (
      <div className="mt-n3">
        <div className="sticky-top friend-header-wrapper">
          <div className="friend-header p-2 pl-4 pr-4 pb-1">
            <div className="row">
              <NavLink
                to={"/friend/"+hashids.encode(friend.id)}
                className="no-underline link py-2 px-2 ml-n2 col-pixel-width-50 flip"
              >
                <i className="icon-caret-right " />
              </NavLink>
              <div
                className={ currentLetter.user!==this.props.me.id ? "col-pixel-width-50 pl-1 link" : "col-pixel-width-50 pl-1"}
                onClick={ currentLetter.user!==this.props.me.id ? this.showFriend : undefined}
              >
                <Avatar
                  key={currentLetter.user}
                  uid={currentLetter.user}
                  id={currentLetter.avatar}
                  gender={currentLetter.gender}
                  name={currentLetter.name}
                  size={42}
                />
              </div>
              <div className="col ml-n1">
                <div
                  className="text-primary h6"
                  style={{ marginBottom: .5, marginTop: 0 }}
                >
                  {currentLetter.name}
                  {(currentLetter.user===me.id && !!me.idv && me.idv.status===2) && <BlueBadgeWithTooltip />}
                  {(currentLetter.user===friend.user_id && !!friend.identity) && <BlueBadgeWithTooltip />}
                </div>
                <div>
                  <span className="text-light small pr-1">
                    {isFuture && (
                      <span className="text-secondary mr-2 strong">
                        <i className="icon-send mr-1"/>{I18n.t('IN_TRANSIT')}
                      </span>
                    )}
                    {moment.custom(currentLetter.deliver_at, 'calendar')}
                  </span>
                  { !isFuture && (
                    <span className="text-light small pr-1">
                      <i className="icon-info-circle px-1" />
                      {I18n.t('Words')+': ' + this.state.wordCount }
                      { ' / ' + I18n.t('Characters')+': ' + this.state.charCount }
                    </span>
                  )}
                  { currentLetter.attachments && (
                    <span className="text-light small">
                      <i className="icon-attachment-2 px-1" />
                    </span>
                  )}
                  { currentLetter.type===5  && (
                    <span className="text-light small">
                      <i className="icon-mic px-1" />
                    </span>
                  )}
                </div>
              </div>
              { !!this.props.isRTL ? (
                <div className="col-pixel-width-200 text-right h5 mt-2  mr-n1">
                  { !nextLink ? (
                    <span className="icon-chevron-left py-2 px-2 mx-1 text-lighter" />
                  ): (
                    <NavLink
                      to={nextLink}
                      className="no-underline link py-2 px-3 mx-1"
                    >
                      <i className="icon-chevron-left" />
                    </NavLink>
                  )}

                  { !prevLink ? (
                    <span className="icon-chevron-right py-2 px-3 mx-1 text-lighter" />
                  ): (
                    <NavLink
                      to={prevLink}
                      className="no-underline link py-2 px-3 mx-1"
                    >
                      <i className="icon-chevron-right" />
                    </NavLink>
                  )}
                </div>
              ): (
                <div className="col-pixel-width-200 text-right mr-n1">
                  <button
                    type="button"
                    className="btn px-2 mx-1 my-0 text-primary"
                    onClick={this.copyToClipboard}
                    disabled={!navigator.clipboard}
                  >
                    <span className="icon-copy h5 font-weight-normal" />
                  </button>
                  <button
                    type="button"
                    className="btn px-2 mx-1 my-0 text-primary"
                    onClick={()=>{
                      window.print()
                    }} >
                    <span className="icon-print h5 font-weight-normal" />
                  </button>
                  { !prevLink ? (
                    <span className="icon-chevron-left py-2 px-3 mx-1 text-lighter" />
                  ): (
                    <NavLink
                      to={prevLink}
                      className="no-underline link py-2 px-3 mx-1"
                    >
                      <i className="icon-chevron-left" />
                    </NavLink>
                  )}
                  { !nextLink ? (
                    <span className="icon-chevron-right py-2 px-3 mx-1 text-lighter" />
                  ): (
                    <NavLink
                      to={nextLink}
                      className="no-underline link py-2 px-3 mx-1"
                    >
                      <i className="icon-chevron-right" />
                    </NavLink>
                  )}
                </div>
              )}

            </div>
          </div>
          <hr className="my-0" />
        </div>
        <div className="friend-Letter-wrapper p-3 pl-4 pr-4 ">
          { ((!!isFuture && currentLetter.user !==me.id) || currentLetter.body===null) ?
            (
              <div className="w-100 text-center mt-5">
                <img src={ require('../assets/images/sending.png')} style={{ width: 480 }} alt={I18n.t('INCOMING_MSG_ONE')} />

                <p className="text-lighter mt-3">
                  <i className="icon-send text-calm mr-2" />
                  { I18n.t('MAP_ARRIVING', { time: this.state.fromNow }) }
                </p>
              </div>
            ) : (
            <Letter
              show
              item={currentLetter}
              token={this.props.me.token}
              handleClose={this.letterClosed}
              p={friend.pass}
              u={me.hashid}
              extra={this.state.extra}
              audio={this.state.audio}
              searchText={this.props.post.searchText}
              duration={this.state.duration}
              stampInfo={slowlyItems[currentLetter.stamp]}
              _deleteLetter={this._deleteLetter}
              isFuture={isFuture}
            />
          )}
        </div>

        { !!this.props.showReplyButton && !!isFuture && currentLetter.user===me.id && (
          <button 
            type="button"
            className="btn btn-outline-primary btn-lg btn-edit shadow-lg print-hidden"
            onClick={this.editLetter}
            >
            { I18n.t('IN_TRANSIT_EDIT')}  <i className="icon-plus-inline text-gold" />
          </button>
        )}

        <FriendProfile
          show={showFriendModal}
          postID={friend.id}
          handleClose={this.friendModalClosed}
        />

        <AppAlert alert={this.state.alert} />
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getFriendPosts = reFriendPosts();

  const mapStateToProps = (state, props) => {
    return {
      me: reProfileMe(state),
      post: getFriendPosts(state, props),
      currentLetter: state.letters.data[props.letterID],
      friend: state.contacts[props.postID],
      friendIds: state.contacts.friendIds,
      slowlyItems: reKeyedItems(state),
      loading: state.letters.loading,
      isRTL: state.slowly.isRTL
    };
  };
  return mapStateToProps;
};

const getLetters = function getLetters(token, id, page) {
  global.log({ id, page });

  return {
    type: "GET_LETTERS",
    token,
    id,
    page
  };
};

const checkIncoming = function checkIncoming() {

  return {
    type: "CHECK_INCOMING",
  };
};

const readLetter = function readLetter({ token, commentID, postID, stamp }) {
  return {
    type: 'READ_LETTER',
    token,
    commentID,
    postID,
    stamp
  };
};

const deleteLetter = function deleteLetter({ token, id }){
  return {
    type: 'DELETE_LETTER',
    token,
    id
  };
}

export default connect(
  makeMapStateToProps,
  {
    getLetters,
    readLetter,
    deleteLetter,
    checkIncoming
  }
)(Letters);
