import React from 'react';
import './assets/shared.css';
import './assets/bootstrap.css';
// import './assets/dark-mode.min.css';

import { Provider, useSelector } from "react-redux";
import { PersistGate } from 'redux-persist/lib/integration/react';
import Loader from './components/Loader';
import Header from './components/Header';
import { store, persistor } from './store';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Home from './screens/Home';
import Login from './screens/Login';
import StampStore from './screens/StampStore';
import Drafts from './screens/Drafts';
import MyStamps from './screens/MyStamps';
import Topup from './screens/Topup';
import Upgrade from './screens/Upgrade';
import Promo from './screens/Promo';
import Plus from './screens/Plus';
import Refresh from './screens/Refresh';

import MyProfile from './screens/MyProfile';
import About from './screens/About';
import Settings from './screens/Settings';
import MatchingPref from './screens/MatchingPref';
import Topics from './screens/Topics';
import ExcludedTopics from './screens/ExcludedTopics';
import ExcludedRegions from './screens/ExcludedRegions';
import Langs from './screens/Langs';
import HiddenFriends from './screens/HiddenFriends';
import RemovedFriends from './screens/RemovedFriends';

//v9
import VerifyNow from './screens/VerifyNow';
import Logout from './screens/Logout';

import { ToastContainer } from 'react-toastify';
import ScrollMemory from 'react-router-scroll-memory';

function PrivateRoute({ component: Component, ...rest }) {
  const loading = useSelector(state => state.slowly.loading)
  const token = useSelector(state => state.me.token)

  return (
    <Route
      {...rest}
      render={props =>
        (!!token && !loading) ? (
          <Component {...props}/>
        ) : (!!token && !!loading) ? (
          <Loader />
        ): (
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        )
      }
    />
  );
}

const App = () => {
    return (
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Router>
            <div className="App">
              <Header />
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/canny" component={Login} />
                <Route path="/promo/:code?/:returnedhash?/" component={Promo} />
                <Route path="/refresh/:type?/:product?/" component={Refresh} />
                <Route path="/subscription" component={Plus} />
                <Route path="/logout" component={Logout} />
                <Route path="/upgrade" component={Upgrade} />
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/stamps" component={MyStamps} />
                <PrivateRoute path="/stamps/achievements/" component={MyStamps} />
                <PrivateRoute path="/drafts" component={Drafts} />
                <PrivateRoute path="/topup" component={Topup} />
                <PrivateRoute path="/friend/:id?/:letter?/" component={Home} />
                <PrivateRoute path="/friends/hidden" component={HiddenFriends} />
                <PrivateRoute path="/friends/removed" component={RemovedFriends} />
                <PrivateRoute path="/store/:mode?/:list?/" component={StampStore} />
                <PrivateRoute path="/about" component={About} />
                <PrivateRoute path="/profile" component={MyProfile} />
                <PrivateRoute path="/verify" component={VerifyNow} />
                <PrivateRoute path="/settings/preferences" component={MatchingPref} />
                <PrivateRoute path="/settings/topics/excluded" component={ExcludedTopics} />
                <PrivateRoute path="/settings/regions/excluded" component={ExcludedRegions} />
                <PrivateRoute path="/settings/topics" component={Topics} />
                <PrivateRoute path="/settings/languages" component={Langs} />
                <PrivateRoute path="/settings" component={Settings} />
                <Route component={ () => <Redirect
                  to={{
                    pathname: "/"
                  }}
                />} />
              </Switch>
              <ScrollMemory />
              <ToastContainer
                autoClose={2500}
                // hideProgressBar={true}
              />
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  // }
}

export default App;
