import React from 'react';
// import { Link } from "react-router-dom";

export default class MiniPlayer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sec: "00",
      min: "00",
      duration: 0.0,
      playing: false,
      loading: true
    };
  }

  secondsToTime = (secs) => {
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    return {
        "min": minutes < 10 ? "0" + minutes : minutes + "",
        "sec": seconds < 10 ? "0" + seconds : seconds + ""
    }
  }

  setupAudio = () => {
    this.player = new Audio(this.props.url);

    if(!!this.props.duration){
      const { min, sec } = this.secondsToTime(this.props.duration)
      this.setState({
        min,
        sec,
        duration: this.props.duration,
        playing: false
      });
    }

    this.player.addEventListener("timeupdate", e => {
      if(!this.state.playing || !!this.unmounted) return true;
      const seconds = e.target.currentTime;
      const { min, sec } = this.secondsToTime(seconds)

      this.setState({
        min,
        sec
      });
    });

    this.player.addEventListener('ended', this.reset);

    this.player.addEventListener('loadedmetadata', e => {
      if(!!this.unmounted) return true;
      this.setState({
        loading: false
      });
    });
  }

  componentDidMount(){
    this.setupAudio()
  }

  componentWillUnmount() {
    this.unmounted = true
    if(!!this.state.playing) this.player.pause()
    this.player.removeEventListener("timeupdate", ()=>{});
    this.player.removeEventListener("ended", ()=>{});
    this.player.removeEventListener("loadedmetadata", ()=>{});
  }

  reset = () => {
    if(!!this.unmounted) return true;
    this.setupAudio()
  }

  toggleAudio = () => {
    if(!!this.state.playing){
      this.player.pause();
      this.reset()
      this.setState({ playing: false })
    }else{
      this.setState({
        min: '00',
        sec: '00',
        playing: true
      });
      this.player.play()
    }
  }

  loaded = ev => {
    global.log('onLoadedMetadata', ev)
  }

  onListen = time => {
    global.log(time)
  }

  render() {
    const { loading, playing, sec, min } = this.state;

    return (
      <div>
        <span
          className="badge badge-pill border shadow-sm  badge-dark link btn-audio d-inline-flex align-items-center justify-content-center"
          onClick={this.toggleAudio}
        >
          { !!loading ? (
            <div className="spinner-border  spinner-border-sm text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) :
            <>
            <i className={!!playing ? "icon icon-checkbox mr-2" : "icon icon-play_arrow mr-2"} />
            <span>{ min + ':' + sec }</span>
            </>
          }
        </span>
        {!loading && (
          <a href={this.props.url}
            target="_blank" rel="noopener noreferrer" 
            className="btn px-3 mx-1 my-0 text-primary"
          >
            <span className="icon-download h5 font-weight-normal"></span>
          </a>
        )}
      </div>
    );
  }
}
