import slowly from './slowly.reducer';
import me from './me.reducer';
import contacts from './contacts.reducer';
import letters from './letters.reducer';
import drafts from './drafts.reducer';

const combined = {
  slowly,
  me,
  contacts,
  letters,
  drafts
}

export default combined;
