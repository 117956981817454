import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import Stamp from "../components/Stamp";
import Avatar from "../components/Avatar";
import { reProfileMe, reParagraphs, reLangs, getTrust } from "../selectors";
import { jwtDecode } from "jwt-decode"; 

import {
  deleteDraft,
  saveDraft,
  getDraft,
  reply,
  handleMyComment,
  uploadFile,
  removeFile,
  getAttachments,
  saveLetter,
} from "../api/letters.api";
import {
  userStatus,
  userProfile,
  makeFriend,
  userProfileExtend,
} from "../api/explore.api";
import { getQuota, matchingQuota, refreshWebToken } from "../api/me.api";
import { getSuggestion, replyOpenLetter } from "../api/openletters.api";
import { friendshipStatus } from "../api/posts.api";

import OpenLetter from "./OpenLetter";
import FriendProfile from "./FriendProfile";
import TextareaAutosize from "react-autosize-textarea";
import _ from "lodash";
import { toast } from "react-toastify";
import { API_URL } from "../config/ApiConfig";
import AppAlert from "../components/AppAlert";
import MyParagraphs from "../components/MyParagraphs";
import StampSelection from "../components/StampSelection";
import tracking from "../lib/tracking";
import MiniRecorder from "./MiniRecorder";
import { Scrollbars } from "react-custom-scrollbars";
import SplitPane from "react-split-pane";
import moment from "../lib/moment";

const attachmentPath = API_URL + "/web/attachments/";

async function asyncForEach(array, callback) {
  if (_.isArray(array))
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
}

const defaultState = {
  stamp: "free",
  body: "",
  loading: true,
  saving: false,
  lastSaved: "",
  draftStatus: null,
  attachments: [],
  onCloud: [],
  trash: [],
  alert: null,
  audio: null,
  quota: {
    photo: { remain: 5 },
    audio: {},
  },
  playing: false,
  showProfile: false,
  profileCached: false,
  wordCount: 0,
};

const ComposerWrapper = (props) => {
  if (props.standalone) {
    return <div className="Pane2">{props.children}</div>;
  } else {
    return (
      <SplitPane
        split="horizontal"
        minSize={80}
        maxSize={-200}
        defaultSize={350}
        primary="second"
        onDragFinished={props.onDragFinished}
      >
        {props.children}
      </SplitPane>
    );
  }
};

const ComposerHeader = (props) => {
  const {
    friend,
    fromDraft = {},
    showStampSelection,
    loading,
    stamp,
    showCount,
    hideCount,
    wordCount,
    charCount,
    showingCount = false,
    openletter,
  } = props;

  return (
    <div
      className="d-flex pt-2 px-3 align-items-center"
      style={{ minHeight: 122 }}
    >
      <div className="d-flex align-items-center mt-n2">
        <Avatar
          key={friend.id}
          uid={friend.id}
          id={friend.avatar}
          gender={friend.gender}
          name={friend.name}
          size={48}
        />
        <div className="ml-3 mt-1">
          <div className="mb-0 text-light">
            {!!fromDraft.new_friend && fromDraft.channel !== "openletter"
              ? I18n.t("TO:")
              : I18n.t("RE:")}{" "}
            {friend.name}
            {!!fromDraft.new_friend && (
              <span className="text-danger mx-2 smallest">{I18n.t("NEW")}</span>
            )}
            {!!friend.identity && (
              <span className="icon icon-shield color-idv ml-1" />
            )}
          </div>
          {!!openletter && (
            <span
              className="badge badge-pill bg-calm badge-tag mr-2 link"
              onClick={props.showOpenLetter}
            >
              <i className="icon-envelope mr-1" />
              {I18n.t("OPEN_LETTER")}
            </span>
          )}
          <span
            className="badge badge-pill badge-tag link"
            onClick={props.showProfile}
          >
            <i className="icon-user mr-1" />
            {I18n.t("PROFILE")}
          </span>
          <span
            className="badge badge-pill badge-tag ml-1 link"
            onMouseEnter={showCount}
            onMouseLeave={hideCount}
          >
            <i className="icon-pencil mr-1" />
            {I18n.t("WORD_COUNT")}
          </span>
          {showingCount && (
            <span className="badge bg-white text-light fw-lighter">
              {I18n.t("Words") +
                ": " +
                wordCount +
                " / " +
                I18n.t("Characters") +
                ": " +
                charCount}
            </span>
          )}
        </div>
      </div>
      <div className="flex-grow-1 text-right">
        <button
          className="btn btn-default pr-2"
          onClick={showStampSelection}
          disabled={loading}
        >
          {!!stamp && <Stamp slug={stamp} size={100} />}
        </button>
      </div>
    </div>
  );
};

class Composer extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this.setup();
    this.unblock = this.props.history.block((location, action) => {
      global.log("history", location);
      const unsavedChange =
        this.state.body !== this.state.lastSaved ||
        this.state.attachments.length > 0;
      if (!unsavedChange) return true;

      const _vars = location.pathname.split("/");
      global.log("_vars", _vars);
      if (
        _vars[1] !== "friend" ||
        (_vars[1] === "friend" && _vars[2] !== this.props.hashid)
      )
        return (
          I18n.t("ASK_SAVE_AUTOMATCH") +
          " - " +
          I18n.t("CONFIRM_CANCEL_SIGNUP_MSG")
        );

      return true;
    });

    window.addEventListener("beforeunload", this.warnUnload);
  }

  checkAndRefreshToken = async() => {
    const { token, id } = this.props.me
    const { uuid, trusted } = this.props

    if(!trusted){ 
      global.log('not a trusted browser, do not try to refresh token')
      return token
    }

    try{
      const decoded = jwtDecode(token)
      global.log('token decoded', decoded)

      const now = moment.orig();
      const expiry = moment.orig(decoded.exp*1000)
      const diffHours = expiry.diff(now, 'hours', true);
      global.log('refresh token diffHours: '+ diffHours)

      if(diffHours<24){
        const payload = { token, uuid, uid: id }
        global.log('refresh token payload', payload)
        //refresh token first
        const refreshed = await refreshWebToken(payload)
        this.props.refreshedToken(refreshed.token)
        return refreshed.token
      }else{
        return token
      }
    }catch(error){
      global.log('refresh token error', error)
      return token
    }
    
  }

  warnUnload = (event) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      return true;
    // const unsavedChange = (this.state.body!==this.state.lastSaved || this.state.attachments.length>0 )
    if (this.state.draftStatus === "changed" || !!this.state.loading)
      event.returnValue =
        I18n.t("ASK_SAVE_AUTOMATCH") +
        " - " +
        I18n.t("CONFIRM_CANCEL_SIGNUP_MSG");
  };

  componentWillUnmount() {
    this.unblock();
    window.removeEventListener("beforeunload", this.warnUnload);
    if (!!this.recorder) this.recorder.stop();
  }

  _hideEditor = () => {
    if (!!this.state.loading) return false;
    // const unsavedChange = (this.state.body!==this.state.lastSaved || this.state.attachments.length>0 )

    if (this.state.draftStatus === "changed") {
      this.setState({
        alert: {
          emoji: "🔔",
          title: I18n.t("ASK_SAVE_AUTOMATCH"),
          message: I18n.t("CONFIRM_CANCEL_SIGNUP_MSG"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: this.props.hideEditor,
        },
      });
    } else {
      this.setState(defaultState);
      this.props.hideEditor();
    }
  };

  closeAlert = () => {
    this.setState({
      alert: null,
    });
  };

  setup = async () => {
    const { me, postID, fromDraft = {}, letter = null } = this.props;

    this.props.beginWriting();

    if (letter) {
      const base = {
        stamp: letter.stamp,
        body: letter.body,
        lastSaved: letter.body,
        fromDraft: letter.body,
        draftStatus: "loaded",
        loading: false,
      };

      if (letter.type === 4 || letter.type === 5) {
        //load extra
        const { token } = this.props.me;
        const results = await getAttachments({
          token,
          id: letter.post,
          letter_id: letter.id,
        });

        const imgs = !!letter.attachments ? letter.attachments.split(",") : [];
        const audio =
          letter.type === 5
            ? _.filter(results, (i) => i.file_type === "audio")
            : [];
        global.log("editing with audio?", audio);

        await this.setState({
          ...base,
          audio: audio.length >= 1 && {
            file_path: audio[0].file_path,
            duration: audio[0].duration,
            uploaded: true,
            file_size: audio[0].file_size,
          },
          onCloud: imgs,
          updated_at: letter.created_at,
        });
      } else {
        await this.setState(base);
      }

      return true;
    }

    if (fromDraft.new_friend) {
      if (fromDraft.channel === "openletter") {
        this._getOpenLetter();
      } else {
        //check availbitiy
        this.getUserStatus(fromDraft.new_friend.id);
      }
    }

    try {
      const { draft } = await getDraft({
        token: me.token,
        post: !!fromDraft.new_friend ? null : postID,
        user_id: !!fromDraft.new_friend ? fromDraft.new_friend.id : null,
      });
      this.setState({
        stamp: !!draft.stamp
          ? draft.stamp
          : !!me.pref.stamp
          ? me.pref.stamp
          : "free",
        body: !!draft.body ? draft.body : "",
        wordCount: !!draft.body ? I18n.wordCount(draft.body) : 0,
        onCloud: !!draft.attachments ? draft.attachments.split(",") : [],
        audio: draft.audio,
        lastSaved: draft.body,
      });
    } catch (error) {
      global.log("draft not found");
      this.setState({
        stamp: !!me.pref.stamp ? me.pref.stamp : "free",
        body: "",
        audio: null,
        lastSaved: "",
      });
    }

    try {
      const quota = await getQuota({ token: this.props.me.token });
      global.log("getQuota", quota);

      this.setState({
        quota,
      });
    } catch (error) {
      global.log("getQuota error", error);
    }

    this.setState({
      loading: false,
      draftStatus: "loaded",
    });
  };

  getUserStatus = async (uid, addFriend = false) => {
    if (!this.props.fromDraft.new_friend) return true;

    try {
      const { token } = this.props.me;
      const user = await userStatus({ token, uid });
      global.log("getUserStatus", user);

      if (user.new_request <= 0) {
        this.setState({
          user_status: false,
          alert: {
            title: I18n.t("SLOWLY_TIPS"),
            message: I18n.t("USER_NOT_ACCEPTING_FRIEND_ATM", {
              name: this.props.fromDraft.new_friend.name,
            }),
            confirmLabel: I18n.t("REG_NOTED"),
            confirmAction: () => {
              this.closeAlert();
            },
          },
        });
        return false;
      } else {
        this.setState({ user_status: true });
        if (addFriend) this.addFriend();
      }
    } catch (err) {
      global.log(err);
    }
  };

  _getOpenLetter = async (callback) => {
    this.setState({ loading: true });

    try {
      const letter = await getSuggestion({
        token: this.props.me.token,
        matching_token: this.props.fromDraft.new_friend.matching_token,
      });

      global.log("getSuggestion result", letter);

      await this.setState({
        openletter: {
          ...letter,
          ...letter.author,
          sent_from: letter.author.location_code,
          language: this.props.langs[letter.language]
            ? this.props.langs[letter.language].native
            : letter.language,
        },
        targetLang: _.keyBy([{ slug: letter.language }], "slug"),
        loading: false,
      });
      if (_.isFunction(callback)) callback();
    } catch (e) {
      global.log("getSuggestion error", e);
      const suffix = e.code ? " (" + e.code + ")" : "";

      if (e.error) {
        if (e.error === "OPENLETTER_EXPIRED") {
          this.setState({
            channel: "temp",
            remove_openletter: true,
            alert: {
              title: I18n.t("OPENLETTER_EXPIRED"),
              message: I18n.t("OPENLETTER_EXPIRED_TIPS"),
              confirmLabel: I18n.t("REG_NOTED"),
              confirmAction: () => {
                this.getUserStatus(this.props.fromDraft.new_friend.id);
                this.closeAlert();
              },
            },
          });
        } else if (e.error === "TARGET_REACHED_DAILY_MATCHING_LIMIT") {
          toast.error(
            I18n.t("TARGET_REACHED_DAILY_MATCHING_LIMIT", { name: e.name }) +
              suffix,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: true,
              closeOnClick: true,
            }
          );
        } else {
          toast.error(I18n.t(e.error) + suffix, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
            closeOnClick: true,
          });
        }
      } else {
        toast.error(I18n.t("UNABLE_TO_REFRESH_DATA") + suffix, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          closeOnClick: true,
        });
      }
      this.setState({ loading: false, user_status: false });
    }
  };

  _initSend = async () => {
    if (!!this.state.loading) return false;

    const bodyLen = I18n.charCount(this.state.body);

    const { fromDraft = {} } = this.props;
    //if new friend, check matching quota
    if (!!fromDraft.new_friend) {
      if (bodyLen < 100) {
        this.setState({
          alert: {
            title: I18n.t("TIPS_SHORT_LETTER_TITLE_WITH_NAME", {
              name: fromDraft.new_friend.name,
            }),
            message: I18n.t("AUTOMATCH_CONTENT_TOO_SHORT"),
            confirmLabel: I18n.t("CONTINUE_WRITING"),
            confirmAction: this.closeAlert,
          },
        });
        return false;
      }

      const quota = await matchingQuota({ token: this.props.me.token });

      if (quota.count > 0) {
        if (quota.count >= quota.limit) {
          this.setState({
            alert: {
              title: I18n.t("SLOWLY_TIPS"),
              message: I18n.t("REACHED_DAILY_MATCHING_LIMIT", {
                datetime: moment.custom(quota.reset, "long"),
              }),
              cancelLabel: I18n.t("CANCEL"),
              cancelAction: this.closeAlert,
              confirmLabel: I18n.t("SAVE_DRAFT"),
              confirmAction: () => {
                this._saveDraft();
                this.closeAlert();
              },
            },
          });

          return false;
        }
      }

      this.addFriend();

      return true;
    }

    if (bodyLen <= 0) return false;
    const totalImgs = this.state.onCloud.length + this.state.attachments.length;

    if (totalImgs > 0) {
      const result = await getQuota({ token: this.props.me.token });

      this.setState({
        quota: {
          photo: result.photo,
          audio: result.audio,
        },
      });

      if (result.photo.remain < totalImgs) {
        this.setState({
          alert: {
            title: I18n.t("REACHED_ATTACHMENTS_QUOTA"),
            message: I18n.t("REACHED_ATTACHMENTS_QUOTA_MSG"),
            confirmLabel: I18n.t("CONFIRM"),
            confirmAction: () => {
              this.closeAlert();
            },
          },
        });
        return false;
      }
    }

    if (bodyLen < 20) {
      this.setState({
        alert: {
          title: I18n.t("SLOWLY_TIPS"),
          message: I18n.t("CONFIRM_SHORT_MSG"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: this._send,
        },
      });
      return false;
    }

    if (this.props.sendLetterReminder !== false) {
      this.setState({
        alert: {
          title: I18n.t("SLOWLY_TIPS"),
          message: I18n.t("CONFIRM_SEND_MSG"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: this._send,
        },
      });
      return false;
    } else {
      this._send();
      return true;
    }
  };

  _editLetter = async () => {
    if (this.state.loading) return false;

    const { letter } = this.props;

    if (moment.orig.utc(letter.deliver_at) <= moment.orig.utc()) {
      toast.info(I18n.t("EDIT_WINDOW_CLOSED"));
      return false;
    }

    this.setState({ loading: true });

    const { token } = this.props.me;

    const photoAttachments = await this.handlePhotos();
    global.log("edit letter > photoAttachments", photoAttachments);

    if (!_.isArray(photoAttachments)) {
      toast.error(I18n.t("UPLOAD_ERROR"));
      this.setState({ loading: false });
      return false;
    }

    const audioID = await this.handleAudio();

    if (!!this.state.audio && !audioID) {
      toast.error(I18n.t("Network request failed"));
      this.setState({ loading: false });
      return false;
    }

    try {
      const { body, stamp } = this.state;

      const payload = {
        token,
        id: letter.id,
        body,
        stamp,
        attachments: photoAttachments.join(","),
        extra: photoAttachments.length >= 1,
        audio: audioID,
      };
      global.log("save letter payload check", payload);

      const response = await saveLetter(payload);
      global.log("saveLetter success", response);
      const _updated = {
        comment: response.letter,
        postID: false,
        uid: this.props.me.id,
        editing: true,
      };

      global.log("saveSuccess payload", _updated);
      this.props.replySuccess(_updated);

      toast.info(I18n.t("LETTER_UPDATED"));
      this.props.hideEditor();
    } catch (error) {
      global.log("saveLetter error", error);

      if (error.error) toast.error(I18n.t(error.error));
      else toast.error(I18n.t("Network request failed"));

      this.setState({ loading: false });
      return false;
    }
  };

  emptyTrash = async () => {
    const { trash = [] } = this.state;
    const { postID } = this.props;
    const { token } = this.props.me;

    if (trash.length > 0) {
      //clear all uploaded images
      await Promise.all(
        _.map(trash, async (filename) => {
          try {
            await removeFile({ token, post: postID, filename });
            global.log("removed - " + filename);
            tracking.event("photo_remove");
          } catch (error) {
            global.log("trash error, move on", error);
          }
        })
      );
      this.setState({ trash: [] });
    }
  };

  emptyAllAttachments = async () => {
    const { onCloud = [], audio = null } = this.state;
    const { postID } = this.props;
    const { token } = this.props.me;

    if (onCloud.length) {
      await Promise.all(
        _.map(onCloud, async (filename) => {
          try {
            await removeFile({ token, post: postID, filename });
            global.log("removed - " + filename);
            tracking.event("photo_remove");
          } catch (error) {
            global.log("trash error, move on", error);
          }
        })
      );
    }

    if (!!audio && audio.uploaded) {
      try {
        await removeFile({
          token,
          post: postID,
          filename: audio.file_path,
          file_type: "audio",
        });
        tracking.event("audio_remove");
      } catch (error) {
        global.log("error", error);
      }
    }

    await this.setState({ onCloud: [], audio: null });
  };

  handlePhotos = async () => {
    const { attachments = [], onCloud = [] } = this.state;
    const { postID } = this.props;
    const { token } = this.props.me;

    await this.emptyTrash();
    global.log("emptyTrash completed");

    if (attachments.length > 0) {
      //replace with Promise.all later

      let uploadError = false;
      global.log("New photos to upload", attachments);
      global.log("onCloud before upload", this.state.onCloud);

      await asyncForEach(attachments, async (file) => {
        if (uploadError) return false;

        try {
          const result = await uploadFile({
            token,
            id: postID,
            file: file.file,
          });
          global.log("uploaded", result);

          if (!!result.s3) {
            await this.setState({
              onCloud: [result.s3, ...this.state.onCloud],
              attachments: _.reject(this.state.attachments, {
                path: file.file,
              }),
            });
            global.log("onCloud now:", this.state.onCloud);

            tracking.event("photo_upload");
          } else {
            uploadError = true;
            return false;
          }
        } catch (e) {
          global.log("photo upload throw error");
          uploadError = true;
          return false;
        }
      });

      if (uploadError) return false;

      global.log("async for each all photos", this.state);

      //find any errors
      return this.state.onCloud;
    } else {
      return onCloud;
    }
  };

  handleAudio = async () => {
    const { audio } = this.state;

    if (!!audio) {
      if (!!audio.uploaded) {
        return audio.uploaded;
      } else {
        const { postID } = this.props;
        const { token } = this.props.me;

        try {
          //upload now
          const audioResult = await uploadFile({
            token,
            id: postID,
            file: audio.path,
            type: "audio",
            duration: audio.duration,
          });

          if (audioResult.id) return audioResult.id;
        } catch (error) {
          global.log("upload audio error", error);
        }

        return false;
      }
    } else {
      return false;
    }
  };

  _send = async () => {
    this.setState({
      loading: true,
      alert: {
        title: (
          <span className="text-positive">
            <i className="icon-send mr-1 text-lighter"></i>{" "}
            {I18n.t("FINISHED_UPLOADING")}
          </span>
        ),
        message: "LOADING",
      },
    });

    const { me, postID, friend } = this.props;
    const { stamp, body, onCloud, attachments } = this.state;

    try {
      //check friendship
      const response = await friendshipStatus({
        token: me.token,
        id: postID,
      });
      this.props.updateFriendshipStatus(response, postID);

      this.setState({
        allowphotos: response.allowphotos,
        allowaudio: response.allowaudio,
      });

      const photosCounter = onCloud.length + attachments.length;
      if (photosCounter > 0 && !response.allowphotos) {
        this.lostPhotoPermission();
        return false;
      }

      if (!!this.state.audio && !response.allowaudio) {
        this.lostAudioPermission();
        return false;
      }
    } catch (error) {
      global.log("friendshipStatus error", error);
      toast.error(I18n.t("INTERNAL_ERROR") + " (Error: 400)");

      this.setState({
        loading: false,
        saving: false,
      });

      return false;
    }

    const host = friend.user_id === friend.joined ? true : false;
    const photoAttachments = await this.handlePhotos();
    if (!_.isArray(photoAttachments)) {
      toast.error(I18n.t("UPLOAD_ERROR"));
      this.setState({ loading: false });
      return false;
    }

    const audioID = await this.handleAudio();
    if (!!this.state.audio && !audioID) {
      toast.error(I18n.t("Network request failed"));
      this.setState({ loading: false });
      return false;
    }

    const token = await this.checkAndRefreshToken()
    global.log('checkAndRefreshToken returned token', token)

    try {
      const response = await reply({
        token,
        id: postID,
        body,
        stamp,
        attachments: photoAttachments.join(","),
        host,
        extra: photoAttachments.length >= 1,
        audio: audioID,
      });

      global.log("reply success", response);

      const commentFixed = await handleMyComment({
        comment: response.comment,
        me: this.props.me,
      });

      this.props.replySuccess({
        comment: commentFixed,
        postID,
        uid: this.props.me.id,
      });

      if (this.props.onSent) this.props.onSent(postID);

      this.setState({
        body: "",
        wordCount: "...",
        loading: false,
        saving: false,
        lastSaved: "",
        draftStatus: null,
        attachments: [],
        onCloud: [],
        trash: [],
        alert: null,
      });

      toast.info(I18n.t("LETTER_SENT"));

      this.props.hideEditor();
    } catch (error) {
      if (error.error === "REACHED_ATTACHMENTS_QUOTA_MSG") {
        this.setState({
          loading: false,
          alert: {
            title: I18n.t("REACHED_ATTACHMENTS_QUOTA"),
            message: I18n.t("REACHED_ATTACHMENTS_QUOTA_MSG"),
            confirmAction: this.closeAlert,
            confirmLabel: I18n.t("BTN_CLOSE"),
          },
        });
      } else if (error.error === "SERVER_TIMEOUT" || !isNaN(error.error)) {
        this.setState({ retry: this.state.retry + 1 });

        if (this.state.retry <= 3) {
          global.log("reply failed, retry now - " + this.state.retry);
          setTimeout(this._send, 1000);
          return true;
        } else {
          this.setState({
            loading: false,
            alert: {
              title: I18n.t("SERVER_OFFLINE"),
              message: I18n.t("STORE_IS_TEMP_CLOSED_TRY_LATER"),
              confirmAction: this.closeAlert,
              confirmLabel: I18n.t("BTN_CLOSE"),
            },
          });
        }
      } else {
        this.setState({
          loading: false,
          alert: {
            title: I18n.t("ERROR"),
            message: !!error.error
              ? I18n.t(error.error)
              : I18n.t("INTERNAL_ERROR"),
            confirmAction: this.closeAlert,
            confirmLabel: I18n.t("BTN_CLOSE"),
          },
        });
      }
    }
  };

  addFriend = async () => {
    this.setState({
      loading: true,
      alert: {
        title: (
          <span className="text-positive">
            <i className="icon-send mr-1 text-lighter"></i>{" "}
            {I18n.t("FINISHED_UPLOADING")}
          </span>
        ),
        message: "LOADING",
      },
    });

    const token = await this.checkAndRefreshToken()
    global.log('checkAndRefreshToken returned token', token)

    try {
      const { fromDraft } = this.props;
      const { stamp, body } = this.state;

      console.log('fromDraft', fromDraft)
      const _API = fromDraft.channel === "openletter" ? replyOpenLetter : makeFriend;

      const payload = {
        token,
        userID: fromDraft.user_id,
        fields: {
          matching_token: fromDraft.new_friend.matching_token,
          body,
          stamp,
          style: "{}",
          automatch: "false",
        },
      }

      console.log('payload', payload)

      const response = await _API(payload);
      global.log("makeFriend", response);

      if (this.props.onSent) this.props.onSent(fromDraft.user_id, "matches");
      this.props.refreshFriends();

      this.setState(defaultState);
      this.props.hideEditor();

      toast.info("🎉 " + I18n.t("NEW_FRIEND_TOAST"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true,
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          title: I18n.t("ERROR"),
          message: !!error.error
            ? I18n.t(error.error)
            : I18n.t("INTERNAL_ERROR"),
          confirmAction: this.closeAlert,
          confirmLabel: I18n.t("BTN_CLOSE"),
        },
      });
    }
  };

  lostPhotoPermission = () => {
    this.setState({ saving: false, loading: false, alert: null });
    toast.error(I18n.t("PHOTO_SHARE_REVOKED"));
  };

  lostAudioPermission = () => {
    this.setState({ saving: false, loading: false, alert: null });
    toast.error(I18n.t("AUDIO_SHARE_REVOKED"));
  };

  _saveDraft = async () => {
    if (!!this.state.loading) return false;

    const { postID, uuid, fromDraft = {} } = this.props;
    const { stamp, trash, body, attachments, onCloud, audio } = this.state;

    this.setState({
      loading: true,
      saving: true,
    });

    const token = await this.checkAndRefreshToken()
    global.log('checkAndRefreshToken returned token', token)

    if (!this.props.new_friend && !!postID) {
      try {
        //check friendship
        const response = await friendshipStatus({
          token,
          id: postID,
        });
        this.props.updateFriendshipStatus(response, postID);

        this.setState({
          allowphotos: response.allowphotos,
          allowaudio: response.allowaudio,
        });

        const photosCounter = onCloud.length + attachments.length;
        if (photosCounter > 0 && !response.allowphotos) {
          this.lostPhotoPermission();
          return false;
        }

        if (!!audio && !response.allowaudio) {
          this.lostAudioPermission();
          return false;
        }
      } catch (error) {
        global.log("friendshipStatus error", error);
        toast.error(I18n.t("INTERNAL_ERROR") + " (Error: 400)");

        this.setState({
          loading: false,
          saving: false,
        });

        return false;
      }
    }

    const photoAttachments = await this.handlePhotos();
    global.log("edit letter > photoAttachments", photoAttachments);

    if (!_.isArray(photoAttachments)) {
      toast.error(I18n.t("UPLOAD_ERROR"));
      this.setState({ loading: false });
      return false;
    }

    const audioID = await this.handleAudio();

    if (!!this.state.audio && !audioID) {
      toast.error(I18n.t("Network request failed"));
      this.setState({ loading: false });
      return false;
    }

    try {
      const payload = {
        token,
        post: !!fromDraft.new_friend ? null : postID,
        channel:
          fromDraft.channel === "openletter"
            ? "openletter"
            : !!this.props.new_friend
            ? "temp"
            : "web",
        user_id: !!fromDraft.new_friend ? fromDraft.new_friend.id : null,
        stamp,
        body,
        device_id: uuid,
        attachments: photoAttachments.join(","),
        audio: audioID,
        matching_token:
          !!fromDraft.new_friend && fromDraft.new_friend.matching_token,
      };
      global.log("saveDraft payload", { payload, fromDraft });

      const result = await saveDraft(payload);

      tracking.event("draft_saved");
      global.log(result);

      if (_.isArray(trash) && trash.length > 0) {
        await asyncForEach(trash, async (filename) => {
          await removeFile({ token, post: postID, filename });

          tracking.event("photo_remove");
        });
      }

      this.setState({
        loading: false,
        saving: false,
        attachments: [],
        onCloud: !!result.onCloud ? result.onCloud : this.state.onCloud,
        trash: [],
        lastSaved: body,
        draftStatus: "saved",
      });

      if (this.props.onSave) {
        this.props.onSave(
          {
            ...payload,
            new_friend: fromDraft.new_friend,
            body: payload.body.substring(0, 100),
            updated_at: result.updated_at,
          },
          !!fromDraft.new_friend ? "matches" : "friends"
        );
      }
    } catch (e) {
      global.log(e);
      this.setState({
        loading: false,
        saving: false,
        draftStatus: "failed",
      });
    }
  };

  onChange = (event) => {
    const { value } = event.target;

    this.setState({
      body: value,
      draftStatus:
        value !== this.state.lastSaved ? "changed" : this.state.draftStatus,
    });
  };

  onFileSelected = (e) => {
    let newFiles = [];

    _.forEach(e.target.files, (file) => {
      newFiles.push({
        file,
        preview: URL.createObjectURL(file),
      });
    });

    const onCloudLength = _.isArray(this.state.onCloud)
      ? this.state.onCloud.length
      : 0;

    const attachments = [...this.state.attachments, ...newFiles];
    const totalImgs = onCloudLength + attachments.length;

    if (totalImgs > 5)
      toast.info(I18n.t("REACHED_ATTACHMENTS_LIMIT"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true,
      });

    this.setState({
      attachments: _.take(attachments, 5 - onCloudLength),
      draftStatus: "changed",
    });
  };

  _removeCloudFile = (filename) => {
    if (!!this.state.loading) return false;

    this.setState({
      onCloud: _.reject(this.state.onCloud, (o) => {
        return o === filename;
      }),
      trash: _.concat(this.state.trash, filename),
      draftStatus: "changed",
    });
  };

  _removeFile = (filename) => {
    if (!!this.state.loading) return false;

    this.setState({
      attachments: _.reject(this.state.attachments, (o) => {
        return o.file.name === filename;
      }),
      draftStatus: "changed",
    });
  };

  showFileInput = () => {
    if (!!this.state.loading) return false;

    const onCloudLength = _.isArray(this.state.onCloud)
      ? this.state.onCloud.length
      : 0;
    const attachmentsLength = _.isArray(this.state.attachments)
      ? this.state.attachments.length
      : 0;

    const totalImgs = onCloudLength + attachmentsLength;

    if (totalImgs >= 5) {
      toast.info(I18n.t("REACHED_ATTACHMENTS_LIMIT_DESC"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true,
      });
      return false;
    }

    this.fileInput.click();
  };

  showStampSelection = () => {
    this.setState({
      showStamp: true,
    });
    this.hideBodyScroll();
  };

  onStampSelected = (stamp) => {
    this.setState({
      showStamp: false,
      stamp,
    });
    this.showBodyScroll();
  };

  hideStampSelection = () => {
    this.setState({
      showStamp: false,
    });
    this.showBodyScroll();
  };

  onAudioChange = (audio) => {
    global.log("onAudioChange", audio);

    this.setState({
      audio,
      draftStatus: "changed",
    });
  };

  startRecording = () => {
    this.setState({ loading: true });
  };

  stopRecording = () => {
    this.setState({ loading: false });
  };

  onDragFinished = (padding) => {
    this.props.onDragFinished(padding);
    this.setState({ stampBottom: padding - 100 });
  };

  friendModalClosed = () => {
    this.setState({
      showProfile: false,
    });
  };

  openLetterClosed = () => {
    this.setState({
      showOpenLetter: false,
    });
  };

  showOpenLetter = () => {
    this.setState({
      showOpenLetter: true,
    });
  };

  showProfile = async () => {
    if (this.state.loading) return false;

    const { fromDraft = {} } = this.props;
    if (!fromDraft.new_friend || !!this.state.user) {
      this.setState({ showProfile: true, loading: false });
      return true;
    }

    this.setState({ loading: true });

    //load profile
    const user = await userProfile({
      token: this.props.me.token,
      uid: fromDraft.user_id,
    });

    this.setState({
      showProfile: true,
      user,
    });

    const extend = await userProfileExtend({
      token: this.props.me.token,
      uid: fromDraft.user_id,
    });
    global.log("Got user", user);

    const myTags = this.props.me.tags;

    const sub_others = !!extend.subtopics
      ? _.filter(
          extend.subtopics.data,
          (s) => s.parent === "OTHER_TOPICS" || !s.parent
        )
      : [];

    global.log("sub_others", sub_others);

    const otherTopics = _.map(
      _.filter(extend.topics, (t) => {
        return myTags.indexOf(t) < 0;
      }),
      (tag) => {
        return {
          tag,
          subtopics: !!extend.subtopics
            ? _.filter(extend.subtopics.data, (s) => s.parent === tag)
            : [],
        };
      }
    );

    this.setState({
      user: {
        ...user,
        extend,
        commonTopics: _.map(
          _.filter(extend.topics, (t) => {
            return myTags.indexOf(t) >= 0;
          }),
          (tag) => {
            return {
              tag,
              subtopics: !!extend.subtopics
                ? _.filter(extend.subtopics.data, (s) => s.parent === tag)
                : [],
            };
          }
        ),
        otherTopics:
          sub_others.length > 0
            ? [
                ...otherTopics,
                {
                  tag: "OTHER_TOPICS",
                  subtopics: sub_others,
                },
              ]
            : otherTopics,
      },
      loading: false,
    });
  };

  deleteDraft = () => {
    this.setState({
      alert: {
        title: I18n.t("CONFIRM_REMOVE"),
        message: I18n.t("CONFIRM_DELETE_ACC_MSG"),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: this.closeAlert,
        confirmLabel: I18n.t("DELETE"),
        confirmAction: this.confirmDelete,
        danger: true,
      },
    });
  };

  confirmDelete = async () => {
    const { fromDraft = {} } = this.props;
    
    const token = await this.checkAndRefreshToken()
    global.log('checkAndRefreshToken returned token', token)

    await this.emptyTrash();
    global.log("emptyTrash completed");
    await this.emptyAllAttachments();

    if (!!fromDraft.new_friend) {
      await deleteDraft({ token, user_id: fromDraft.user_id });
      this.props.onSent(fromDraft.user_id, "matches");
    } else {
      await saveDraft({
        token,
        stamp: this.state.stamp,
        body: "",
        post: this.props.postID,
        channel: this.state.platform,
        device_id: this.state.uuid,
        attachments: "",
        audio: null,
      });
      if (this.props.onSent) this.props.onSent(this.props.postID);
    }

    this.setState({
      alert: null,
    });
    this.props.hideEditor();

    toast.info("✔ " + I18n.t("AUTOMATCH_DRAFT_DISCARDED"), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: true,
      closeOnClick: true,
    });
  };

  showCount = () => {
    this.setState({
      charCount: I18n.charCount(this.state.body),
      wordCount: I18n.wordCount(this.state.body),
      showingCount: true,
    });
  };

  hideCount = () => {
    this.setState({
      showingCount: false,
    });
  };

  hideBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  showBodyScroll = () => {
    document.body.style.overflow = "unset";
  };

  insert = (text) => {
    this.setState(
      {
        body: this.state.body.length > 0 ? this.state.body + "\n" + text : text,
        draftStatus: "changed",
      },
      () => {
        if (this.editorScroller) this.editorScroller.scrollToBottom();
      }
    );
    this.hideParagraphs();
  };

  showParagraphs = () => {
    this.setState({
      showParagraphs: true,
    });
    this.hideBodyScroll();
  };

  hideParagraphs = () => {
    this.setState({
      showParagraphs: false,
    });
    this.showBodyScroll();
  };

  render() {
    const {
      me,
      standalone = false,
      fromDraft = {},
      letter = null,
    } = this.props;
    const friend = fromDraft.new_friend
      ? fromDraft.new_friend
      : this.props.friend;

    const {
      loading,
      saving,
      draftStatus,
      body,
      attachments = [],
      onCloud = [],
      showProfile = false,
      showOpenLetter = false,
    } = this.state;

    const { photo = { remain: 10 } } = this.state.quota;
    const totalImgs = onCloud.length + attachments.length;

    return (
      <div className="container h-100">
        <div className="d-flex h-100">
          <div className="empty-sidebar" />
          <div className="col pr-0 flex-grow-1">
            <ComposerWrapper
              standalone={standalone}
              onDragFinished={this.onDragFinished}
            >
              <div />
              <div className="align-items-stretch h-100 w-100">
                {!standalone && (
                  <div className="handler">
                    <div className="grip">
                      <div className="icon-holder">
                        <i className="icon-more" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-100 h-100 editor " dir="ltr">
                  <div className="modal-content w-100 h-100 m-0 p-0">
                    {totalImgs > 0 && photo.remain < 5 && (
                      <div className="photo-quota-badge">
                        <div
                          className={
                            photo.remain < totalImgs || photo.remain === 0
                              ? "badge badge-danger p-2"
                              : "text-white badge badge-dark p-2"
                          }
                        >
                          <i className="icon-info pr-1" />
                          {I18n.t("PHOTO_QUOTA")}
                          {": "}
                          {photo.remain}
                        </div>
                      </div>
                    )}
                    <div
                      className="modal-header p-1"
                      ref={(ref) => (this.modalBody = ref)}
                    >
                      <div className="row w-100 m-0">
                        <div className="col-1 d-flex align-items-center mx-1 p-0">
                          <button
                            type="button"
                            className="btn btn-default btn-toolbar"
                            onClick={this._hideEditor}
                            disabled={!!loading}
                          >
                            <i className="icon-close h4" />
                          </button>
                        </div>
                        <div className="col d-flex flex-row-reverse align-items-center text-right pr-1">
                          {!!letter ? (
                            <button
                              type="button"
                              className="btn btn-primary ml-2 editor-btn"
                              disabled={!!loading}
                              onClick={this._editLetter}
                            >
                              {I18n.t("SAVE")}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary ml-2 editor-btn"
                              disabled={
                                !!loading || this.state.user_status === false
                              }
                              onClick={this._initSend}
                            >
                              <i className="icon-send mr-2" />
                              {I18n.t("SEND")}
                            </button>
                          )}

                          {!letter && (
                            <>
                              <button
                                type="button"
                                className="btn ml-2 editor-btn btn-outline-dark"
                                onClick={this._saveDraft}
                                disabled={!!loading}
                              >
                                {draftStatus === "saved" ? (
                                  I18n.t("AUTOMATCH_DRAFT_SAVED")
                                ) : !!saving ? (
                                  <span
                                    className="spinner-grow spinner-grow-sm mr-2 text-warning"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  I18n.t("SAVE_DRAFT")
                                )}
                              </button>
                              <button
                                type="button"
                                className="btn editor-btn btn-default text-light btn-sm"
                                onClick={this.showParagraphs}
                              >
                                {I18n.t("MY_PARAGRAPHS")}
                              </button>
                            </>
                          )}

                          {!!friend.allowaudio && draftStatus !== null && (
                            <MiniRecorder
                              key={friend.id}
                              me={me}
                              audio={
                                !!this.state.audio
                                  ? attachmentPath +
                                    this.state.audio.file_path +
                                    "?_p=" +
                                    friend.pass +
                                    "&_u=" +
                                    me.hashid
                                  : null
                              }
                              duration={
                                !!this.state.audio
                                  ? this.state.audio.duration
                                  : null
                              }
                              onAudioChange={this.onAudioChange}
                              startRecording={this.startRecording}
                              stopRecording={this.stopRecording}
                            />
                          )}
                          {!!friend.allowphotos && (
                            <button
                              type="button"
                              className="btn btn-default btn-toolbar mr-2"
                              ref={(ref) => (this.imgBtn = ref)}
                              onClick={this.showFileInput}
                            >
                              <i className="icon-attachment-2" />
                              {totalImgs > 0 && (
                                <span className="badge badge-danger badge-corner">
                                  {totalImgs}
                                </span>
                              )}
                            </button>
                          )}

                          {!standalone && !letter && (
                            <button
                              type="button"
                              className="btn btn-default btn-toolbar mr-2"
                              onClick={this.props.toggleFocusMode}
                              disabled={!!loading}
                            >
                              <i
                                className={
                                  !!this.props.isFocus
                                    ? "icon-minimize h4"
                                    : "icon-expand"
                                }
                              />
                            </button>
                          )}
                          {!!standalone && !!fromDraft.new_friend && (
                            <button
                              type="button"
                              className="btn btn-default btn-toolbar mr-2"
                              onClick={this.deleteDraft}
                              disabled={!!loading}
                            >
                              <i className="icon-trash-o" />
                            </button>
                          )}
                        </div>
                      </div>
                      <input
                        className="invisible position-absolute"
                        ref={(ref) => (this.fileInput = ref)}
                        type="file"
                        accept="image/jpeg,image/png,image/jpg"
                        multiple
                        onChange={this.onFileSelected}
                      />
                    </div>
                    {this.props.isFocus && (
                      <ComposerHeader
                        friend={friend}
                        fromDraft={fromDraft}
                        showStampSelection={this.showStampSelection}
                        loading={loading}
                        stamp={this.state.stamp}
                        darkMode={this.props.darkMode}
                        showProfile={this.showProfile}
                        showOpenLetter={this.showOpenLetter}
                        openletter={!!this.state.openletter}
                        wordCount={this.state.wordCount}
                        charCount={this.state.charCount}
                        showCount={this.showCount}
                        hideCount={this.hideCount}
                        showingCount={this.state.showingCount}
                        fullscreen
                      />
                    )}
                    <Scrollbars
                      className="modal-body position-relative"
                      ref={(ref) => (this.editorScroller = ref)}
                    >
                      {!this.props.isFocus && (
                        <ComposerHeader
                          friend={friend}
                          showStampSelection={this.showStampSelection}
                          loading={loading}
                          stamp={this.state.stamp}
                          showProfile={this.showProfile}
                          wordCount={this.state.wordCount}
                          charCount={this.state.charCount}
                          showCount={this.showCount}
                          hideCount={this.hideCount}
                          showingCount={this.state.showingCount}
                        />
                      )}
                      <div className="px-3">
                        <TextareaAutosize
                          key="editor"
                          className="form-control textarea"
                          dir="auto"
                          value={body}
                          onChange={this.onChange}
                          placeholder={I18n.t("BODY_PLACEHOLDER")}
                          disabled={!!loading}
                        />
                      </div>

                      {totalImgs > 0 && (
                        <div
                          className="mt-3 px-2 d-flex mb-1"
                          style={{ width: "80%" }}
                        >
                          {_.map(onCloud, (img) => {
                            return (
                              <div
                                className="rounded img-preview m-1 with-border"
                                key={img}
                              >
                                <img
                                  src={
                                    attachmentPath +
                                    img +
                                    "?_p=" +
                                    friend.pass +
                                    "&_u=" +
                                    me.hashid
                                  }
                                  alt="..."
                                />
                                <div
                                  className="close link"
                                  onClick={() => {
                                    this._removeCloudFile(img);
                                  }}
                                >
                                  <i className="icon-close text-white" />
                                </div>
                              </div>
                            );
                          })}

                          {_.map(attachments, (img) => {
                            return (
                              <div
                                className="rounded img-preview m-1 with-border"
                                key={img.file.name}
                              >
                                <img src={img.preview} alt={img.file.name} />
                                <div
                                  className="close link"
                                  onClick={() => {
                                    this._removeFile(img.file.name);
                                  }}
                                >
                                  <i className="icon-close text-white" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className="p-2 w-100" />
                    </Scrollbars>
                    {!!this.state.loading && draftStatus === null && (
                      <div className="editor-block w-100 h-100 d-flex justify-content-center align-items-center">
                        <span
                          className="spinner-grow spinner-grow-sm text-warning"
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    {letter && (
                      <div className="p-3 text-light">
                        <hr />
                        <span class="text-calm mr-2 strong">
                          <i className="icon-send mr-1" />{" "}
                          {I18n.t("IN_TRANSIT")}
                        </span>
                        {I18n.t("IN_TRANSIT_EDIT_REMINDER", {
                          date: moment.custom(
                            letter.deliver_at,
                            "calendar-full"
                          ),
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ComposerWrapper>
          </div>
        </div>

        <StampSelection
          showing={this.state.showStamp}
          dismiss={this.hideStampSelection}
          onStampSelected={this.onStampSelected}
          friend={friend}
          key={friend.id}
        />

        <MyParagraphs
          showing={this.state.showParagraphs}
          dismiss={this.hideParagraphs}
          insert={this.insert}
        />

        <AppAlert alert={this.state.alert} />

        <OpenLetter
          show={showOpenLetter}
          item={this.state.openletter}
          handleClose={this.openLetterClosed}
        />

        <FriendProfile
          show={showProfile}
          postID={friend.id}
          user={this.state.user}
          handleClose={this.friendModalClosed}
          readOnly
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { postID } = props;

  return {
    me: reProfileMe(state),
    paragraphs: reParagraphs(state),
    friend: state.contacts[postID],
    sendLetterReminder: state.slowly.sendLetterReminder,
    sending: state.letters.sending,
    error: state.letters.error,
    uuid: state.slowly.uuid,
    darkMode: state.slowly.darkMode,
    langs: reLangs(state),
    trusted: getTrust(state),
  };
};

const beginWriting = function beginWriting() {
  return {
    type: "BEGIN_WRITING",
  };
};

const replySuccess = function replySuccess({ comment, postID=null, uid, editing=false }) {
  return {
    type: 'LETTER_REPLY_SUCCESS',
    comment,
    postID,
    uid,
    editing
  };
};

const refreshFriends = function refreshFriends() {
  return {
    type: "GET_FRIENDS",
  };
};

const updateFriendshipStatus = function updateFriendshipStatus(
  response,
  postID
) {
  return {
    type: "UPDATE_FRIENDSHIP_STATUS",
    response,
    postID,
  };
};

const returnError = function returnError(error) {
  return {
    type: "ME_ERROR",
    error: {
      error,
    },
  };
};

const refreshedToken = function refreshedToken(token) {
  return {
    type: "REFRESH_TOKEN",
    token
  };
};

export default connect(mapStateToProps, {
  beginWriting,
  replySuccess,
  returnError,
  updateFriendshipStatus,
  refreshFriends,
  refreshedToken
})(Composer);
