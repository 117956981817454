import React from "react";
import I18n from "../I18n";
import Avatar from "./Avatar";
import Select from "react-select";
import { connect } from "react-redux";
import { reContacts } from "../selectors";
import _ from "lodash";

class SendGiftDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      friend: null,
      contacts: [],
    };
  }

  componentDidMount() {
    const { data = [] } = this.props.contacts;

    this.setState({
      contacts: _.map(
        _.reject(data, (f) => f.user_status < 1),
        (f) => {
          return {
            ...f,
            label: f.name,
            value: f.id,
          };
        }
      ),
    });
  }

  _onChange = (friend) => {
    global.log(friend);
    this.setState({
      friend,
    });
    if (this.props._onChange) this.props._onChange(friend);
  };

  _onChangeGiftMsg = (ev) => {
    if (this.props._onChangeGiftMsg)
      this.props._onChangeGiftMsg(ev.target.value);
  };

  render() {
    const { darkMode } = this.props;
    const { friend, contacts } = this.state;
    const bgColor = !!darkMode ? " #2a2f39" : "#fff";

    return (
      <div className="px-3 pb-3">
        <div className="position-relative">
          <Select
            options={contacts}
            placeholder={I18n.t("SEND_TO")}
            noOptionsMessage={() => I18n.t("NOT_FOUND")}
            onChange={this._onChange}
            styles={{
              input: (styles) => ({
                ...styles,
                marginLeft: 34,
              }),
              placeholder: (styles) => ({
                ...styles,
                marginLeft: 34,
              }),
              singleValue: (styles, { data }) => ({
                ...styles,
                marginLeft: 34,
                color: null,
              }),
              control: (styles) => ({
                ...styles,
                backgroundColor: null,
                color: null,
                borderColor: null,
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: bgColor,
              }),
            }}
            theme={
              !!this.props.darkMode
                ? (theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral0: "#000",
                      primary25: "#222",
                      primary: "#333",
                      neutral80: "white",
                      neutral50: "white",
                      primary50: "#222",
                      neutral90: "white",
                    },
                  })
                : null
            }
          />
          <div className="react-select-avatar">
            {!!friend ? (
              <Avatar
                key={friend.id}
                uid={friend.id}
                id={friend.avatar}
                gender={friend.gender}
                name={friend.name}
                size={30}
              />
            ) : (
              <div className="empty-avatar" />
            )}
          </div>
        </div>
        <div className="my-2">
          <textarea
            row={5}
            style={{ resize: "none", height: 100 }}
            className="form-control"
            onChange={this._onChangeGiftMsg}
            placeholder={I18n.t("GIFT_MESSAGE")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    contacts: reContacts(state),
  };
};

export default connect(mapStateToProps, {})(SendGiftDetail);
