import Hashids from 'hashids'
const hashids = new Hashids('slowlyweb',8,'abcdefghijklmnopqrstuvwxyz0123456789');

const encode = id => {
  if(!id) return false
  return hashids.encode(id)
}
const decode = hashed => {
  if(!hashed) return false
  return hashids.decode(hashed)
}

const _hashFunc = {
  encode,
  decode
}

export default _hashFunc
