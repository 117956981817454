import {
  INIT_APP,
  GET_LETTERS,
  GET_LETTERS_SUCCESS,
  READ_LETTER_SUCCESS,
  READ_LETTERS_CLOSE,
  BEGIN_WRITING,
  LETTER_REPLY_SUCCESS,
  SET_SEARCH_TEXT,
  CHECK_INCOMING_SUCCESS,
  GET_UNREAD_SUCCESS,
  ADD_FRIEND,
  GET_LATEST,
  GET_LATEST_SUCCESS,
  BLOCK_USER_SUCCESS,
  SHOW_ERROR,
  DELETE_LETTER_SUCCESS,
  LOGOUT_COMPLETE
} from '../actions/type';

// import I18n from '../I18n';
import moment from 'moment';
import _ from 'lodash'

const defaultState = {
  loading: false,
  body: '',
  unreadCounter: 0,
  uploading: false,
  uploadSuccess: [],
  uploadFailed: [],
  lastCheck: null,
  incomingCounter: 0,
  incoming: [],
  data: {},
  latest: {
    loading: false,
    current_page: 0,
    next_page_url: null,
    ids: [],
    lastCheck: null
  },
  success: false,
  error: null
};

const _letters = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_APP:
      return{
        ...state,
        loading: false,
        lastCheck: null
      }
    case GET_LETTERS:
      return {
        ...state,
        loading: true
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searching: action.postID,
        searchText: action.searchText,
        filter: action.filter
      }

    case GET_LETTERS_SUCCESS:
      // __DEV__ && global.log(action);
      // const res = action.response;
      const { response, postID } = action;
      let newIds = !!state[postID] ? response.comments.current_page!==1 ? state[postID].ids : [] : [];
      let newData = {}

      if(!!response.incoming)
        response.incoming.map((item, index) => {
          newIds.push(item.id);
          newData[item.id] = item;
          return true
        });

      response.comments.data.map((item, index) => {
        newIds.push(item.id);
        newData[item.id] = item;
        return true
      });

      const lastIndex = !!state[postID] ? state[postID].lastIndex : 0

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...newData,
        },
        [postID]: {
          current_page: response.comments.current_page,
          next_page_url: response.comments.next_page_url,
          ids: [...new Set(newIds)],
          now: action.response.now,
          lastIndex
        }
      };
    case BLOCK_USER_SUCCESS:
      state[action.user.id].ids.map((item, index) => {
        global.log('Delete comment -' + item)
        delete state.data[item];
        return true;
      });

      delete state[action.user.id]

      return{
        ...state,
        data: {
          ...state.data
        }
      }

    case GET_LATEST:
      return{
        ...state,
        latest:{
          ...state.latest,
          loading:true
        }
      }


    case GET_LATEST_SUCCESS:
      if(action.response.comments.current_page===1 && action.after==null ) state.latest.ids = []
      let newLetters = [];

      action.response.comments.data.map((item, index) => {
        if(state.latest.ids.indexOf(item.id)<0){
          action.after==null ? state.latest.ids.push(item.id) : newLetters.push(item.id);
          state.data[item.id] = item;
        }
        return true;
      });

      return {
        ...state,
        data: state.data,
        latest: {
          current_page: action.after==null ? action.response.comments.current_page : state.latest.current_page,
          next_page_url: action.after==null ? action.response.comments.next_page_url : state.latest.next_page_url,
          ids: action.after==null ? [...state.latest.ids] : [ ...newLetters, ...state.latest.ids ],
          now: action.after==null ? action.response.now : state.latest.now,
          loading:false,
        }
      };

    case GET_UNREAD_SUCCESS:
      return {
        ...state,
        unreadCounter: action.unreadCounter
      };

    case DELETE_LETTER_SUCCESS:
      return {
        ...state,
        data: _.omit(state.data, [action.id])
      }

    case READ_LETTER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.commentID]:{
            ...state.data[action.commentID],
            read_at: moment.utc().format('YYYY-MM-DD')
          }
        },
        unreadCounter: state.unreadCounter - 1 < 0 ? 0 : state.unreadCounter - 1
      };

    case READ_LETTERS_CLOSE:
      global.log('save last reading index');
      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          lastIndex: action.lastIndex
        }
      };

    case BEGIN_WRITING:
      return {
        ...state,
        body: '',
        loading: false,
        sending: false,
        error: null
      };
      
    case LETTER_REPLY_SUCCESS:
      if(!!action.editing){
        return{
          ...state,
          loading: false,
          data: {
            ...state.data,
            [action.comment.id] : {
              ...state.data[action.comment.id],
              ...action.comment
            }
          }
        }

      }else if(!state[action.postID])
        return{
          ...state,
          sending: false,
          error: null,
          data: {
            ...state.data,
            [action.comment.id] : action.comment
          }
        }
      else
        return {
          ...state,
          sending: false,
          error: null,
          [action.postID]: {
            ...state[action.postID],
            ids: [
              action.comment.id,
              ...state[action.postID].ids
            ]
          },
          data: {
            ...state.data,
            [action.comment.id] : action.comment
          }
        };

    // case LETTER_REPLY_ERROR:
    //   if (action.error.error)
    //     global.log('ERROR: ' + I18n.t(action.error.error));
    //
    //   return {
    //     ...state,
    //     sending: false,
    //     error: action.error.error
    //   };

    case ADD_FRIEND:
      return {
        ...state,
        uploading: false,
        loading: true,
        error: null,
      };

    case CHECK_INCOMING_SUCCESS:
      if(!!action.response){
        return {
          ...state,
          lastCheck: action.now,
          incomingCounter: action.response.total,
          incoming: _.sortBy(action.response.data, 'deliver_at', 'asc'),
          loading: false
        }
      }else{
        return {
          ...state,
          lastCheck: action.now,
          loading: false
        }
      }

    case SHOW_ERROR:
      return {
        ...state,
        sending: false,
        error: action.error.error
      }
    case LOGOUT_COMPLETE:
      return {
        ...defaultState,
        incoming: [],
        data: {},
        latest: {
          current_page: 0,
          next_page_url: null,
          ids: [],
          lastCheck: null
        }
      };

    default:
      return state;
  }
}


export default _letters