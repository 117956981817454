import React from 'react';
import { STATIC_CDN_URL } from "../config/ApiConfig";
import { connect } from "react-redux";

class Stamp extends React.Component {
  constructor(){
    super();
    this.state = { loaded: false };
  }

  loaded = () => {
    this.setState({ loaded: true })
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.state.loaded !== nextState.loaded) return true;
    if(nextProps!== this.props) return true;
    return false;
  }

  render() {
    const { slug, size, name, darkMode=false } = this.props;
    const small = size<100 ? '-sm' : ''
    const url = (slug==="nostamps" && !!darkMode) ? STATIC_CDN_URL + "stamp/nostamps-inverted.png" : STATIC_CDN_URL + 'stamp' +small + '/' + slug + '.png'

    return (
      <img
        src={url} width={size} height={size}
        key={'stamp-'+slug}
        alt={name} className={`stamp ${ !!this.state.loaded ? 'loaded' : '' }`}
        onLoad={this.loaded}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    darkMode: state.slowly.darkMode
  };
};

export default connect(
  mapStateToProps,
  {}
)(Stamp);
