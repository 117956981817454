import { API_URL, headers, handleApiErrors } from '../config/ApiConfig';

//7.0.0+
export const sync = ({ token, last_sync=false }) => {
  const url = last_sync ? API_URL + '/paragraph/sync?token='+token+'&last_sync=' +last_sync :
  API_URL + '/paragraph/sync?token='+token
  return fetch(url, {
    method: 'GET',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const syncLabels = ({ token }) => {
  return fetch(API_URL + '/paragraph/labels?token='+token, {
    method: 'GET',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const del = ({ token, key }) => {
  return fetch(API_URL + '/paragraph/item/'+ key +'?token='+token, {
    method: 'DELETE',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const saveLabels = ({ token, payload }) => {
  return fetch(API_URL + '/paragraph/labels/save?token='+token, {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const save = ({ token, payload }) => {
  return fetch(API_URL + '/paragraph/save?token='+token, {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};
