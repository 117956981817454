import React, { Component } from "react";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import Loader from "../components/Loader";
import { Redirect } from "react-router-dom";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.logout();
  }

  render() {
    if (!this.props.me.token) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    return <Loader />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    loading: state.slowly.loading,
  };
};

const logout = () => {
  return {
    type: "LOGOUT",
  };
};

export default connect(mapStateToProps, {
  logout,
})(Logout);
