import { applyMiddleware, createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootSaga from '../sagas';
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
// import uuidv4 from 'uuid/v4';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const reducer = persistReducer(
  {
    key: 'slowly', // key is required
    storage, // storage is now required
    blacklist: [
      'letters',
      'login',
      'app'
    ]
  },
  combineReducers({ ...rootReducer }),
);

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(...middleware)));
sagaMiddleware.run(rootSaga);

const persistor =  persistStore(store, null,()=>{
  const { token=null, role=null } = store.getState().me;

  global.log('persistStore ready', {token, role})

  // if(!store.getState().slowly.uuid)
  //   store.dispatch({
  //     'type': 'GOT_UUID',
  //     uuid: uuidv4()
  //   });

  //sso mode?
  const pathname = window.top.location.pathname;
  if (pathname !== "/login/canny/"){
    store.dispatch({
      'type': 'INIT_APP',
      token,
      role
    });
  }
})

export { store, persistor };
