import {
  SAVE_MYSTAMPS_SORTING,
  GOT_TOKEN,
  REFRESH_TOKEN,
  GOT_DEVICE,
  GET_ME_SUCCESS,
  ME_ERROR,
  GET_COLLECTION_SUCCESS,
  SET_TOPICS_SAVE,
  SET_TOPICS_SUCCESS,
  SYNC_EXCLUDE_TOPICS,
  SYNC_EXCLUDE_TOPICS_SUCCESS,
  LANG_INIT,
  LANG_ADD_SUCCESS,
  LANG_UPDATE_SUCCESS,
  LANG_REMOVE_SUCCESS,
  UPDATE_ME,
  CHECK_DOB,
  UPDATE_ME_SUCCESS,
  UPDATE_PREF_SUCCESS,
  CHANGE_STAMP,
  INIT_APP,
  // LETTER_REPLY_INIT,
  CHECK_SLOWLY,
  INIT_APP_COMPLETE,
  SAVE_EMAIL_SUCCESS,
  SETUP_PASSWORD,
  CLOSE_NEW_STAMP_POPUP,
  CLEAR_NEW_STAMP_NOTIFY,
  BUILD_COLLECTION,
  CLEAR_FOUND_STAMP,
  DELETE_ME,
  RESTORE_DATA,
  CANCEL_LOADING,
  FETCH_SLOWLY_SUCCESS,
  SHOW_ERROR,
  RESET_ERROR,
  NEW_STAMP_UNLOCKED,
  REFRESH_COINS,
  REFRESH_STAMPS_SUCCESS,
  UPDATE_FAV_SUCCESS,
  UPDATE_BOOKMARKS_SUCCESS,
  CLEAR_NEW_STAMPS,
  SAVE_EXTEND_PREF,
  LOGOUT_COMPLETE,
  CHECK_NEW_STAMP,
  SAVE_SUBTOPICS,
  HIDE_STAMP
} from '../actions/type';

import I18n from '../I18n';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from 'lodash'

const defaultState = {
  token: null,

  ak_id: null,
  fb_id: null,
  mobile: null,
  email: null,
  original_email: null,

  id: null,
  role: null,
  name: null,
  original_name: null,
  gender: null,
  avatar: null,
  about: null,
  dob: null,
  dob_privacy: null,
  zodiac: null,
  score: null,
  status: null,

  lang: [],
  tags: [],
  items: [],
  collection: [],
  newStamps: [],
  messages: [],
  newCountry: false,
  newletters: false,

  pref: {
    photoShare: {},
    notifications: {
      sent: true,
      received: true,
      joined: true,
      promo: true
    },
    sortBy: '-created_at',
    stamp: 'free'
  },
  excluded_countries: [],
  excluded_tags: [],
  new_request: 2,
  show_last_login: 1,
  target_gender: 'any',
  editortips: false,

  location: null,
  location_code: null,
  locale: 'en',
  timezone: null,
  device: 'unknown',

  created_at: null,
  updated_at: null,
  last_login: null,

  validateStep: 1,
  hasPassword: null,

  target_age_from: null,
  target_age_to: null,

  loading: false,
  error: false,
  connection: true,

  retry:0
};

const _me = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        loading: true,
        newStamps: [],
        messages: [],
        newCountry: false,
        error: false,
        validateStep: 1,
        regStep: 1,
        showStamp:false,
        showStampNotify:false,
        // showCollectionNotify:false,
        foundStamp:false,
        newStampsIsSet: false,
        retry: 0,
      };

    case CLEAR_NEW_STAMPS:
        return {
          ...state,
          newStamps: []
        }

    case SAVE_MYSTAMPS_SORTING:
      return {
        ...state,
        myStampsSorting: action.sorting
      }
    case REFRESH_COINS:
      return{
        ...state,
        coins: action.coins
      }

    case UPDATE_BOOKMARKS_SUCCESS:
      return{
        ...state,
        bookmarks: action.bookmarks
      }

    case UPDATE_FAV_SUCCESS:
      return {
        ...state,
        fav: action.fav
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.token
      }
    case CHECK_SLOWLY:
      return{
        ...state,
        loading: true,
      }

    case FETCH_SLOWLY_SUCCESS:
      return{
        ...state,
        collectionData:[],
      }

    case RESTORE_DATA:
      return {
        ...state,
        ...action.payload.me
      };

    case GOT_TOKEN:
      return {
        ...state,
        token: action.token,
        loading: true
      };
    case GOT_DEVICE:
      const { device, timezone } = action.deviceInfo;

      return {
        ...state,
        device: !!device ? device : state.device,
        timezone: !!timezone ? timezone : state.timezone
      };
    case GET_ME_SUCCESS:
      return {
        ...state,
        ...action.response,
        loading: false,
        error: false
      };

    case CLOSE_NEW_STAMP_POPUP:
      return {
        ...state,
        showStamp: false
      };

    case CLEAR_NEW_STAMP_NOTIFY:
      return {
        ...state,
        showStampNotify: false
      };

    case BUILD_COLLECTION:
      return {
        ...state,
        collectionData: [...action.collection]
      };

    case CHANGE_STAMP:
      return {
        ...state,
        pref:{
          ...state.pref,
          stamp: action.selectedStamp
        }
      };

    case UPDATE_ME:
      return {
        ...state,
        loading: !!action.skip ? false : true,
        error: false
      };

    case CLEAR_FOUND_STAMP:
      return{
        ...state,
        foundStamp: false
      }

    case CHECK_DOB:
      let items = [...state.items];
      let collection = [...state.collection]
      let newStamps = [];
      var temp

      if(!!action.zodiac && action.zodiac!== state.zodiac){
          const index = _.findIndex(items, { item_type: 'zodiac' });
          if(index>=0){
            items[index] = {
              ...items[index],
              item_slug: action.zodiac.toLowerCase(),
              item_name: action.zodiac,
              created_at: moment.utc().format('YYYY-MM-DD'),
              updated_at: moment.utc().format('YYYY-MM-DD')
            }
          }else{
            temp = items[0];
            items[0] = {
              item_type: 'zodiac',
              item_slug: action.zodiac.toLowerCase(),
              item_name: action.zodiac,
              created_at: moment.utc().format('YYYY-MM-DD'),
              updated_at: moment.utc().format('YYYY-MM-DD')
            };
            items.unshift(temp);
          }
          newStamps.push(action.zodiac.toLowerCase())
          collection.push(action.zodiac.toLowerCase())
      }

      if(!!action.birthdayStamp) {
        temp = state.items[0];
        items[0] = {
          item_type: 'birthday',
          item_slug: action.birthdayStamp,
          item_name: 'Birthday Cake',
          created_at: moment.utc().format('YYYY-MM-DD'),
          updated_at: moment.utc().format('YYYY-MM-DD')
        };
        items.unshift(temp);

        newStamps.unshift(action.birthdayStamp);
        collection.push(action.birthdayStamp);
      }

      return {
        ...state,
        items,
        collection,
        newStamps,
        showStamp: newStamps.length>=1,
        showStampNotify: newStamps.length>=1,
        // showCollectionNotify: newStamps.length>=1,
        isBirthday: !!action.isBirthday
      }

    case UPDATE_PREF_SUCCESS:
      if(!!action.result)
        return{
          ...state,
          ...action.result
        }
      return state

    case HIDE_STAMP:
      return {
        ...state,
        items: _.reject(state.items, { item_slug: action.slug }),
        newStamps: _.reject(state.newStamps, { item_slug: action.slug }),
        dontask_hidestamp: !!action.dontask
      }

    case REFRESH_STAMPS_SUCCESS:
      const _newStamps = _.uniq([...action.newStamps, ...state.newStamps])
      const isNew = _newStamps.length >= 1

      return {
        ...state,
        newStamps: _newStamps,
        collection: [...action.newStamps, ...state.collection],
        items: !!action.items && action.items.length>0 ? _.uniqBy(action.items, "item_slug") : state.items,
        showStamp: isNew,
        showStampNotify: isNew,
        newStampsIsSet: !!action.isSet ? action.isSet : false,
        updated_at: new Date().toISOString(),
        error: false
      };

    case UPDATE_ME_SUCCESS:
      return {
        ...state,
        ...action.updateFields,
        loading: !!action.loading ? true : false,
        error: false
      };

    case LANG_INIT:
      return {
        ...state,
        loading: true,
        error: false,
        regStep: action.regStep
      };

    case LANG_ADD_SUCCESS:
      return {
        ...state,
        lang: [...state.lang, action.lang].sort((a, b) => b.level - a.level),
        loading: false,
        error: false
      };

    case LANG_UPDATE_SUCCESS:
      return {
        ...state,
        lang: _.orderBy(_.concat(_.reject(state.lang, l => ( l.id === action.lang.id )), action.lang), ['level'],['desc']),
        loading: false,
        error: false
      };

    case LANG_REMOVE_SUCCESS:
      return {
        ...state,
        lang: _.reject(state.lang, l => (l.id === action.langId)),
        loading: false,
        error: false
      };

    case SET_TOPICS_SAVE:
    case SYNC_EXCLUDE_TOPICS:
      return {
        ...state,
        loading: true,
        error: false
      };

    case SYNC_EXCLUDE_TOPICS_SUCCESS:
      // if (Platform.OS === 'android') {
      //   ToastAndroid.show(I18n.t('TOPICS_UPDATED'), ToastAndroid.SHORT);
      // } else {
      //   Snackbar.show({
      //     title: I18n.t('TOPICS_UPDATED')
      //   });
      // }
      return{
        ...state,
        loading: false,
        excluded_tags: action.excluded_tags,
      }

    case SET_TOPICS_SUCCESS:
      global.log('SET_TOPICS_SUCCES');
      global.log(action);
      return {
        ...state,
        tags: action.tagged.map( item => item.tag_slug),
        loading: false,
        regStep: action.regStep,
        error: false
      };

    case GET_COLLECTION_SUCCESS:
      global.log('GET_COLLECTION_SUCCESS',action);
      return {
        ...state,
        collection: action.collection
      };

    // case SAVE_EMAIL_INIT:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: false
    //   };

    // case SAVE_EMAIL_CANCEL:
    //   return {
    //     ...state,
    //     validateStep: 1,
    //     loading: false,
    //     error: false
    //   };

    // case PASSCODE_SENT:
    //   return {
    //     ...state,
    //     validateStep: 2,
    //     loading: false,
    //     error: false
    //   };

    // case CHECK_PASSCODE:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    //
    // case RESET_PASSWORD:
    //   return {
    //     ...state,
    //     hasPassword: false
    //   };

    case SAVE_EMAIL_SUCCESS:
      return {
        ...state,
        token: action.newToken,
        original_email: state.email,
        validateStep: 1,
        loading: false,
        error: false
      };

    case CANCEL_LOADING:
      return {
        ...state,
        loading: false
      }

    case RESET_ERROR:
      if(!!state.loading || state.retry!==0)
      return{
        ...state,
        loading: false,
        error: false,
        retry:0
      }
      return state

    case ME_ERROR:
      if(action.error.error==='SERVER_TIMEOUT' && !!action.action && action.error.error!=='token_expired')
      return{
        ...state,
        retry: state.retry+1
      }
      else
      return state;

    case SHOW_ERROR:
      global.log('SHOW ERROR', action);
      if (
        action.error.error === 'SERVER_OFFLINE' ||
        action.error.error === 'maintenance_mode' ||
        action.error.error === 'outdated_version' ||
        action.error.error === 'banned_user' ||
        action.error.error === 'token_not_provided'
      ) {
        global.log('Skip error toast', action.error);
      }else{
        const errorMsg = !!action.error.error
              ? I18n.t(action.error.error)
              : !!action.error.email
                ? I18n.t(action.error.email[0])
                : !!action.error.message
                  ? I18n.t(action.error.message)
                  : I18n.t('INTERNAL_ERROR');

        toast.error(errorMsg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true
        });
      }

      return {
        ...state,
        loading: false,
        error: action.error
      };

    // case AUTH_ADDED:
    //   return {
    //     ...state,
    //     fb_id: action.response.fb_id,
    //     g_id: action.response.g_id,
    //     ak_id: action.response.ak_id,
    //     digits_id: action.response.digits_id,
    //     mobile: action.response.mobile,
    //     email: action.response.email,
    //     original_email: action.response.original_email,
    //
    //     loading: false,
    //     error: false
    //   };

    // case AUTH_REMOVED:
    //   let meAfterAuthRemoved = {};
    //
    //   if (action.method === 'FACEBOOK') meAfterAuthRemoved.fb_id = null;
    //   if (action.method === 'GOOGLE') meAfterAuthRemoved.g_id = null;
    //   if (action.method === 'MOBILE') meAfterAuthRemoved.digits_id = meAfterAuthRemoved.ak_id = null;
    //   if (action.method === 'EMAIL') meAfterAuthRemoved.email = null;
    //
    //   return {
    //     ...state,
    //     ...meAfterAuthRemoved
    //   };

    // case LOGIN_EMAIL_SENT:
    //   return {
    //     ...state,
    //     loading: false,
    //     email: action.email,
    //     validateStep: 2
    //   };

    // case CHECK_PASSWORD:
    //   return {
    //     ...state,
    //     // loading: false,
    //     error: false
    //   };

    // case CHECK_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     hasPassword: action.hasPassword
    //     // loading: false
    //   };

    // case SETUP_PASSWORD_CANCEL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false
    //   };
    //
    // case SETUP_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     hasPassword: true,
    //     loading: false,
    //     error: false
    //   };

    case INIT_APP_COMPLETE:
      return {
        ...state,
        loading: false,
        error: false
      };

    case LOGOUT_COMPLETE:
      return {
        ...defaultState,
        device: state.device,
        timezone: state.timezone
      };

    case DELETE_ME:
      return {
        ...state,
        loading: true,
        error: false
      };

    case SETUP_PASSWORD:
      return {
        ...state,
        loading: true,
        error: false
      };
    // case GET_LOCATION:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: false
    //   };

    case NEW_STAMP_UNLOCKED:
      if(!!action.stamp)
      return {
        ...state,
        items: [
          ...state.items,
          action.stamp
        ],
        newStamps: [
          action.stamp.item_slug
        ],
        showStamp: true,
        showStampNotify: true,
        showCollectionNotify: true
      }
      return state

    case SAVE_EXTEND_PREF:
      return {
        ...state,
        extend_pref:[
          ..._.reject(state.extend_pref, l => l.type === action.slug),
          {
            type: action.slug,
            value: action.value
          }
        ]
      }
      
    case SAVE_SUBTOPICS:
      return{
        ...state,
        subtopics: action.subtopics,
        subcheck: !!action.subcheck ? action.subcheck : state.subcheck
      }

    case CHECK_NEW_STAMP:
      if(action.stamp)
        if (state.collection.indexOf(action.stamp) < 0) {
          toast.info(I18n.t("FOUND_STAMP"));
          global.log("New stamp!");
          return {
            ...state,
            collection: [...state.collection, action.stamp],
            newStamps: _.uniq([...state.newStamps, action.stamp]),
            showCollectionNotify: true
          };
        }
      return state;

    default:
      return state;
  }
};

export default _me
