import React from 'react';

const Loader = props => {
  return (
    <div className="fullscreen-loader d-flex justify-content-center align-items-center w-100" style={props.style} >
      <div className="spinner-border text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
