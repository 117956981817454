import React from "react";
import Avatar from "./Avatar";
import moment from "moment";
import I18n from "../I18n";
import { connect } from "react-redux";

import mailPng from "../assets/images/mail.png";
import { reIncomingCounter } from "../selectors";

import { GMAP_KEY } from "../config/ApiConfig"
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

class Incoming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      center: { lat: 1, lng: 1 },
      selected: null,
      zoom: 4,
      currentIndex: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.incoming !== nextProps.incoming &&
      nextProps.incoming.length > 0
    ) {
      let markers = [];
      let index = 0;

      nextProps.incoming.map(marker => {
        const loc = marker.user_location.split(",");
        markers.push({
          ...marker,
          index,
          loc: [Number(loc[0]), Number(loc[1])]
        });
        index++;
        return true;
      });
      if (markers.length)
        this.setState({
          markers,
          selected: markers[0],
          center: markers[0].loc,
          zoom: markers.length > 1 ? 3 : 10
        });
    }
  }

  async componentDidMount() {
    let markers = [];

    let index = 0;

    this.props.incoming.map(marker => {
      const loc = marker.user_location.split(",");
      markers.push({
        ...marker,
        index,
        loc: {
          lat: Number(loc[0]),
          lng: Number(loc[1])
        }
      });
      index++;

      return true;
    });

    this.setState({
      markers,
      selected: markers[0],
      center: markers[0].loc,
      zoom: markers.length > 1 ? 4 : 8
    });

    global.log(this.props.locale);
  }

  handleMarkerClick = marker => {
    this.setState({
      center: marker.loc,
      zoom: 8
    });

    this.setState({
      selected: marker,
      currentIndex: marker.index
    });
  };

  prev = () => {
    const { markers, currentIndex } = this.state;
    if (currentIndex === 0) return false;

    this.handleMarkerClick(markers[currentIndex - 1]);
  };

  next = () => {
    const { markers, currentIndex } = this.state;
    if (markers.length - 1 === currentIndex) return false;

    this.handleMarkerClick(markers[currentIndex + 1]);
  };

  render() {
    const { markers, center, zoom, selected, currentIndex } = this.state;

    return (
      <LoadScript
        googleMapsApiKey={GMAP_KEY}
        language={this.props.locale}
        onLoad={() => {
          this.setState({
            mapLoaded: true
          })
        }}
      >
        <GoogleMap
          mapContainerStyle={{ width:'100%', height: '100%' }}
          center={center}
          zoom={zoom}
          clickableIcons={false}
          options={{
            disableDefaultUI: true,
            maxZoom: 10,
            minZoom: 3
          }}
        >
          { this.state.mapLoaded && markers.map((marker, index) => {
            return (
              <Marker
                key={"marker" + marker.id}
                position={marker.loc}
                icon={{
                  url: mailPng,
                  scaledSize: { width: 50, height: 56 }
                }}
                onClick={()=>{
                  this.handleMarkerClick(marker)
                }}
                zIndex={marker.index===this.state.currentIndex ? 2 : 1}
              />
            );
          })}
        </GoogleMap>

        <div className="w-100 tips-bar position-absolute">
          <span className="small" style={{ backgroundColor: 'rgba(0,0,0,.4)', color:'white' }}>
            <i className="icon-info-circle mr-2" />
            { I18n.t('LOCATION_TIPS') }
          </span>
        </div>
        <div className="map-overlay" style={{ bottom: 20 }}>
          {!!selected && (
            <div className="map-overlay-wrapper d-flex w-100 align-items-center">
              <div
                className={currentIndex === 0 ? "p-2 text-lighter" : "p-2 link"}
                onClick={this.prev}
              >
                <i className="icon-chevron-left" />
              </div>
              <div className="mx-2">
                <Avatar
                  uid={selected.id}
                  gender={selected.gender}
                  borderColor="#fff"
                  id={selected.avatar}
                  size={70}
                />
              </div>
              <div className="flex-grow-1 p-1 ml-1">
                <span className="text-light">
                  <span className="h5 text-primary">{selected.name}</span>
                  <br />
                  {I18n.t(
                    moment.utc(selected.deliver_at) <= moment.utc()
                      ? "NOTI_ARRIVES"
                      : selected.incoming > 1
                      ? "MAP_ARRIVING_MORE"
                      : "MAP_ARRIVING",
                    {
                      total: selected.incoming,
                      time: moment.utc(selected.deliver_at).fromNow()
                    }
                  )}
                </span>
              </div>
              <div
                className={
                  currentIndex === markers.length - 1
                    ? "p-2 text-lighter"
                    : "p-2 link"
                }
                onClick={this.next}
              >
                <i className="icon-chevron-right" />
              </div>
            </div>
          )}
        </div>
      </LoadScript>
    );
  }
}

const mapStateToProps = state => {
  return {
    incoming: state.letters.incoming,
    incomingCounter: reIncomingCounter(state),
    // darkMode: !!state.slowly.darkMode
  };
};

export default connect(
  mapStateToProps,
  null
)(Incoming);
