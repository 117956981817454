import { call, put, take, select, takeLatest } from 'redux-saga/effects';
import {
  getLetters,
  checkIncoming,
  getIncoming,
  readLetter,
  deleteLetter,
  getLatest,
  getUnread,
  getPostImagePass,
} from '../api/letters.api';
import {
  getMyID, 
  getToken, getLastCheck, getFriendIds, getLatestLastCheck, getErrorCount,
  searchingID
} from '../selectors';

import {
  GET_FRIENDS,
  GET_LETTERS,
  GET_LETTERS_SUCCESS,
  READ_LETTER,
  DELETE_LETTER,
  DELETE_LETTER_SUCCESS,
  READ_LETTER_SUCCESS,
  CHECK_NEW_STAMP,
  CHECK_INCOMING,
  CHECK_INCOMING_SUCCESS,
  GET_UNREAD,
  GET_UNREAD_SUCCESS,
  GET_LATEST,
  GET_LATEST_SUCCESS,
  ME_ERROR,
  RESET_ERROR,
  SAVE_POST_PASS,
} from '../actions/type';

import tracking from '../lib/tracking';

function* readLetterWatcher() {
  while (true) {
    const { token, commentID, postID, stamp } = yield take(READ_LETTER);
    try {
      yield call(readLetter, { token, commentID });
      tracking.event('Letter', 'read');

      yield put({ type: READ_LETTER_SUCCESS, commentID, postID });
      yield put({ type: CHECK_NEW_STAMP, stamp });
    } catch (error) {
      global.log(error);
    }
  }
}

function* deleteLetterWatcher() {
  while (true) {
    const { token, id } = yield take(DELETE_LETTER);
    try {
      yield call(deleteLetter, { token, id });
      tracking.event('Letter', 'delete');
      yield put({ type: DELETE_LETTER_SUCCESS, id });
    } catch (error) {
      yield put({ type: ME_ERROR, error, action: DELETE_LETTER, payload: { token, id } });
    }
  }
}

function* _getLetters({ id, page }) {
  const _searchingID = yield select(searchingID)
  const token = yield select(getToken);
  // const me = yield select(reProfileMe);

    try {
      global.log('getLetters');
      const response = yield call(getLetters, { token, id, page });


      if(page===1){
        const uid = yield select(getMyID);
        const passResult = yield call(getPostImagePass, { token, p: id, u: uid })
        global.log('passResult', passResult);
        yield put({ type: SAVE_POST_PASS, pass: passResult.pass, postID: id });
      }

      yield put({ type: GET_LETTERS_SUCCESS, response, postID: id });

      yield put({ type: RESET_ERROR })

      if(_searchingID===id && response.comments.next_page_url!==null){
         global.log("searching posts, auto load next page");
         // yield delay(200)
         yield put({ type: GET_LETTERS, id, page: page+1 });
       }

    } catch (error) {
      yield put({ type: ME_ERROR, error, action: GET_LETTERS, payload: { token, id, page } });
    }
  // }
}

function* latestWatcher() {
  while (true) {
    const { token, page, before = null, after = null } = yield take(GET_LATEST);

    try {
      const response = yield call(getLatest, { token, page, before, after });

      yield put({ type: GET_LATEST_SUCCESS, response, before, after });
      yield put({ type: RESET_ERROR })

    } catch (error) {
      yield put({ type: ME_ERROR, error, action: GET_LATEST,
        payload: { token, page, before, after }
      });
    }
  }
}

function* unreadWatcher() {
  while (true) {
    const { token } = yield take(GET_UNREAD);
    try {
      const response = yield call(getUnread, { token });
      yield put({
        type: GET_UNREAD_SUCCESS,
        unreadCounter: response.unreadCounter
      });
    } catch (error) {
      global.log(error);
      yield put({ type: ME_ERROR, error, action: GET_UNREAD, payload: { token } });
    }
  }
}

function* _checkIncoming() {
  global.log('Check Incoming Letters')

  try {
    const lastCheck = yield select(getLastCheck);
    const token = yield select(getToken);
    const errorCount = yield select(getErrorCount);

    if(!!token && errorCount===0){
      const { incoming=0, arrived=0, now, refresh_friends=false } = yield call(checkIncoming, { token , lastCheck });
      global.log('lastCheck- ' + lastCheck);

      if(arrived>0){
        const latestLastCheck = yield select(getLatestLastCheck)
        global.log('Some arrived, get unread')

        yield put({ type: GET_UNREAD, token });

        yield put({
          type: GET_LATEST,
          token,
          page: 1,
          before: null,
          after: latestLastCheck
        });
      }

      let missing = false;

      if(incoming>0 || arrived>0){
        const response = yield call(getIncoming, { token  });
        const friendIds = yield select(getFriendIds);


        response.data.map((comment)=>{
          if(friendIds.indexOf(comment.post)<0) missing = true;
          return true;
        })
        yield put({ type: CHECK_INCOMING_SUCCESS, response, now });
      }else{
        yield put({ type: CHECK_INCOMING_SUCCESS, now });
      }

      if(!!missing || !!refresh_friends) yield put({ type: GET_FRIENDS, token });
    }
  } catch (error) {
    yield put({ type: ME_ERROR, error, action: CHECK_INCOMING, payload: {} });
  }
}

function* incomingWatcher() {
  yield takeLatest(CHECK_INCOMING, _checkIncoming);
}

function* lettersWatcher() {
  yield takeLatest(GET_LETTERS, _getLetters);
}

const lettersSaga = [
  lettersWatcher,
  unreadWatcher,
  incomingWatcher,
  readLetterWatcher,
  deleteLetterWatcher,
  latestWatcher
]

export default lettersSaga
