export const WEB_VERSION_INT = 40003
export const WEB_VERSION = '4.0.3'

//production
export const API_URL = 'https://api.getslowly.com'
export const APP_URL = 'https://app.getslowly.com';
// export const stripe_client_key = 'pk_live_UEb3jCRneS64TPIcqTOrA1dQ' //old Stripe HK key
export const stripe_client_key = 'pk_live_51LxXbREUuGJ5MwHvPe7RLYawxFAEPalwaKbqaXEKPI0V4HrQE8aclX6ujc2BH78GwFTYlRLGa9JIpzFe0dN7m31t00EcbnBhZw'
export const REDIRECT_URL = "https://web.slowly.app";
export const LOGIN_REDIRECT_URL = "https://web.slowly.app/login/"
export const FACEBOOK_LOGIN_APP_ID = "110456709430307"

//UAT
// export const API_URL = 'https://staging.slowlyapp.com'
// export const APP_URL = 'https://staging.slowlyapp.com'
// // export const stripe_client_key = 'pk_live_UEb3jCRneS64TPIcqTOrA1dQ' //old Stripe HK key
// export const stripe_client_key = 'pk_live_51LxXbREUuGJ5MwHvPe7RLYawxFAEPalwaKbqaXEKPI0V4HrQE8aclX6ujc2BH78GwFTYlRLGa9JIpzFe0dN7m31t00EcbnBhZw'
// export const REDIRECT_URL = "https://web-uat.slowly.app/"
// export const LOGIN_REDIRECT_URL = "https://web-uat.slowly.app/login/"
// export const FACEBOOK_LOGIN_APP_ID = "110456709430307"

//DEV
// export const API_URL = 'https://staging.getslowly.com'
// export const APP_URL = 'https://staging.getslowly.com';
// // export const stripe_client_key = 'pk_test_51LxXbREUuGJ5MwHv5pETjwjQtdKSIZMCLRwLMIiuMp7IfpA23ZhFji0DpSDkQBFxomp3wHyO963bA72u2rFgT3Lb00uTV9509p'
// export const REDIRECT_URL = "http://localhost:3000";
// export const LOGIN_REDIRECT_URL = "https://web-uat.slowly.app/login/"
// export const FACEBOOK_LOGIN_APP_ID = "7028558607155561"

export const CDN_URL = 'https://cdn.getslowly.com';
export const STATIC_CDN_URL = 'https://static.slowly.app/'
export const GMAP_KEY = 'AIzaSyCPkgFxiyg7l-HMGBFYtDvp_y7mqmXNgvU'

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

export async function handleApiErrors(response) {
  global.log("handleApiErrors", response);

  if (response.status === 403 ) {
    const err = { error: "ERROR403_ACCESS_DENIED" }
    throw err;
  } else if (response.ok) {
    return response;
  } else if (response.status === 400 || response.status === 401) {
    let error = await response.json();
    if (error.banned === "true"){
      const err = { error: "banned_user" }
      throw err;
    }else
      throw error;
  } else if (response.status === 404) {
    let error = await response.json();
    if (error.error === "user_not_found") throw error;

    const err = { error: "SERVER_OFFLINE" }
    throw err;
  } else if (response.status >= 500 && response.status < 600) {
    const err = { error: "SERVER_TIMEOUT" }
    throw err;
  } else {
    const err = { error:response.status }
    throw err;
  }
}

export const availableLang = [
  "en",
  "es",
  "de",
  "fil",
  "fr",
  "id",
  "it",
  "hu",
  "nl",
  "pl",
  "pt_BR",
  "sv",
  "sk",
  "vi",
  "tr",
  "cs",
  "ru",
  "th",
  "uk",
  // "ca",
  "da",
  "ar",
  "zh_Hant",
  "zh_Hans",
  "ja",
  "ko"
];

export const availableLangLabel = {
  ar: "العربية",
  ca: "Català",
  da: "Dansk",
  de: "Deutsch",
  en: "English",
  es: "Español",
  fil: "Filipino",
  fr: "Français",
  id: "Indonesia",
  it: "Italiano",
  hu: "Magyar",
  nl: "Nederlands",
  pl: "Polski",
  pt_BR: "Português (Brasil)",
  sk: "Slovenčina",
  sv: "Svenska",
  vi: "Tiếng Việt",
  tr: "Türkçe",
  cs: "Čeština",
  ru: "Русский",
  // he: "עִברִית",
  // sr_Latn: "Srpski (latinica)",
  // si: "සිංහල",
  th: "ไทย",
  uk: "українська",
  zh_Hant: "繁體中文",
  zh_Hans: "简体中文",
  ja: "日本語",
  ko: "한국어"
};

export const extraLocale = [
  'zh_Hant','zh_Hans'
]
