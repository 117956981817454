import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe, reParagraphs } from "../selectors";
import { saveLabels } from '../api/drafts.api';
import { Modal } from "react-bootstrap";
import AppAlert from "../components/AppAlert";
// import { toast } from "react-toastify";
import _ from "lodash";

class Langs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      updating: null,
      newLabel: '',
      labels: this.props.paragraphs.labels
    };
    this.saveDelayed = _.throttle(this.save, 5000);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.paragraphs.labels !== nextProps.paragraphs.labels ) {
      this.setState({
        newLabel: '',
        labels: nextProps.paragraphs.labels
      });
    }
  }

  initUpdate = label => {
    this.setState({
      updating: label.id
    });
  };

  cancelEdit = () => {
    this.setState({
      updating: null
    });
  };

  onChange = ev => {
    this.setState({
      editing: ev.target.value
    });
  };

  newLabelOnChange = ev => {
    this.setState({
      newLabel: ev.target.value
    });
  }

  addNew = (e) => {
    if(e) e.preventDefault()
    if(this.state.newLabel){
      const id = Math.random().toString(36).substring(7) + new Date().getTime()
      this.setState({
        labels: [
          {
            id,
            name: this.state.newLabel
          },
          ...this.state.labels
        ],
        newLabel: ''
      }, this.saveDelayed)

      return true;
    }
    return true;
  }

  deleteLabel = (id) => {
    this.setState({
      alert: {
        title: I18n.t("CONFIRM_REMOVE"),
        message: I18n.t("CONFIRM_DELETE_LABEL"),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: () => {
          this.setState({ alert: null });
        },
        confirmLabel: I18n.t("DELETE"),
        confirmAction: ()=>{
          this.confirmDel(id)
        },
        danger: true
      }
    });
  }

  confirmDel = (id) => {
    this.setState({
      alert: null,
      labels: _.reject(this.state.labels, { id })
    }, this.saveDelayed)
  }

  save = () => {
    global.log('Save now')
    this.props.syncLabels({
      data: this.state.labels
    }, this.props.me.id)

    try{
      saveLabels({
        token: this.props.me.token,
        payload: {
          channel: 'web',
          data: JSON.stringify(this.state.labels)
        }
      })
    }catch(err){
      global.log('save labels error')
    }
  }

  _onHide = () => {
    this.saveDelayed.flush();
    this.props.onHide()
  }

  applyEdit = (e) => {
    if(e) e.preventDefault()
    const labels = _.map(this.state.labels, a => {
      if(a.id===this.state.updating && this.state.editing.length>0){
        return {
          id: a.id,
          name: this.state.editing
        }
      }
      return a
    })

    this.setState({
      updating: false,
      labels,
      editing: ''
    }, this.saveDelayed)

    return true;
  }

  render() {
    const { show } = this.props;
    const { labels, updating } = this.state;
    const rowClass = "d-flex p-2 py-3 align-items-center border-bottom";
    return (
      <Modal
        show={show}
        onHide={this._onHide}
        dialogClassName="paragraph modal-dialog modal-dialog-scrollable modal-lg"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-stable">
          <div className="w-100 position-relative ">
            <h5 className="text-primary p-0 m-0">{I18n.t("LABELS")}</h5>
            <div className="position-absolute" style={{ top: -8, right: -8 }}>
              <button className="btn btn-default" onClick={this.props.onHide}>
                <i className="icon-close h5" />
              </button>
            </div>
            { this.state.labels.length < 20 && (
              <div className="px-0 ml-n2 mt-3 mb-n1">
                <form
                  className="d-flex w-100 mx-1"
                  onSubmit={this.addNew}
                >
                  <div className="flex-grow-1 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      rows="1"
                      value={this.state.newLabel}
                      onChange={this.newLabelOnChange}
                      maxLength={20}
                      placeholder={I18n.t("CREATE_NEW_LABEL")}
                      autoFocus
                    />
                  </div>
                  <button
                    className="btn btn-primary mr-1 btn-sm"
                    type="button"
                    onClick={this.addNew}
                  >
                    {I18n.t("ADD")}
                  </button>
                </form>
              </div>
            )}

          </div>

        </Modal.Header>
        <Modal.Body className="p-0">
          <div>

            { labels.map(label => {
              return (
                <div
                  className={
                    updating === label.id ? rowClass + " bg-stable" : rowClass
                  }
                  key={"lang-" + label.id}
                >
                  {updating === label.id ? (
                    <form
                      className="d-flex w-100 mx-1"
                      onSubmit={this.applyEdit}
                    >
                      <div className="flex-grow-1 mr-2">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          rows="1"
                          defaultValue={label.name}
                          onChange={this.onChange}
                          maxLength={20}
                          placeholder={label.name}
                          autoFocus
                        />
                      </div>
                      <button
                        className="btn btn-primary mr-1 btn-sm"
                        type="button"
                        onClick={this.applyEdit}
                      >
                        {I18n.t("SAVE")}
                      </button>
                      <button
                        className="btn btn-light btn-sm"
                        type="button"
                        onClick={this.cancelEdit}
                      >
                        {I18n.t("CANCEL")}
                      </button>
                    </form>
                  ) : (
                    <div className="h6 flex-grow-1 mb-0 px-2"><i className="icon-tag text-lighter mr-1 small"/> {label.name}</div>
                  )}
                  {updating !== label.id && (
                    <div className="mr-2">
                      <button
                        className="btn btn-light btn-sm"
                        onClick={() => this.initUpdate(label)}
                        disabled={!!this.state.loading}
                      >
                        {!!this.state.loading ? (
                          <span
                            className="spinner-grow spinner-grow-sm text-lighter"
                            role="status"
                          />
                        ) : (
                          I18n.t("EDIT")
                        )}
                      </button>
                      <button
                        className="btn btn-light btn-sm ml-2"
                        onClick={()=>{
                          this.deleteLabel(label.id)
                        }}
                        disabled={!!this.state.loading}
                      >
                        <i className="icon-trash-o text-danger" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="bg-stable">
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={this._save}
          >
            { I18n.t('CREATE_NEW_LABEL') }
          </button>
        </Modal.Footer> */}
        <AppAlert alert={this.state.alert} />
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    paragraphs: reParagraphs(state)
  };
};

const syncLabels = function syncLabels(labels, uid) {
  return {
    type: "SYNC_P_LABELS",
    labels,
    uid
  };
};

export default connect(mapStateToProps, { syncLabels })(Langs);
