import { API_URL, headers, handleApiErrors } from "../config/ApiConfig";

//6.4.0+
export const getWeather = ({ id }) => {
  return fetch(API_URL + "/weather/" + id, {
    method: "GET",
    headers,
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getFriendsDrafts = ({ token, ids = "", page = 1 }) => {
  return fetch(API_URL + "/web/drafts/friends?token=" + token, {
    method: "POST",
    headers,
    body: JSON.stringify({
      ids: ids.join(","),
      page,
    }), 
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getMatchesDrafts = ({ token, page = 1 }) => {
  return fetch(API_URL + "/web/drafts/matches?token=" + token, {
    method: "POST",
    headers,
    body: JSON.stringify({
      app_ver: 8,
      page,
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getDraft = ({ token, post, user_id = null }) => {
  return fetch(API_URL + "/web/drafts/get?token=" + token, {
    method: "POST",
    headers,
    body: JSON.stringify({
      post,
      user_id,
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const deleteDraft = ({ token, user_id }) => {
  return fetch(API_URL + "/web/drafts/delete?token=" + token, {
    method: "DELETE",
    headers,
    body: JSON.stringify({
      user_id,
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const saveDraft = ({
  token,
  post,
  user_id,
  body,
  stamp,
  device_id,
  channel = 'web',
  attachments = "",
  audio = null,
  matching_token,
  remove_openletter=false
}) => {
  return fetch(API_URL + "/web/drafts/save?token=" + token, {
    method: "POST",
    headers,
    body: JSON.stringify({
      post,
      user_id,
      body,
      stamp,
      device_id,
      attachments,
      channel,
      audio,
      matching_token,
      remove_openletter
    })
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const readLetter = ({ token, commentID }) => {
  global.log("Read Letter: " + commentID);
  return fetch(API_URL + "/letter/read?token=" + token, {
    method: "POST",
    headers,
    body: JSON.stringify({
      ids: commentID,
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const deleteLetter = ({ token, id }) => {
  return fetch(API_URL + "/letter/" + id + "/remove?token=" + token, {
    method: "DELETE",
    headers,
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getLatest = ({ token, before, after, page }) => {
  return fetch(
    API_URL +
      "/letter/latest?token=" +
      token +
      "&before=" +
      before +
      "&after=" +
      after +
      "&page=" +
      page
  )
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getUnread = ({ token }) => {
  return fetch(API_URL + "/letter/unread?token=" + token)
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getLetters = ({ token, id, page }) => {
  return fetch(
    API_URL +
      "/friend/" +
      id +
      "/all?ver=70200&token=" +
      token +
      "&page=" +
      page
  )
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getAttachments = ({ token, id, letter_id }) => {
  return fetch(
    API_URL +
      "/friend/" +
      id +
      "/letter/" +
      letter_id +
      "/attachments?token=" +
      token
  )
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const saveLetter = ({
  token,
  id,
  attachments = "",
  body,
  stamp,
  extra = null,
  audio = null
}) => {
  return fetch(API_URL + "/letter/edit?token=" + token, {
    method: "PUT",
    headers,
    body: JSON.stringify({
      id,
      body,
      attachments,
      stamp,
      extra,
      audio
    }),
    timeout: 10000
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then(res => res)
  .catch(error => {
    throw error;
  });
};

export const reply = ({
  token,
  id,
  attachments = "",
  body,
  stamp,
  host = false,
  extra = null,
  audio = null,
}) => {
  const fields = host
    ? {
        body,
        attachments,
        style: "{}",
        stamp,
        host,
        ver: "2",
        extra,
        audio,
        cleardraft: true,
      }
    : {
        body,
        attachments,
        style: "{}",
        stamp,
        ver: "2",
        extra,
        audio,
        cleardraft: true,
      };

  return fetch(API_URL + "/friend/" + id + "/reply?token=" + token, {
    method: "POST",
    headers,
    body: JSON.stringify(fields),
    timeout: 10000,
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const handleMyComment = ({ comment, me }) => {
  global.log("Add my info to latest comment");

  return {
    ...comment,
    name: me.name,
    gender: me.gender,
    location_code: me.location_code,
    avatar: me.avatar,
  };
};

export const checkIncoming = ({ token, lastCheck }) => {
  const url = !!lastCheck
    ? API_URL + "/letter/incoming/check?after=" + lastCheck + "&token=" + token
    : API_URL + "/letter/incoming/check?token=" + token;

  return fetch(url)
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getIncoming = ({ token }) => {
  return fetch(API_URL + "/letter/incoming/all?token=" + token)
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getPostImagePass = ({ token, p, u }) => {
  return fetch(API_URL + "/web/p/pass?token=" + token, {
    method: "POST",
    headers,
    body: JSON.stringify({
      p,
      u,
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const uploadFile = async ({
  token,
  id,
  file,
  fileName = "photo.jpg",
  type = "image",
  duration = null,
}) => {
  global.log(type, file);
  var formData = new FormData();
  formData.append(type, file);
  formData.append("duration", String(!!duration ? duration : "0"));
  // global.log('formData', formData);

  const res = await fetch(
    API_URL + "/attachments/upload?post=" + id + "&token=" + token,
    {
      method: "POST",
      body: formData,
    }
  );
  // global.log('res', res.json())

  if (res.status === 200) return res.json();
  else return false;
};

export const removeFile = async ({
  token,
  post,
  filename,
  file_type = null,
}) => {
  return fetch(API_URL + "/web/drafts/attachments?token=" + token, {
    method: "DELETE",
    headers,
    body: JSON.stringify({
      post,
      filename,
      file_type,
    }),
  })
    .then(handleApiErrors)
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};
