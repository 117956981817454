import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import I18n from "../I18n";
import { connect } from "react-redux";
import {
  reProfileMe,
  reFirstArrive,
  reIncomingCounter,
  getTrust,
} from "../selectors";
import { Overlay } from "react-bootstrap";
import IncomingModal from "./IncomingModal";
import MyStampCollection from "./MyStampCollection";
import Avatar from "./Avatar";
import { APP_URL, CDN_URL } from "../config/ApiConfig";
import { Helmet } from "react-helmet";
import * as firebaseMsg from "firebase/messaging";
import { updateDevice } from "../api/me.api";
import _ from "lodash";
import { toast } from "react-toastify";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIncomingModal: false,
      show: false,
      showSurvey: false,
      messages: [],
    };

    this.throttleRefreshFriends = _.throttle(this.props.refreshFriends, 500);
    this.throttleCheckIncoming = _.throttle(this.props.checkIncoming, 500);
    this.debouncedRefreshStamps = _.debounce(this.props.refreshStamps, 2000);
  }

  componentDidMount() {
    const pathname = window.top.location.pathname;
    if (pathname.indexOf("/login/canny/") < 0) this.setup();
  }

  setup = async () => {
    const isSupported = await firebaseMsg.isSupported();
    global.log("firebaseMsg.isSupported() : ", isSupported);

    if (this.props.trusted && isSupported && this.props.me.token) {
      if (!Notification) return false;
      if (Notification.permission === "default") {
        //ask first
        toast.warn(
          ({ closeToast, toastProps }) => (
            <div>
              <small>{I18n.t("NEW_FEATURE")}</small>
              <br />
              <span className="font-weight-bold">
                {"🔔 " + I18n.t("REG_NOTIFY")} - {I18n.t("SLOWLY_WEB")}
              </span>
            </div>
          ),
          {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            // closeButton: false,
            onClose: async () => {
              const permission = await Notification.requestPermission();
              if (permission === "granted") this.getFCM();

              return true;
            },
          }
        );
      } else if (Notification.permission === "granted") {
        this.getFCM();
      }

      firebaseMsg.onMessage((payload) => {
        global.log("FCM message received. ", payload);
        const { data = {} } = payload;
        const { token, updated_at, coins } = this.props.me;

        switch (data.type) {
          case "accepted_friend":
          case "newfriend":
            if (payload.name) {
              toast.info(I18n.t("NEW_FRIEND") + " " + payload.name);
            }
            this.throttleRefreshFriends();
            if (data.type === "newfriend") this.throttleCheckIncoming();
            return;
          case "refresh_friends":
          case "photo":
          case "audio":
            this.throttleRefreshFriends();
            return;
          case "new_letter":
            this.throttleCheckIncoming();
            return;
          case "new_stamp":
            this.debouncedRefreshStamps();
            return;
          case "refresh_me":
            if (payload.updated_at >= updated_at || !payload.updated_at)
              this.props.refreshMe(token);
            return;
          case "refresh_coins":
            if (payload.coins && payload.coins !== coins)
              this.props.refreshCoins(payload.coins);
            return;
          default:
            break;
        }
        if (payload.notification.body) {
          toast.info(payload.notification.body);
        }
      });
    }
  };

  getFCM = async () => {
    if (!Notification) return false;

    const messaging = firebaseMsg.getMessaging();
    try {
      const currentToken = await firebaseMsg.getToken(messaging, {
        vapidKey:
          "BAz5bgdPlYjNxxHqclnomJxxaNqORVszGdGNqeuKXZiDHymTZym748bS0QFb9qnT9aFK7jEztSMGEoKUEp0_j3M",
      });
      global.log("currentToken", currentToken);

      if (
        currentToken &&
        currentToken !== this.props.fcm &&
        !this.props.loading
      ) {
        // const firebaseRes = await getToken(global.appCheck);
        const newDevice = await updateDevice({
          token: this.props.me.token,
          uuid: this.props.uuid,
          fcm: currentToken,
          lang: this.props.locale,
        });
        global.log("newDevice", newDevice);
        this.props.saveFCM(currentToken);
      }
    } catch (error) {
      global.log("FCM error", error);
    }
  };

  _showIncomingModal = () => {
    this.setState({
      showIncomingModal: true,
    });
  };

  incomingModalClosed = () => {
    this.setState({
      showIncomingModal: false,
    });
  };

  goCollection = () => {
    window.open(APP_URL + "/p/collection/" + this.props.me.hashid);
  };

  toggleMenu = () => {
    this.setState({ show: !this.state.show });
  };

  hideMenu = () => {
    this.setState({ show: false });
  };

  toggleDarkMode = () => {
    this.props.switchDarkMode(!this.props.darkMode);
  };

  showStampCollection = () => {
    this.setState({ showStampCollection: true, show: false });
  };

  stampCollectionClosed = () => {
    this.setState({ showStampCollection: false });
  };

  render() {
    const {
      loading,
      ipcountry = null,
      darkMode,
      location,
      incomingCounter = 0,
      me,
    } = this.props;
    const isPromo =
      _.startsWith(location.pathname, "/promo") ||
      _.startsWith(location.pathname, "/upgrade");
    const isRefresh = _.startsWith(location.pathname, "/refresh");

    if((!me || !me.token) && !isPromo && !isRefresh) return null

    if (!!loading) {
      if (!!darkMode) {
        return (
          <Helmet>
            <link
              rel="stylesheet"
              href={`${CDN_URL}/web/dark-mode.css?v=3.7.14`}
            />
          </Helmet>
        );
      } else {
        return null;
      }
    }
    const { show } = this.state;

    const showUpgrade =
      me.role < 20 &&
      location &&
      location.pathname !== "/upgrade" &&
      ipcountry !== "IN" &&
      ipcountry !== "RU";

    return (
      <header className="App-header sticky-top shadow-sm" dir="ltr">
        <Helmet htmlAttributes={{ lang: this.props.locale }}>
          <title>Slowly</title>
        </Helmet>
        {!!darkMode && (
          <Helmet>
            <link
              rel="stylesheet"
              href={`${CDN_URL}/web/dark-mode.css?v=3.7.14`}
            />
          </Helmet>
        )}
        <div className="container h-100 no-min-width">
          <div className="row align-items-center d-flex h-100 p-1">
            <div className="h-100">
              <NavLink
                className={
                  (me.role >= 20 || isPromo) 
                    ? "no-underline header-logo-plus"
                    : "no-underline header-logo"
                }
                to={!!me.token ? "/home" : "/"}
              />
            </div>
            { !!me && me.token && (
              <div className="col align-items-center d-flex d-md-none">
                <NavLink
                  to="/logout"
                  className="nav-link link text-primary"
                  style={{ marginLeft: "auto", marginRight: -15 }}
                >
                  <i className="icon-signin mr-1" /> {I18n.t("LOGOUT")}
                </NavLink>
              </div>
            )}
            {!!me && me.token && (
              <div className="col text-right flex-row-reverse align-items-center d-none d-md-flex">
                <div
                  ref={(ref) => (this.profileMenu = ref)}
                  className="btn btn-default btn-toolbar mr-3 link p-0"
                  // style={{marginTop: 2}}
                  onClick={this.toggleMenu}
                >
                  <Avatar
                    key={"avatar-" + me.id}
                    uid={me.id}
                    id={me.avatar}
                    gender={me.gender}
                    name={me.name}
                    extraClass="d-block mx-auto"
                    size={40}
                    onClick={this.showProfile}
                  />
                  {me.newStamps.length > 0 && !!me.showStampNotify && (
                    <span className="badge badge-danger badge-corner">
                      {me.newStamps.length}
                    </span>
                  )}
                </div>
                <Overlay
                  target={this.profileMenu}
                  show={show}
                  name={me}
                  placement="bottom-end"
                  rootClose={true}
                  rootCloseEvent="click"
                  onHide={this.hideMenu}
                >
                  {({
                    placement,
                    scheduleUpdate,
                    arrowProps,
                    outOfBoundaries,
                    me: _me,
                    show: _show,
                    ...props
                  }) => (
                    <div
                      className="popover bs-popover-bottom shadow mr-n1"
                      {...props}
                    >
                      <div className="arrow" style={{ right: 10 }}></div>
                      <NavLink
                        title={I18n.t("PROFILE")}
                        to="/profile"
                        className="no-underline"
                        onClick={this.hideMenu}
                      >
                        <div className="p-3 pb-0" style={{ width: 274 }}>
                          <div className="media">
                            <div className="mr-3">
                              <Avatar
                                key={"avatar-" + me.avatar}
                                id={me.avatar}
                                gender={me.gender}
                                name={me.name}
                                size={60}
                              />
                            </div>
                            <div className="media-body">
                              <div
                                className="mt-2 text-primary mb-0 text-truncate h5"
                                style={{ maxWidth: 170 }}
                              >
                                {me.name}
                                {!!me.idv && me.idv.status === 2 && (
                                  <i className="ml-1 icon icon-shield color-idv" />
                                )}
                              </div>
                              <div
                                className="text-light  text-truncate"
                                style={{ maxWidth: 170 }}
                              >
                                <i className="icon-pin mr-1" />
                                {I18n.country(me.location_code)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                      <hr className="m-0 mb-2" />
                      <nav className="nav flex-column mb-2">
                        <NavLink
                          title={I18n.t("PROFILE")}
                          to="/profile"
                          className="nav-link"
                          onClick={this.hideMenu}
                        >
                          <i className="icon-user-circle mr-2" />{" "}
                          {I18n.t("PROFILE")}
                        </NavLink>
                        <NavLink
                          title={I18n.t("MY_STAMPS")}
                          to="/stamps"
                          className="nav-link"
                          onClick={this.hideMenu}
                        >
                          <i className="icon-stamp-small-01 mr-2" />{" "}
                          {I18n.t("MY_STAMPS")}
                          {me.newStamps.length > 0 && !!me.showStampNotify && (
                            <span className="badge badge-danger ml-2">
                              {me.newStamps.length}
                            </span>
                          )}
                        </NavLink>
                        <div
                          className="nav-link link"
                          onClick={this.showStampCollection}
                        >
                          <i className="icon-th mr-2" />{" "}
                          {I18n.t("STAMP_COLLECTION")}
                        </div>
                      </nav>
                      <hr className="m-0" />
                      <nav>
                        <NavLink
                          title={I18n.t("STAMP_STORE")}
                          to="/store"
                          className="nav-link"
                          onClick={this.hideMenu}
                        >
                          <i className="icon-shop mr-2" />{" "}
                          {I18n.t("STAMP_STORE")}
                        </NavLink>
                        <NavLink
                          title={I18n.t("PURCHASE_COINS")}
                          to="/topup"
                          className="nav-link"
                          onClick={this.hideMenu}
                        >
                          <i className="icon-coins mr-2" />{" "}
                          {I18n.t("PURCHASE_COINS")}
                        </NavLink>
                      </nav>
                      <hr className="mt-1 mb-0" />
                      <div className="nav-link">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input link"
                            checked={!!this.props.darkMode}
                            onChange={this.toggleDarkMode}
                            id="darkModeSwitch"
                          />
                          <label
                            className="custom-control-label link"
                            htmlFor="darkModeSwitch"
                          >
                            {I18n.t("DARK_MODE")}
                          </label>
                        </div>
                      </div>
                      <hr className="m-0 mb-2" />
                      <nav className="nav flex-column mb-2">
                        <NavLink
                          className="nav-link"
                          to="/about"
                          onClick={this.hideMenu}
                        >
                          <i className="icon-slowly mr-2" />{" "}
                          {I18n.t("ABOUT_SLOWLY")}
                        </NavLink>
                        <NavLink
                          title={I18n.t("SETTINGS")}
                          to="/settings"
                          className="nav-link"
                          onClick={this.hideMenu}
                        >
                          <i className="icon-cogs mr-2" /> {I18n.t("SETTINGS")}
                        </NavLink>
                        <NavLink
                          to="/logout"
                          className="nav-link link text-primary"
                        >
                          <i className="icon-signin mr-2" /> {I18n.t("LOGOUT")}
                        </NavLink>
                      </nav>
                    </div>
                  )}
                </Overlay>
                <NavLink
                  title={I18n.t("STAMP_STORE")}
                  to="/store"
                  className="btn btn-default btn-toolbar mr-3"
                >
                  <i
                    className="icon-stamp-small-01 text-positive d-block"
                    style={{
                      fontSize: 24,
                      marginTop: 8,
                    }}
                  />
                  {!!this.props.store.new && (
                    <span className="badge badge-danger badge-corner red-dot"></span>
                  )}
                </NavLink>
                <NavLink
                  title={I18n.t("Drafts")}
                  to="/drafts"
                  className="btn btn-default btn-toolbar mr-3"
                >
                  <i
                    className="icon-pencil text-positive d-block"
                    style={{
                      fontSize: 20,
                      marginTop: 10,
                    }}
                  />
                </NavLink>
                {incomingCounter > 0 ? (
                  <button
                    // type="button"
                    className="btn btn-default btn-toolbar mr-3"
                    onClick={this._showIncomingModal}
                    title={I18n.t("INCOMING")}
                  >
                    <i className="icon-send h4 text-positive" />
                    <span className="badge badge-danger badge-corner">
                      {incomingCounter}
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-default btn-toolbar mr-3"
                    title={I18n.t("INCOMING")}
                  >
                    <i className="icon-send h4 text-lighter" />
                  </button>
                )}
                {!!showUpgrade && (
                  <NavLink
                    title={I18n.t("UPGRADE_TO_SLOWLY_PLUS")}
                    to="/upgrade"
                  >
                    <div className="btn btn-secondary badge badge-pill badge-secondary p-1 px-2 mr-2 font-weight-bold">
                      <i className="icon-plus-inline mr-1" />
                      {I18n.t("UPGRADE_NOW")}
                    </div>
                  </NavLink>
                )}
              </div>
            )}
          </div>
        </div>
        <IncomingModal
          show={this.state.showIncomingModal}
          handleClose={this.incomingModalClosed}
          incomingCounter={incomingCounter}
          plus={this.props.me.role >= 20}
          locale={this.props.locale}
        />
        <MyStampCollection
          show={this.state.showStampCollection}
          handleClose={this.stampCollectionClosed}
        />
      </header>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    firstArrive: reFirstArrive(state),
    incomingCounter: reIncomingCounter(state),
    locale: state.slowly.locale,
    store: state.slowly.store,
    darkMode: state.slowly.darkMode,
    uuid: state.slowly.uuid,
    fcm: state.slowly.fcm,
    loading: state.slowly.loading,
    ipcountry: state.slowly.country,
    trusted: getTrust(state),
  };
};

const switchDarkMode = function switchDarkMode(darkMode) {
  return {
    type: "SWITCH_DARKMODE",
    darkMode,
  };
};

const saveFCM = function saveFCM(fcm) {
  return {
    type: "REG_DEVICE",
    fcm,
  };
};

const checkIncoming = function checkIncoming() {
  return {
    type: "CHECK_INCOMING",
  };
};

const refreshStamps = function refreshStamps() {
  return {
    type: "REFRESH_STAMPS",
  };
};

const refreshFriends = function refreshFriends() {
  return {
    type: "GET_FRIENDS",
  };
};

const refreshMe = function refreshMe(token) {
  return {
    type: "GOT_TOKEN",
    token,
    skip: true,
  };
};

const refreshCoins = function refreshCoins(coins, from = "header") {
  return {
    type: "REFRESH_COINS",
    coins,
    from,
  };
};

export default connect(mapStateToProps, {
  switchDarkMode,
  saveFCM,
  checkIncoming,
  refreshStamps,
  refreshFriends,
  refreshMe,
  refreshCoins,
})(withRouter(Header));
