import React from "react";
import I18n from "../I18n";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  syncStore,
  purchase,
  getNow,
  giftSync,
  giftSend,
} from "../api/slowly.api";
import { reProfileMe } from "../selectors";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import coin from "../assets/images/coin.png";
import Stamp from "./Stamp";
import flags from "../lib/flags";
import moment from "../lib/moment";
import { STATIC_CDN_URL } from "../config/ApiConfig";
import SendGiftDetail from "./SendGiftDetail";
import AppAlert from "./AppAlert";
import Hashids from "hashids";
import { availableLang } from "../config/ApiConfig";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import _ from "lodash";

const banners = [
  {
    img: "https://cdn.getslowly.com/web/images/ads/ad_styles.png",
    title: "Unlock All Avatar Items for Free",
    desc: "YEAR_PLAN_BONUS_FEATURES",
    btn: "UPGRADE_TO_SLOWLY_PLUS",
  },
  {
    img: "https://cdn.getslowly.com/web/images/ads/ad_match.png",
    title: "Power Search",
    desc: "By a Specific City, Exact Topics and More",
    btn: "UPGRADE_TO_SLOWLY_PLUS",
  },
  {
    img: "https://cdn.getslowly.com/web/images/ads/ad_coins.png",
    title: "Get 800 Coins Upon Registration",
    desc: "YEAR_PLAN_BONUS_FEATURES",
    btn: "UPGRADE_TO_SLOWLY_PLUS",
  },
  {
    img: "https://cdn.getslowly.com/web/images/ads/ad_bonus.png",
    title: "PLUS Privileges",
    desc: "PLUS-only Avatar Items and Achievement Stamp",
    btn: "UPGRADE_TO_SLOWLY_PLUS",
  },
  {
    img: "https://cdn.getslowly.com/web/images/ads/ad_highres.png",
    title: "Enhanced Media Sharing",
    desc: "High-res Photos and Extended Audio Notes",
    btn: "UPGRADE_TO_SLOWLY_PLUS",
  },
  {
    img: "https://cdn.getslowly.com/web/images/ads/ad_adfree.png",
    title: "AD_FREE",
    desc: "AD_FREE_DESC",
    btn: "UPGRADE_TO_SLOWLY_PLUS",
  },
  {
    img: "https://cdn.getslowly.com/web/images/ads/ad_doubleup.png",
    title: "Double Up Everything",
    desc: "Friend Limit, Media Quota, Everything",
    btn: "UPGRADE_TO_SLOWLY_PLUS",
  },
];

class StampDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      purchased: props.showing ? props.showing.unlocked : false,
      sendGift: false,
      showAd: false,
      purchasing: false,
      arrived: false,
      alert: null,
      currentSec: 10,
      banner: _.sample(banners),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.showing && !!this.props.showing) ||
      (!prevProps.showStampModal && !!this.props.showStampModal)
    ) {
      this.init();
    } else {
      if (
        !!prevProps.showing &&
        !!this.props.showing &&
        prevProps.showing.id !== this.props.showing.id
      ) {
        global.log("prevProps.showing", prevProps.showing);
        global.log("this.props.showing", this.props.showing);
        this.init();
      }
    }
  }

  init = () => {
    const { showing } = this.props;
    global.log("Showing", showing);
    if (!showing) return false;

    if (
      !!showing.unlocked ||
      (!!showing.salesInfo.coming && !showing.salesInfo.isActive)
    ) {
      global.log('already unlocked')
      this.setState({
        loading: false,
        purchased: true,
      });
      return true;
    }

    if (!!showing.salesInfo.comingSoon) {
      const comingTime = moment.orig(showing.salesInfo.coming);
      global.log("coming soon time check", {
        comingTime,
        currentTime: moment.orig(),
      });

      if (comingTime <= moment.orig()) {
        global.log("stamp arrived");
        this.setState({
          arrived: true,
          loading: true,
          banner: _.sample(banners),
        });
      }
    } else {
      this.setState({
        loading: true,
        banner: _.sample(banners),
      });
    }

    if (!showing.unlocked) this.sync();
  };

  sync = async () => {
    const results = await syncStore({
      token: this.props.me.token,
      item_set_id: this.props.showing.id,
      item_type: this.props.showing.collection_type,
    });
    global.log("sync results:", results);

    if (
      this.props.me.role < 20 &&
      !results.purchased &&
      this.props.showing.salesInfo.price === 0
    ) {
      try {
        //prepare ad
        this.setState({
          purchased: false,
          loading: false,
        });
      } catch (error) {
        global.log("error", error);
      }
    } else {
      this.setState({
        purchased: results.purchased,
        loading: false,
      });
    }

    this.props.refreshCoins(results.coins, "stampDetail_sync");
  };

  hideStamp = () => {
    this.setState({
      showModal: false,
    });
  };

  renderActionBar = () => {
    const { showing, me } = this.props;
    const { salesInfo } = showing;
    const { loading, arrived, sendGift } = this.state;
    const purchased = showing.purchased || this.state.purchased;
    if (
      !!salesInfo.coming &&
      !salesInfo.isActive &&
      !salesInfo.discount &&
      !arrived
    ) {
      return (
        <Modal.Footer className="border-0 mx-auto h-auto mt-n1">
          <div className="text-center mb-4">
            <div className="btn btn-outline-dark disabled">
              {salesInfo.comingSoon
                ? " " +
                  I18n.t("Coming Soon") +
                  " - " +
                  moment.custom(salesInfo.starttime, "birthday") +
                  " (" +
                  I18n.t("FREE_STAMPS") +
                  ")"
                : " " +
                  I18n.t("EXPECTED_MONTH", {
                    month: moment.orig(salesInfo.starttime).format("MMMM"),
                  })}
            </div>
            {!showing.unlocked && salesInfo.price === 0 ? (
              !loading ? (
                <div
                  onClick={this._skip}
                  className="mt-4 small link"
                  style={{ height: 40 }}
                >
                  <div className="strong">{I18n.t("DONT_WAIT")}</div>
                  <u>
                    {I18n.t("GET_IT_NOW_FOR_X_COINS", {
                      coins: me.role >= 20 ? 25 : 50,
                    })}
                  </u>
                  {me.role >= 20 && (
                    <i className="icon-plus-inline text-gold ml-1" />
                  )}
                </div>
              ) : (
                <div
                  className="mt-4 py-3 small text-light"
                  style={{ height: 40 }}
                >
                  {I18n.t("LOADING")}
                </div>
              )
            ) : (
              !!showing.unlocked && (
                <div className="my-3 small text-light">
                  <i className="icon-checkmark text-calm" />
                  {" " + I18n.t("OWNED")}
                </div>
              )
            )}
          </div>
        </Modal.Footer>
      );
    }

    return (
      <Modal.Footer>
        <div
          className="d-flex justify-content-between w-100 align-items-center stamp-detail-footer"
          dir="ltr"
        >
          <div>
            <div className="price d-flex align-items-center">
              <img src={coin} height="18" alt={I18n.t("SLOWLY_COINS")} />
              <strong
                className={
                  "h5 mx-2 my-0" +
                  (!!salesInfo.original_price ? " text-danger" : " text-primary")
                }
              >
                {salesInfo.price}
              </strong>
              {!!salesInfo.original_price && (
                <span className="text-lighter ml-1">
                  <span style={{ textDecorationLine: "line-through" }}>
                    {salesInfo.original_price}
                  </span>
                </span>
              )}
            </div>
            <small>
              {I18n.t("MY_COINS")}
              {": "}
              {me.coins}
            </small>
          </div>
          {!!this.state.checking || this.state.purchasing ? (
            <div className="text-right">
              <small className="mr-2">
                <span
                  className="spinner-grow spinner-grow-sm mr-2 text-warning"
                  role="status"
                  aria-hidden="true"
                />
                {I18n.t("LOADING")}
              </small>
            </div>
          ) : (
            <div className="text-right">
              {!!sendGift ? (
                <>
                  <button
                    type="button"
                    className="btn btn-default mr-2"
                    onClick={this.cancelSendGift}
                  >
                    {I18n.t("CANCEL")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-purchase"
                    onClick={this._purchase}
                    disabled={
                      !this.state.friend || this.state.giftable === false
                    }
                  >
                    <i className="icon-gift mr-2" />
                    {I18n.t("SEND_GIFT")}
                  </button>
                </>
              ) : !!purchased ? (
                <button
                  type="button"
                  className="btn btn-dark btn-purchase"
                  disabled
                >
                  <i className="icon-checkmark mr-2 text-calm" />
                  {I18n.t("OWNED")}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary btn-purchase"
                  onClick={this._purchase}
                >
                  {salesInfo.price === 0
                    ? I18n.t("GET_IT_FOR_FREE")
                    : I18n.t("PURCHASE")}
                </button>
              )}
              {showing.price !== 0 &&
                showing.collection_type !== "random" &&
                !sendGift && (
                  <OverlayTrigger
                    key={"send-gift-" + showing.id}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip`}>{I18n.t("SEND_GIFT")}</Tooltip>
                    }
                  >
                    <button
                      type="button"
                      className="ml-2 btn btn-secondary btn-purchase text-truncate"
                      onClick={this._initSendGift}
                    >
                      <i className="icon-gift" />
                    </button>
                  </OverlayTrigger>
                )}
            </div>
          )}
        </div>
      </Modal.Footer>
    );
  };

  _skip = () => {
    const { coins, role } = this.props.me;
    const { loading } = this.state;

    const cost = role >= 20 ? 25 : 50;
    if (!!loading) return false;

    const { ipcountry } = this.props;
    const canPurchaseCoins = ipcountry !== "IN" && ipcountry !== "RU";

    if (coins < cost) {
      //out of coins
      this.setState({
        alert: {
          title: I18n.t("NOT_ENOUGH_COINS"),
          message: I18n.t("MY_COINS") + ": " + coins,
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: canPurchaseCoins
            ? I18n.t("PURCHASE_COINS")
            : I18n.t("REG_NOTED"),
          confirmAction: canPurchaseCoins
            ? this.props.purchaseCoins
            : this.closeAlert,
        },
      });
    } else {
      //confirm purchase
      this.setState({
        alert: {
          title: I18n.t("CONFIRM_PURCHASE"),
          message: I18n.t("CONFIRM_PURCHASE_MSG", {
            coins: cost,
          }),
          cancelLabel: I18n.t("CANCEL"),
          confirmLabel: I18n.t("PURCHASE"),
          cancelAction: this.closeAlert,
          confirmAction: this._confirmSkip,
        },
      });
    }
    return true;
  };

  _confirmSkip = async () => {
    const { showing, uuid } = this.props;
    const { token } = this.props.me;

    this.setState({
      loading: true,
      purchasing: true,
      alert: null,
    });

    try {
      const results = await getNow({
        token: token,
        promo_id: showing.salesInfo.id,
        device_id: uuid,
        item_set_id: showing.id,
      });

      global.log("purchase result", results);
      this.props.refreshCoins(results.coins);

      this.setState({
        purchased: results,
        loading: false,
        purchasing: false,
      });

      this.refreshPurchased();
      this.props.hideStamp();
    } catch (error) {
      global.log(error);

      toast.error(
        !!error.error
          ? I18n.t("ERROR") + ": " + I18n.t(error.error)
          : I18n.t("ERROR") + ": " + I18n.t("SERVER_OFFLINE"),
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          closeOnClick: true,
        }
      );
      this.setState({
        loading: false,
        purchasing: false,
      });
    }
  };

  _purchase = () => {
    const { salesInfo } = this.props.showing;
    const { coins } = this.props.me;
    const { loading } = this.state;
    const { ipcountry } = this.props;
    const canPurchaseCoins = ipcountry !== "IN" && ipcountry !== "RU";
    global.log('_purchase init', this.state)
    
    if (!!loading) return false;

    if (coins < salesInfo.price) {
      //out of coins
      this.setState({
        alert: {
          title: I18n.t("NOT_ENOUGH_COINS"),
          message: I18n.t("MY_COINS") + ": " + coins,
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: canPurchaseCoins
            ? I18n.t("PURCHASE_COINS")
            : I18n.t("REG_NOTED"),
          confirmAction: canPurchaseCoins
            ? this.props.purchaseCoins
            : this.closeAlert,
        },
      });
    } else {
      //confirm purchase
      if (salesInfo.price > 0) {
        this.setState({
          alert: {
            title: I18n.t("CONFIRM_PURCHASE"),
            message: I18n.t("CONFIRM_PURCHASE_MSG", {
              coins: salesInfo.price,
            }),
            cancelLabel: I18n.t("CANCEL"),
            confirmLabel: I18n.t("PURCHASE"),
            cancelAction: this.closeAlert,
            confirmAction: this.state.sendGift
              ? this._confirmSendGift
              : this._confirmPurchase,
          },
        });
      } else {
        //show Ad if user id >= xxx
        if (this.props.me.role < 20) {
          this.showAdThenPurchase();
          // this.setState({ showAd: true })
        } else {
          this._confirmPurchase();
        }
      }
    }
    return true;
  };

  _confirmPurchase = async (payload) => {
    const { showing, uuid } = this.props;
    const { token } = this.props.me;
    const skip = !!payload && !!payload.skip;

    this.setState({
      loading: true,
      purchasing: true,
      alert: null,
    });

    try {
      const results = await purchase({
        token: token,
        device_id: uuid,
        item_set_id: showing.id,
        promo_id:
          (!!this.state.arrived || !!showing.salesInfo.isActive) &&
          showing.salesInfo.id,
      });

      global.log("purchase result", results);
      this.props.refreshCoins(results.coins);
      this.setState({
        purchased: results,
        loading: false,
        purchasing: false,
      });

      if (!skip) {
        this.refreshPurchased();
        this.props.hideStamp();
      }
    } catch (error) {
      global.log(error);
      toast.error(
        error.error
          ? I18n.t("ERROR") + ": " + I18n.t(error.error)
          : I18n.t("ERROR") + ": " + I18n.t("SERVER_OFFLINE"),
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          closeOnClick: true,
        }
      );
      this.setState({
        loading: false,
        purchasing: false,
        alert: null,
      });
    }
  };

  refreshPurchased = () => {
    const { purchased } = this.state;
    const { showing } = this.props;

    const isSet = showing.stamps.length > 1;
    global.log("refreshPurchased", purchased);

    this.props.refreshStamps({
      ...purchased,
      isSet,
    });

    toast.warn(I18n.t("STAMP_SET_PURCHASED", { item: I18n.t(showing.title) }), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: true,
      closeOnClick: true,
    });

    if (this.props.newPurchase) this.props.newPurchase();
  };

  _initSendGift = () => {
    this.setState({ sendGift: true, friend: null, giftable: null });
  };

  cancelSendGift = () => {
    this.setState({ sendGift: false, friend: null, giftable: null });
  };

  _onChangeFriend = (f) => {
    global.log("_onChangeFriend", f);
    this.setState(
      {
        friend: f,
        giftable: null,
      },
      this._checkFriend
    );
  };

  _checkFriend = async () => {
    if (!this.state.friend) return false;
    global.log("checkFriend", this.state.friend);

    const _hashids = new Hashids("sl0wly@pp", 20);
    const data = _hashids.encode(
      this.state.friend.user_id,
      this.props.showing.id,
      this.state.friend.id
    );

    const { giftable, locale } = await giftSync({
      token: this.props.me.token,
      data,
    });
    global.log("giftable", giftable);
    global.log("friends locale", locale);
    this.setState({ giftable, giftLocale: locale, giftData: data });
  };

  _confirmSendGift = async () => {
    const { giftData, friend, giftLocale = "en", giftMsg = "" } = this.state;
    const { token, name } = this.props.me;
    const { uuid } = this.props;

    this.setState({
      purchasing: true,
      alert: null,
    });

    try {
      const _locale =
        giftLocale === "zh"
          ? "zh_Hant"
          : giftLocale === "pt"
          ? "pt_BR"
          : availableLang.indexOf(giftLocale) >= 0
          ? giftLocale
          : "en";

      const title =
        "🎁 " + I18n.t("GIFT_MESSAGE_TITLE", { name, locale: _locale });

      const payload = {
        token,
        data: giftData,
        device_id: uuid,
        title,
        body: giftMsg,
      };

      global.log("send gift payload", payload);
      const result = await giftSend(payload);

      global.log("giftSend", result);
      if (!!result.coins) this.props.refreshCoins(result.coins);
      this.props.refreshFriend(friend.id);

      toast.warn(I18n.t("LETTER_SENT"));

      this.setState({
        friend: null,
        sendGift: false,
        purchasing: false,
        showStampModal: false,
      });
    } catch (error) {
      global.log(error);
      toast.error(
        error.error
          ? I18n.t("ERROR") + ": " + I18n.t(error.error)
          : I18n.t("ERROR") + ": " + I18n.t("SERVER_OFFLINE"),
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          closeOnClick: true,
        }
      );

      this.setState({
        purchasing: false,
      });
    }
  };

  _onChangeGiftMsg = (msg) => {
    this.setState({
      giftMsg: msg,
    });
  };

  closeAlert = () => {
    this.setState({
      alert: null,
    });
  };

  showAdThenPurchase = () => {
    this.setState(
      { showStampModal: false, showAd: true, currentSec: 5 },
      this.countDown
    );
    this._confirmPurchase({ skip: true });
  };

  countDown = () => {
    setTimeout(() => {
      if (this.state.currentSec === 0) return true;
      const currentSec = this.state.currentSec - 1;
      this.setState({ currentSec });
      if (currentSec > 0) this.countDown();
    }, 1000);
  };

  finish = () => {
    this.refreshPurchased();
    this.props.hideStamp();
    this.setState({ showAd: false });
  };

  render() {
    const { showing, showStampModal = false } = this.props;
    const { sendGift = false } = this.state;
    const uri = !!showing ? STATIC_CDN_URL + showing.image : "";

    return (
      <>
        <Modal
          show={!!showStampModal && !this.state.showAd}
          onHide={this.props.hideStamp}
          dialogClassName="default stamp-detail"
          aria-labelledby="contained-modal-title-vcenter"
          keyboard={false}
          centered
        >
          <Modal.Body>
            {!!showing && (
              <div dir="ltr">
                <div
                  className={
                    "bg-stable stamp-detail-bg" +
                    (!showing.image ? " no-img" : "")
                  }
                >
                  {!!showing.image && (
                    <img src={uri} alt={showing.title} className="w-100" />
                  )}
                </div>
                {!showing.isSet && (
                  <div className="stamp-detail-single text-center">
                    <Stamp slug={showing.stamps[0].slug} size={200} />
                  </div>
                )}
                <div
                  className={
                    !showing.isSet ? "p-3 bg-white text-center" : "p-3 bg-white"
                  }
                >
                  <h4 className="mt-0 mb-1 text-darker">
                    {showing.isSet
                      ? I18n.t(showing.title)
                      : I18n.t(showing.stamps[0].name)}
                  </h4>
                  {showing.isSet ? I18n.t(showing.body) : showing.desc}
                  {!showing.isSet && !!showing.url && (
                    <a
                      href={showing.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      &nbsp;<u>{I18n.t("READ_MORE")}</u>
                    </a>
                  )}

                  {showing.stamps.length > 1 && !sendGift && (
                    <div className="row my-3">
                      {showing.stamps.map((stamp) => {
                        return (
                          <OverlayTrigger
                            key={stamp.name}
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip`}>
                                {I18n.t(stamp.name)}
                              </Tooltip>
                            }
                          >
                            <div className="col-4 text-center mb-2 mx-auto">
                              <Stamp slug={stamp.slug} size={120} />
                            </div>
                          </OverlayTrigger>
                        );
                      })}
                    </div>
                  )}
                  {!sendGift && (
                    <div className="mt-3 mb-1 small text-light">
                      <div>
                        {(!!showing.salesInfo.isActive || this.state.arrived) &&
                        !!showing.salesInfo.endtime ? (
                          <span className="text-danger">
                            {!!showing.showingItem &&
                              !!showing.showingItem.country &&
                              showing.salesInfo.multi_regions !== 1 && !!flags[showing.showingItem.country] 
                              && flags[showing.showingItem.country].emoji }
                            {!!showing.salesInfo.discount &&
                              " " + I18n.t("LIMITED_TIME_OFFER") + " -"}
                            {" " +
                              I18n.t("OFFER_ENDS_DATE", {
                                date: moment.custom(
                                  showing.salesInfo.endtime,
                                  "birthday"
                                ),
                              }) +
                              " "}
                          </span>
                        ) : !!showing.showingItem.country &&
                          !showing.collection_type &&
                          !showing.salesInfo.comingSoon &&
                          showing.salesInfo.multi_regions !== 1 ? (
                          <span>
                            { !!flags[showing.showingItem.country] 
                              && flags[showing.showingItem.country].emoji }
                            {showing.price === 0
                              ? " " + I18n.t("Free Exclusive Stamp")
                              : " " +
                                I18n.t("STORE_EXCLUSIVE_TO", {
                                  region: I18n.country(
                                    showing.showingItem.country
                                  ),
                                })}
                          </span>
                        ) : (
                          !showing.salesInfo.comingSoon && (
                            <span>
                              <i className="icon-stamp-small-01" />{" "}
                              {showing.price === 0
                                ? I18n.t("STAMP_SINGLE") +
                                  " ( " +
                                  I18n.t("FREE_STAMPS") +
                                  " )"
                                : showing.isSet
                                ? showing.desc
                                : I18n.t("STAMP_PREMIUM")}
                            </span>
                          )
                        )}
                        {!showing.salesInfo.original_price &&
                          showing.rank > 0 &&
                          showing.rank <= 25 && (
                            <strong className="text-danger">
                              {" " + I18n.t("HOT_ITEMS")}
                            </strong>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!!showing && !!sendGift && (
              <SendGiftDetail
                item={showing}
                _onChange={this._onChangeFriend}
                _onChangeGiftMsg={this._onChangeGiftMsg}
                darkMode={this.props.darkMode}
              />
            )}
            <div className="btn-close link" onClick={this.props.hideStamp}>
              <i className="icon-close" />
            </div>
            {!!sendGift && !!this.state.friend && (
              <div className="small text-danger">
                {this.state.giftable === false && (
                  <p className="px-3 pt-1">
                    <strong>{I18n.t("UNABLE_TO_SEND")}</strong> -
                    {I18n.t("UNABLE_REASON_ITEM_EXISTS")}
                  </p>
                )}
              </div>
            )}
          </Modal.Body>
          {!!showing && this.renderActionBar()}
        </Modal>
        <AppAlert alert={this.state.alert} />

        {/* plus banner */}
        <Modal
          show={!!this.state.showAd}
          onHide={this.hideStamp}
          dialogClassName="stamp-detail"
          aria-labelledby="contained-modal-title-vcenter"
          keyboard={false}
          backdrop="static"
          centered
        >
          <Modal.Body className="p-3" >
            {this.state.banner && (
              <div className="p-3 text-center">
                <img
                  src={this.state.banner.img}
                  style={{ width: 356.8, height: 265.6 }}
                  alt={this.state.banner.title}
                />
                <h3 className="text-danger mt-2 mb-0">
                  {I18n.t(this.state.banner.title)}
                </h3>
                <p>{I18n.t(this.state.banner.desc)}</p>
              </div>
            )}
            {/* <div style={{ width: 800, height: 600 }}>
              <AdComponent dataAdSlot='9702669352' />
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100">
              <Link to="/upgrade">
                <button
                  type="button"
                  className="btn btn-secondary btn-block"
                  onClick={this.finish}
                >
                  {this.state.banner && I18n.t(this.state.banner.btn)}
                </button>
              </Link>
              <button
                type="button"
                className="btn btn-default btn-sm btn-block my-2"
                onClick={this.finish}
                disabled={this.state.currentSec > 0}
              >
                {I18n.t("CONTINUE")}
                {this.state.currentSec > 0
                  ? " (" + this.state.currentSec + ")"
                  : ""}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const refreshCoins = function refreshCoins(coins, from = "stampDetail") {
  return {
    type: "REFRESH_COINS",
    coins,
    from,
  };
};

const refreshStamps = function refreshStamps({
  items = [],
  newStamps = [],
  isSet = false,
}) {
  return {
    type: "REFRESH_STAMPS",
    from: "StampDetailsScreen",
    items,
    newStamps,
    isSet,
    skipRefresh: true,
  };
};

const refreshFriend = function refreshFriend(postID) {
  return {
    type: "REFRESH_FRIEND",
    postID,
  };
};

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    ipcountry: state.slowly.country,
    uuid: state.slowly.uuid,
    darkMode: state.slowly.darkMode,
  };
};

export default connect(mapStateToProps, {
  refreshCoins,
  refreshStamps,
  refreshFriend,
})(StampDetailModal);
