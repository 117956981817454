import React from 'react';
import I18n from '../I18n'
import { Modal } from 'react-bootstrap'
import { Link } from "react-router-dom";

export default class PopupModal extends React.Component {

  _handleAction = () =>{
    const { handleClose } = this.props;
    handleClose()
  }
  render() {
    const { show, messages=[], handleClose } = this.props;

    if(messages.length===0 || !show) return null;
    const trans = messages[0].i18n;
    const msg = {
      ...messages[0],
      title: !!trans ? I18n.t(messages[0].title) : messages[0].title,
      message: !!trans ? I18n.t(messages[0].message) : messages[0].message,
      remarks: !!trans ? I18n.t(messages[0].remarks) : messages[0].remarks,
      button_label: !!trans ? I18n.t(messages[0].button_label) : messages[0].button_label
    }

    return (
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-stable">
          <div className="d-flex flex-row align-items-center">
            <i className="icon-slowly text-primary" style={{ fontSize: 28}} />
          </div>
        </Modal.Header>
        <Modal.Body dir="ltr" className="height-auto p-0">
          <div>
            { !!msg.image && (
              <img src={msg.image} className="d-block d-block-center" alt={
                msg.title
              }
              style={{ width: '100%' }}
              />
            )}
          </div>
          <div className="w-100 p-4">
            <h3 className="text-primary pb-1">{msg.title}</h3>
            <p>{msg.message}</p>
            { !!msg.remarks && (
              <p className="pt-3 text-light small">{ msg.remarks}</p>
            )}
            {
              (msg.id===14 || msg.id===15 || msg.id===16) && (
                <p className="pt-3 text-positive small">
                  <i className="icon-lightbulb mr-1 text-alert" />
                  { I18n.t('STORE_EXCLUSIVE_TO', { region: I18n.t('SLOWLY_WEB')})}
                </p>
              )}
          </div>
          {(msg.deeplink!=='avatarbuilder') ? (
            <div className="w-100 p-3">
              { msg.deeplink==='topup' ? (
                <Link
                  to="/topup"
                  className="btn btn-secondary btn-block"
                  onClick={this._handleAction}
                >
                   {msg.button_label}
                </Link>
              ): msg.deeplink==='upgrade' ? (
                <Link
                  to="/upgrade"
                  className="btn btn-secondary btn-block"
                  onClick={this._handleAction}
                >
                   {msg.button_label}
                </Link>
              ): msg.deeplink==='stampstore' ? (
                <Link
                  to="/store"
                  className="btn btn-primary btn-block"
                  onClick={this._handleAction}
                >
                   {msg.button_label}
                </Link>
              ): !!msg.url ? (
                <a
                  href={msg.url}
                  className="btn btn-primary btn-block"
                  target="_blank"
                  onClick={this._handleAction}
                  rel="noopener noreferrer"
                >
                   {msg.button_label}
                </a>
              ):(
                <button
                  className="btn btn-primary btn-block"
                  onClick={this._handleAction}
                >
                   {msg.button_label}
                </button>
              )}
            </div>
          ): (
            <div className="w-100 p-3">
             <button
                className="btn btn-primary btn-block"
                onClick={this._handleAction}
              >
                 { I18n.t('REG_NOTED')}
              </button>)
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
