import React, { Component } from "react";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import I18n from "../I18n";
import { getData, getDataPublic, checkoutSession } from "../api/payment.api";
import { toast } from "react-toastify";
import tracking from "../lib/tracking";
import _ from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Login from "./Login";

function getQueryParameterByName(name) {
  var pairStrings = window.location.search.slice(1).split("&");
  var pairs = pairStrings.map(function (pair) {
    return pair.split("=");
  });
  return pairs.reduce(function (value, pair) {
    if (value) return value;
    return pair[0] === name ? decodeURIComponent(pair[1]) : null;
  }, null);
}

class Upgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coinsdata: null,
      save: true,
      loading: false,
      stripe: null,
      ready: false,
      plans: ["annual", "six-month", "monthly"],
      annual: {
        title: "YEARLY_PLAN",
        months: 12,
        price: "...",
        total_discounted: null,
        discount: null,
      },
      "six-month": {
        title: "HALF_YEARLY_PLAN",
        months: 6,
        price: "...",
        total_discounted: null,
        discount: null,
      },
      monthly: {
        title: "MONTHLY_PLAN",
        months: 1,
        price: "...",
        total_discounted: null,
        discount: null,
      },
      selectedPlan: "annual",
      currency: "...",
      discount: true,
      alert: null,
    };
  }

  componentDidMount() {
    this.setup();
  }

  changePlan = (plan) => {
    if (this.state.loading) return false;

    this.setState({
      selectedPlan: plan,
    });
    tracking.event("changePlan_" + plan);
  };

  hideAllModals = () => {
    this.setState({
      showLogin: false,
    });
  };

  _continue = async () => {
    if (!this.props.me || !this.props.me.token) {
      this.setState({
        showLogin: true,
      });
      return true;
    }

    this.setState({ loading: true });

    try {
      const _checkout = await checkoutSession({
        token: this.props.me.token,
        payload: {
          product_id: this.state[this.state.selectedPlan].id,
          currency: this.state.currency,
          device_id: this.props.uuid,
          locale: this.props.locale,
          mode: "subscription",
          promo: !!this.state.discount_type ? this.state.discount_type : null,
        },
      });
      global.log("_checkout", _checkout);

      if (_checkout.url) {
        window.top.location.href = _checkout.url;
        return true;
      }
    } catch (error) {
      global.log("stripe error", error);
      this.props.showError({
        message: "UNABLE_TO_REFRESH_DATA",
      });
    }

    this.setState({ loading: false });
  };

  _back = () => {
    this.setState({
      next: false,
    });
  };

  priceList = (plan) => {
    const isSelected = plan === this.state.selectedPlan;
    const _plan = this.state[plan];
    if (!_plan.amount) return null;

    if (!isSelected && !!this.state.next) return false;
    // const total = this.state["monthly"].amount * _plan.recurring;
    // const discount = Math.round(((total - _plan.amount) / total) * 100);

    const finalAmount = !!this.state.discount
      ? _plan.amount * ((100 - this.state.discount) / 100) : _plan.amount;

    return (
      <div
        className={
          !!isSelected
            ? plan === "annual"
              ? "card mb-2 mt-3 price-selected link"
              : "card mb-2 price-selected link"
            : "card my-2 link"
        }
        key={_plan.title}
        onClick={() => {
          this.changePlan(plan);
        }}
      >
        <div className={"card-body"}>
          {/* {_plan.recurring &&
          (plan === "annual") &&
          !!isSelected ? (
              <div className="position-absolute discount-badge badge badge-xs badge-danger">
                {I18n.t("SAVE_DISCOUNT", { discount })}
              </div>
          ) : (
            !_plan.recurring &&
            plan === "annual" && (
              <div className="position-absolute discount-badge badge badge-sm badge-danger">
                {I18n.t("BEST_VALUE")}
              </div>
            )
          )} */}
          {plan === "annual" && (
            <div className="position-absolute discount-badge badge badge-sm badge-danger">
              {I18n.t("BEST_VALUE")}
            </div>
          )}

          <div className="d-flex align-items-center">
            <div>
              <h3 className="mb-0">{I18n.t(_plan.title)}</h3>
              {/* {_plan.recurring &&
                (plan === "annual" || plan === "six-month") &&
                !!isSelected && (
                  <div className="h5 mt-1 mb-0">
                    {
                      <del className="pr-2 font-weight-normal">
                        {_plan.symbol}
                        {I18n.toNumber(total, {
                          precision: _plan.no_decimal ? 0 : 2,
                        })}
                      </del>
                    }
                    {_plan.symbol}
                    {I18n.toNumber(_plan.amount, {
                      precision: _plan.no_decimal ? 0 : 2,
                    })}
                  </div>
                )} */}

              {plan === "annual" && (
                <span className="text-danger strong">
                  {"+ "}
                  {I18n.t("COINS_AMOUNT", { amount: 800 })}
                </span>
              )}
            </div>
            <div className="text-right flex-fill">
              {!!this.state.discount ? (
                <div className="mb-0">
                  <del>
                    {_plan.symbol}
                    {I18n.toNumber(_plan.amount, {
                      precision: _plan.no_decimal ? 0 : 2,
                    })}
                  </del>
                  <span className="text-danger ml-2 h4">
                    {_plan.symbol}
                    { I18n.toNumber(finalAmount, {
                        precision: _plan.no_decimal ? 0 : 2,
                    })}
                  </span>
                </div>
              ) : (
                <h4 className="mb-0">
                  {_plan.symbol}
                  {I18n.toNumber(_plan.amount, {
                    precision: _plan.no_decimal ? 0 : 2,
                  })}
                </h4>
              )}

              {plan !== "monthly" && (
                <div className={"mb-0"}>
                  {"~ " + _plan.symbol}
                  {I18n.toNumber(finalAmount / _plan.recurring, {
                    precision: _plan.no_decimal ? 0 : 2,
                  }) + " "}
                  {I18n.t("PER_MONTH")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  setup = async () => {
    const { me } = this.props;

    const promo = getQueryParameterByName("code");
    const token = !!me ? me.token : null;
    const _getData = !!token ? getData : getDataPublic;

    const result = await _getData({
      token,
      promo,
    });

    global.log("getData", result);

    const discount_expire_at = !!result.discount_expire_at
      ? moment.utc(result.discount_expire_at).diff(moment(), "days")
      : null;
    const coinsValue = result.coinsdata
      ? result.coinsdata[result.coinsdata.length - 1]
      : {};

    this.setState({
      coinsValue:
        coinsValue.slug === "coins_800"
          ? coinsValue.symbol + coinsValue.amount
          : "US$11.99",
      ready: true,
      currency: result.currency,
      tax: result.tax,
      location_code: result.location_code,
      discount: result.discount,
      discount_type: result.discount_type,
      discount_expire_at,
      ..._.keyBy(result.plans, "slug"),
    });
  };

  lockProductList = () => {
    this.setState({
      locked: true,
    });
  };

  unlockProduct = () => {
    this.setState({
      locked: false,
    });
  };

  onLoad = () => {
    if (!!this.state.loading) return false;
    this.setState({
      loading: true,
    });
  };

  onCancel = () => {
    this.setState({
      loading: false,
    });
  };

  onError = (error) => {
    this.setState({
      loading: false,
    });
    if (error)
      toast.error(I18n.t(error.error), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true,
        ...error,
      });
    else
      toast.error(I18n.t("INTERNAL_ERROR"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true,
      });
  };

  render() {
    const loggedIn = !!this.props.me && this.props.me.token;
    const {  ready, plans } = this.state;

    if (!ready) {
      return (
        <div className="w-100 store-wrapper text-center pt-5">
          <small>
            <span
              className="spinner-grow spinner-grow-sm mr-2 span-warning"
              role="status"
              aria-hidden="true"
            />
            {I18n.t("LOADING")}
          </small>
        </div>
      );
    }

    const { idv_available = false } = this.props.me;

    // global.log('paywall me', this.props.me)
    // global.log('paywall slowly', this.props.idv_countries)

    return (
      <div className="container plus no-min-width px-0">
        <div className="py-4 position-relative">
          {!this.state.locked && (
            <div className="row mx-auto" style={{ maxWidth: 1280 }}>
              <div className="col-12 col-xl-6">
                <div
                  className="sticky-top p-3 mx-auto"
                  style={{ maxWidth: 640 }}
                >
                  <h5 className="text-darker font-weight-normal ">
                    {I18n.t("UPGRADE_TO_SLOWLY_PLUS")}
                  </h5>
                  <h3 className="text-darker" style={{ fontWeight: 900 }}>
                    {I18n.t("PAYWALL_UNLOCK_THE_MAGIC")}
                  </h3>
                  <div className="text-center mt-3">
                    <img
                      src="https://promo.slowly.app/paywall-header-mailbox.png"
                      alt="Slowly Plus"
                      style={{ width: 680, maxWidth: "90%" }}
                    />
                  </div>
                  <div className="mt-3 text-left stripe-credit">
                    <div
                      className="text-light mb-2 px-2"
                      style={{ fontSize: 12 }}
                    >
                      {I18n.t("Secure payment powered by Stripe")}
                    </div>
                    <img
                      src="https://promo.slowly.app/slowly-payments.svg"
                      alt="Payment Methods"
                      className="payment-icon"
                    />
                    {this.state.currency === "CNY" && (
                      <img
                        src="https://promo.slowly.app/slowly-payments-cn.svg"
                        alt="Payment Methods"
                        className="ml-n1 payment-icon"
                      />
                    )}
                    {this.state.currency === "GBP" && (
                      <img
                        src="https://promo.slowly.app/slowly-payments-03.svg"
                        alt="Payment Methods"
                        className="ml-n1 payment-icon"
                      />
                    )}
                    {this.state.currency === "EUR" &&
                      this.props.me.location_code === "FR" && (
                        <img
                          src="https://promo.slowly.app/slowly-payments-04.svg"
                          alt="Payment Methods"
                          className="ml-n1 payment-icon"
                        />
                      )}
                    {this.state.currency === "EUR" && (
                      <img
                        src="https://promo.slowly.app/slowly-payments-05.svg"
                        alt="Payment Methods"
                        className="ml-n1 payment-icon"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col bg-stable main-col">
                {plans.map(this.priceList)}
                <div className="p-2 d-flex small">
                  <div className="text-light">
                    {I18n.t("CURRENCY_WITH_CODE", {
                      code: this.state.currency,
                    })}
                  </div>
                  <div className="flex-fill text-right">
                    {this.state.discount > 0 && (
                      <div className="text-danger font-weight-bold h6">
                        <i className="icon-gift mr-1" />
                        {/* {this.state.discount +
                          "% OFF (–" +
                          this.state[selectedPlan].symbol +
                          Math.round(
                            this.state[selectedPlan].amount *
                              (this.state.discount / 100) *
                              100
                          ) /
                            100 +
                          ")"} */}
                        {this.state.discount + "% OFF"}
                        {this.state.discount_expire_at !== null &&
                          !this.state.next && (
                            <div className="mt-1 ml-2 text-danger small">
                              {"⏰ "}
                              {this.state.discount_expire_at > 1
                                ? I18n.t("EXPIRES_IN_DAYS", {
                                    days: this.state.discount_expire_at,
                                  })
                                : I18n.t("LAST_DAY")}
                            </div>
                          )}
                      </div>
                    )}
                    {this.state.discount > 0 && this.state.discount_type && (
                      <div className="ml-2 text-light">
                        Coupon: {this.state.discount_type}
                      </div>
                    )}
                    {/* {!!this.state.tax && (
                      <span className="mb-1">
                        {!!this.state.next
                          ? " + " +
                            this.state.tax.type +
                            this.state[selectedPlan].symbol +
                            this.state[selectedPlan].tax
                          : " + " +
                            this.state.tax.type +
                            " " +
                            this.state.tax.rate +
                            "%"}
                        <span className="text-light ml-2">
                          {"( " + I18n.country(this.state.location_code) + " )"}
                        </span>
                      </span>
                    )} */}
                    {/* {!!this.state.next && (
                      <h5>
                        <span className="mr-2">{I18n.t("Total")}</span>
                        {this.state[selectedPlan].symbol}
                        {!!this.state[selectedPlan].amount_total
                          ? this.state[selectedPlan].amount_total
                          : this.state[selectedPlan].amount_after_tax}
                      </h5>
                    )} */}
                  </div>
                </div>
                {/* {this.state.discount_type === "buymeacoffee" && (
                  <div className="alert alert-warning" role="alert">
                    {'☕️ '}
                    {I18n.t(
                      "To show our gratitude to your Coffee support, you will get an extra 20% discount for the first year of Slowly Plus subscription."
                    )}
                  </div>
                )} */}
                {/* {this.state.discount_type === "twoyears" && (
                  <div className="alert alert-warning" role="alert">
                    {'🎉 '}
                    <strong className="mx-2">
                      {I18n.t(
                        "Thanks for joining Slowly for more than two years!"
                      )}
                    </strong>
                    {I18n.t(
                      "To show our gratitude, you will get an extra 20% discount for the first year of Slowly Plus subscription."
                    )}
                  </div>
                )} */}
                <hr className="mb-4" />
                {!this.state.next && (
                  <>
                    {/* Privileges */}
                    <div className="d-flex w-100 my-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt1.png")}
                          alt={I18n.t("PAYWALL_ADV_SEARCH")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("PAYWALL_ADV_SEARCH")}</h5>
                        {I18n.t("PAYWALL_ADV_SEARCH_DESC")}
                      </div>
                    </div>

                    <div className="d-flex w-100 my-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-edit.png")}
                          alt={I18n.t("IN_TRANSIT_EDIT")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("IN_TRANSIT_EDIT")}</h5>
                        {I18n.t("PAYWALL_IN_TRANSIT_EDIT_DESC")}
                      </div>
                    </div>

                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt-coins.png")}
                          alt={I18n.t("MONTHLY_COINS")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("MONTHLY_COINS")}</h5>
                        {I18n.t("MONTHLY_COINS_DESC", { coins: 100 })}
                      </div>
                    </div>

                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-cool.png")}
                          alt={I18n.t("PAYWALL_AD_FREE_EXP")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">
                          {I18n.t("PAYWALL_AD_FREE_EXP")}
                        </h5>
                        {I18n.t("AD_FREE_DESC")}
                      </div>
                    </div>

                    {!!idv_available && (
                      <div className="d-flex w-100 my-3">
                        <div className="px-2">
                          <img
                            style={{ width: 26, height: 26 }}
                            src={require("../assets/images/shield.png")}
                            alt={I18n.t("ID_VERIFICATION")}
                          />
                        </div>
                        <div className="flex-fill">
                          <h5 className="mb-0">{I18n.t("ID_VERIFICATION")}</h5>
                          {I18n.t("PAYWALL_GET_VERIFIED_DESC")}
                        </div>
                      </div>
                    )}

                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-mailbox.png")}
                          alt={I18n.t("PAYWALL_MORE_OPEN_LETTERS")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">
                          {I18n.t("PAYWALL_MORE_OPEN_LETTERS")}
                        </h5>
                        {I18n.t("PAYWALL_MORE_OPEN_LETTERS_DESC")}
                      </div>
                    </div>

                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt3.png")}
                          alt={I18n.t("Unlimited Styles")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("Unlimited Styles")}</h5>
                        {I18n.t("PAYWALL_UNLIMITED_STYLES_DESC")}
                      </div>
                    </div>

                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt2.png")}
                          alt={I18n.t("PAYWALL_DOUBLE_QUOTA")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">
                          {I18n.t("PAYWALL_DOUBLE_QUOTA")}
                        </h5>
                        {I18n.t("PAYWALL_DOUBLE_QUOTA_DESC")}
                      </div>
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt4.png")}
                          alt={I18n.t("PAYWALL_10X_MEDIA_QUOTA")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">
                          {I18n.t("PAYWALL_10X_MEDIA_QUOTA")}
                        </h5>
                        {I18n.t("PAYWALL_10X_MEDIA_QUOTA_DESC")}
                      </div>
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt-gift.png")}
                          alt={I18n.t("PAYWALL_AND_MORE")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("PAYWALL_AND_MORE")}</h5>
                        {I18n.t("PAYWALL_AND_MORE_DESC")}
                      </div>
                    </div>
                    <div className="plus-bottom-gap" />
                    <div className="position-fixed bottom-action-bar w-100 bg-stable">
                      <div className="container no-min-width">
                        <div className="row">
                          <div className="col-6 d-none d-xl-block"></div>
                          {!(
                            this.state.location_code === "IN" ||
                            this.state.location_code === "TR" ||
                            this.state.location_code === "RU"
                          ) ? (
                            <div className="col main-col">
                              <hr className="mt-0 mb-3" />
                              <button
                                type="button"
                                className="btn btn-primary btn-block py-2 shadow-lg mb-3"
                                onClick={this._continue}
                                disabled={this.state.loading}
                              >
                                {I18n.t("CONTINUE")}
                              </button>
                            </div>
                          ) : (
                            <div className="col">
                              <hr className="mt-0 mb-0" />
                              <div className="p-3 text-center">
                                <i className="icon-info mr-2 text-danger" />
                                Please purchase via our mobile app.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {!loggedIn && (
          <Modal
            show={this.state.showLogin && !loggedIn}
            onHide={this.hideAllModals}
            dialogClassName="modal-md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body dir="ltr" className="p-0">
              <Login isModal onSuccess={this.setup} />
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    locale: state.slowly.locale,
    uuid: state.slowly.uuid,
    idv_countries: state.slowly.idv_countries,
  };
};

const refreshCoins = function refreshCoins(coins, from = "upgrade") {
  return {
    type: "REFRESH_COINS",
    coins,
    from,
  };
};

const showError = function showError(error) {
  return {
    type: "SHOW_ERROR",
    error,
  };
};

export default connect(mapStateToProps, {
  refreshCoins,
  showError,
})(Upgrade);
