import { API_URL, headers, handleApiErrors } from '../config/ApiConfig';

export const getSuggestions = ({ token }) => {
  return fetch(API_URL + '/openletters/suggestions', {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const logSuggestions = ({ token }) => {
  return fetch(API_URL + '/openletters/suggestions/log', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getSuggestion = ({ token, matching_token }) => {
  return fetch(API_URL + '/openletters/suggestions/details', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      matching_token,
    }),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const markAsRead = ({ token, matching_token, timestamp }) => {
  return fetch(API_URL + '/openletters/suggestions/read', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      matching_token,
      timestamp
    }),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

// export const hideSuggestion  = ({ token, matching_token }) => {
//   return fetch(API_URL + '/openletters/suggestions/hide', {
//     method: 'POST',
//     headers: {
//       ...headers,
//       Authorization: 'Bearer ' + token,
//     },
//     body: JSON.stringify({
//       matching_token,
//     }),
//   })
//     .then(handleApiErrors)
//     .then(res => res.json())
//     .then(res => res)
//     .catch(error => {
//       throw error;
//     });
// };

// export const reportSuggestion  = ({ token, matching_token, user_name, reason, comments }) => {
//   return fetch(API_URL + '/openletters/suggestions/report', {
//     method: 'POST',
//     headers: {
//       ...headers,
//       Authorization: 'Bearer ' + token,
//     },
//     body: JSON.stringify({
//       matching_token,
//       user_name, 
//       reason, 
//       comments
//     }),
//   })
//     .then(handleApiErrors)
//     .then(res => res.json())
//     .then(res => res)
//     .catch(error => {
//       throw error;
//     });
// };

export const syncMyOpenLetters = ({ token }) => {
  return fetch(API_URL + '/openletters/sync', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const init = ({ token, language, topic }) => {
  return fetch(API_URL + '/openletters/init', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      language,
      topic,
    }),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const resume = ({ token, slot_id }) => {
  return fetch(API_URL + '/openletters/manage/' + slot_id, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

// export const getDraft = ({ token, slot_id }) => {
//   return fetch(API_URL + '/openletters/manage/' + slot_id + '/draft', {
//     method: 'GET',
//     headers: {
//       ...headers,
//       Authorization: 'Bearer ' + token,
//     },
//   })
//     .then(handleApiErrors)
//     .then(res => res.json())
//     .then(res => res)
//     .catch(error => {
//       throw error;
//     });
// };

export const saveDraft = payload => {
  const { token, slot_id } = payload;
  if(!slot_id) return false;

  return fetch(API_URL + '/openletters/manage/' + slot_id + '/save', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(payload),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const publish = (payload) => {
  const { token, slot_id } = payload;
  if(!slot_id) return false;

  return fetch(API_URL + '/openletters/manage/' + slot_id + '/publish', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(payload),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const unpublish = ({ token, slot_id }) => {
  return fetch(API_URL + '/openletters/manage/' + slot_id + '/unpublish', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const republish = ({ token, slot_id }) => {
  return fetch(API_URL + '/openletters/manage/' + slot_id + '/unpublish/cancel', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const replyOpenLetter = ({ token, fields }) => {

  return fetch(API_URL + '/openletters/reply', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
      // 'X-Firebase-AppCheck': appCheckToken,
    },
    body: JSON.stringify(fields),
    timeout: 10000,
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};