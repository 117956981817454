import React from 'react';
import I18n from "../I18n";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class BlueBadgeWithTooltip extends React.PureComponent {
  render() {
    return (
        <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={
          <Tooltip>
            <div className="p-3 stamp-pop text-left">
              <h6 className="text-white font-weight-bold mb-0">
                <i className="icon icon-shield mr-1"/>{I18n.t('VERIFIED_ACCOUNT')}
              </h6>
              <div className="small">
                {I18n.t('ABOUT_VERIFIED_ACCOUNT')}
              </div>
            </div>
          </Tooltip>
        }
      >
        <span className="icon icon-shield color-idv ml-1 link display-inline-block"  />
      </OverlayTrigger>
    );
  }
}
