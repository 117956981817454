import { all, fork } from 'redux-saga/effects';

// import slowly from './slowly.saga';
import me from './me.saga';
import loading from './loading.saga';
import contacts from './contacts.saga';
import letters from './letters.saga';
import slowly from './slowly.saga';

const sagas = [
  ...loading,
  ...me,
  ...contacts,
  ...letters,
  ...slowly
];

/**
 * rootSaga
 */
export default function* root() {
  yield all(
    sagas.map(saga => fork(saga))
  )
}
