import React from 'react';
import I18n from '../I18n'
import { Modal } from 'react-bootstrap'
import IncomingVector from './IncomingVector'
import Incoming from './Incoming'

export default class IncomingModal extends React.Component {
  render() {
    const { show, handleClose, incomingCounter=0, plus=false,locale='en' } = this.props;

    if(incomingCounter===0) return null;

    return (
      <Modal
        show={show} onHide={handleClose}
        dialogClassName="default"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="d-flex flex-row align-items-center">
            <i className="icon-send text-calm mx-2" />
            <h5 className="text-primary p-0 m-0 flex-grow-1">
              {
                incomingCounter===0 ?  I18n.t("NO_INCOMING_MSG") :
                incomingCounter===1 ? I18n.t("INCOMING_MSG_ONE")
                : I18n.t( "INCOMING_MSG", { count: incomingCounter })
              }
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body dir="ltr" className={!plus && "modal-map"}>
          { plus ? <Incoming locale={locale} /> : <IncomingVector />}
        </Modal.Body>
      </Modal>
    );
  }
}
