import React, { PureComponent } from "react";
import { STATIC_CDN_URL } from "../config/ApiConfig";
import _ from 'lodash'
import I18n from "../I18n";

class WeatherTag extends PureComponent {
  render() {
    if (!this.props.weather) return null;
    const { data=[] } = this.props.weather;
    const {location_code } = this.props
    if(data.length<=0) return null;

    const now = Date.now()
    global.log('now: '+ now)

    const filtered = _.find(data, d=>{
      return d.dt >= now/1000
    })
    global.log('filtered data', filtered)

    const closestWeather = !filtered ? data[0] : filtered
    if(!closestWeather) return null;

    return (
      <span
        className="badge badge-pill badge-weather badge-secondary"
        onClick={this.props.showModal}
      >
        { closestWeather.weather && closestWeather.weather[0].icon && (
          <img
            src={STATIC_CDN_URL + "weather/" + closestWeather.weather[0].icon + ".png"}
            alt={closestWeather.weather[0].description}
          />
        )}
        {I18n.weatherConvert(closestWeather.main.temp, location_code)}
      </span>
    );
  }
}

export default WeatherTag;
