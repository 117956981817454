import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe } from '../selectors'
import StaticSideMenu from "../components/StaticSideMenu";
class ExcludedTopics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      tempTags: props.me.tags,
      excluded: props.me.excluded_tags.map(
        item => item.tag_slug
      ),
      saved: false,
      maxExclude: props.me.role===20 ? 10 : 5
    };
  }

  componentDidMount(){
    if(this.props.me.role===20) this.setState({ maxExclude: 10 })
  }


  pickTag = slug => () => {
    if(this.state.tempTags.indexOf(slug)>=0) return false;

    const index = this.state.excluded.indexOf(slug);
    const temp = this.state.excluded;

    if (index > -1) {
      //remove tag
      temp.splice(index, 1);
    } else {
      if(temp.length>=this.state.maxExclude) return false;
      temp.push(slug);
    }

    this.setState({
      touched: true,
      saved: false,
      excluded: temp
    });
  };

  _syncExcludeTopics = () => {
    this.props.syncExcludeTopics(this.props.me.token, this.state.excluded);
    this.setState({ touched: false, saved: true });
  };

  render() {
    const { tags } = this.props;
    const { tempTags, excluded, maxExclude } = this.state;

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu idv_available={this.props.me.idv_available} />
            </div>
            <div className="col-9 p-3 h-100">

              {/* TAGS */}
              <div className="card shadow-sm my-2 ">
                <div className="card-header" >
                  <strong>{I18n.t('EXCLUDE_TOPICS')}</strong>
                  <span className={ excluded.length >= maxExclude ? "ml-2 badge badge-danger" : "ml-2 badge badge-light"}>
                    {excluded.length} / {maxExclude}
                  </span>
                </div>
                <div className="card-body tags-holder px-2 overflow-auto"  dir="ltr">
                    <div className="row no-gutters">
                      { tags.map( tag =>{
                        const isExcluded = excluded.indexOf(tag.slug)>=0
                        const isSelected = tempTags.indexOf(tag.slug)>=0
                        return (<button
                          className={"btn btn-default text-left col-6 px-3 py-2 mb-3 link topic" + ( isExcluded ? " excluded" : isSelected ? " selected" : "")}
                          key={tag.slug} onClick={this.pickTag(tag.slug)} disabled={isSelected}>
                          <i className={ isExcluded ? "icon-ban mr-2 text-danger" :
                            isSelected ? "icon-checkbox-checked mr-2 text-lightest"
                            : "icon-checkbox-unchecked mr-2 text-lightest"
                          } />
                          { isExcluded ? (
                            <del className="text-danger">
                              { I18n.t(tag.slug) }
                            </del>
                          ) : (
                            <span className={ isSelected ? "text-lighter" : "" }>
                              { I18n.t(tag.slug) }
                            </span>
                          )}

                        </button>
                        )
                      })}
                    </div>
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-primary btn-block px-3"
                    disabled={ excluded.length > maxExclude }
                    onClick={this._syncExcludeTopics}
                    >
                      { !!this.state.saved ? (
                        <i className="icon-checkmark text-secondary" />
                      ) : I18n.t('SAVE')}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    tags: state.slowly.tags,
  };
};

const syncExcludeTopics = function syncExcludeTopics(token, tags) {
  return {
    type: "SYNC_EXCLUDE_TOPICS",
    token,
    tags
  };
};

export default connect(
  mapStateToProps,
  {
    syncExcludeTopics
  }
)(ExcludedTopics);
