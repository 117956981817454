import i18n from "i18n-js";
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/ca';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fil';
import 'moment/locale/fr';
import 'moment/locale/id';
import 'moment/locale/it';
import 'moment/locale/hu';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/sv';
import 'moment/locale/sk';
import 'moment/locale/vi';
import 'moment/locale/tr';
import 'moment/locale/cs';
import 'moment/locale/ru';
// import 'moment/locale/he';
// import 'moment/locale/sr';
import 'moment/locale/th';
import 'moment/locale/uk';
import 'moment/locale/zh-tw';
import 'moment/locale/zh-cn';
import 'moment/locale/ja';
import 'moment/locale/ko';

import { availableLangLabel, extraLocale } from "../config/ApiConfig";
import {
  downloadCountries,
  getSpecialStamps,
  getExtra,
  getPaywall,
  getTopics,
  getLang
} from "../api/slowly.api";
import WebFont from "webfontloader";
// import numeral from 'numeral'
import _ from 'lodash'
import emojiSupport from 'detect-emoji-support'

const missingTranslationRegex = /^\[missing ".*" translation\]$/;
const miles = ['US','LR','MM','GB']

function getFontFamily(locale){
  switch (locale) {
    case "ar":
      return "Cairo:300,400,700&display=swap&subset=arabic,latin-ext"
    case "zh_Hant":
      return "Noto+Sans+TC:300,400,700&display=swap&subset=chinese-traditional"
    case "zh_Hans":
      return "Noto+Sans+SC:300,400,700&display=swap&subset=chinese-simplified,cyrillic,vietnamese"
    case "ja":
      return "M+PLUS+1p:300,400,700&display=swap&subset=japanese"
    case "ko":
      return "Nanum+Gothic:300,400,800&display=swap&subset=korean"
    case "th":
      return "Krub:ital,wght@0,300;0,700;1,400&display=swap"
    case "vi":
    case "ru":
    case "cs":
      return "Roboto:400,700&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese"
    case "he":
      return "Rubik:400,700&display=swap&subset=cyrillic,hebrew,latin-ext"
    default:
      return false
  }
}


moment.locale('en');

// i18n.locale = 'en';
i18n.fallbacks = true;
i18n.translations = {
  en: {
    ...availableLangLabel,
    ...require("./paywall/en"),
    ...require("./ui/en"),
    ...require("./topics/en"),
    ...require("./stamps/en")
  }
};
const subSet = "&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese"

//base
WebFont.load({
  google: {
    families: [
      "Inter:600,900",
      "Cutive+Mono",
      "Open+Sans:300,400,600,700",
      emojiSupport() ? subSet : "Noto+Color+Emoji" + subSet
    ]
  }
});

const init = async (locale = "en", ver = "1", forceRefresh = false) => {
  global.log('i18n init',locale)
  i18n.locale = _.isString(locale) ? locale : 'en';
  i18n.initialised = true;

  i18n.defaultSeparator= ':::'    // use dot in key

  if (locale === "he" || locale === "ar")
    document.body.setAttribute("dir", "rtl");
  else document.body.setAttribute("dir", "ltr");

  // numeral.locale(locale);

  document.body.className = locale;

  if (locale !== "en") {
    //special WebFont
    const fontFam = getFontFamily(locale)
    global.log('font family:' + fontFam)

    if(fontFam){
      WebFont.load({
        google: {
          families: [
            fontFam
          ]
        }
      });

    }

      
    const storedC = localStorage.getItem("countries_" + locale);
    if (!!storedC && !forceRefresh) {
      i18n.c = JSON.parse(storedC);
    } else {
      const c = await downloadCountries({ locale, ver });

      i18n.c = c;
      try{
        localStorage.setItem("countries_" + locale, JSON.stringify(c));
      }catch(error){
        console.error('Failed to cache countries data on localStorage.')
      }
    }

    const storedLang = localStorage.getItem("langstore_" + locale);
    if (!!storedLang && !forceRefresh) {
      i18n.translations[locale] = JSON.parse(storedLang);
    } else {
      global.log("get language.");

      const _stamp  = extraLocale.indexOf(locale)>=0  ? await getExtra({ locale, ver }) : null
      const _specialStamps  = await getSpecialStamps({ locale, ver })
      const _topics  = await getTopics({ locale, ver })
      const _lang  = await getLang({ locale, ver })
      const _paywall  = await getPaywall({ locale, ver })

      const newLang = {
        // ...i18n.translations[locale],
        ...availableLangLabel,
        ..._stamp,
        ..._specialStamps,
        ..._topics,
        ..._lang,
        ..._paywall
      }

      i18n.translations[locale] = newLang;

      try{
        localStorage.setItem("langstore_" + locale, JSON.stringify(newLang));
      }catch(error){
        console.error('Failed to cache language file on localStorage.')
      }
    }
  } else {
    i18n.c = require("./countries/en.json");

    if (!!forceRefresh) {
      global.log("get language.");
      const _stamp  = extraLocale.indexOf(locale)>=0  ? await getExtra({ locale, ver }) : null
      const _specialStamps  = await getSpecialStamps({ locale: 'en', ver })
      const _topics  = await getTopics({ locale: 'en', ver })
      const _lang  = await getLang({ locale: 'en', ver })
      const _paywall  = await getPaywall({ locale, ver })

      const newLang = {
        ...i18n.translations['en'],
        ...availableLangLabel,
        ..._stamp,
        ..._specialStamps,
        ..._topics,
        ..._lang,
        ..._paywall
      }

      i18n.translations['en'] = newLang;

      try{
        localStorage.setItem("langstore_en", JSON.stringify(newLang));
      }catch(error){
        console.error('Failed to cache language file on localStorage.')
      }
    }
  }

  i18n.h = require("./hello-min.json");
  const momentLocale =
    locale === "pt_BR"
      ? "pt"
      : locale === "zh_Hant"
      ? "zh-tw"
      : locale === "zh_Hans"
      ? "zh-cn"
      : locale;
  try {
    moment.locale(momentLocale);
  } catch (err) {
    global.log(err);
  }
  global.log("lang setup complete");

  return true;
};

const translateOrFallback = (initialMsg, value) => {
  // We tried to translate something else than a string
  // The native i18n function will simply crash instead of rejecting the attempt with an error message
  if (typeof initialMsg !== "string") {
    // __DEV__ &&
    global.log(
      `i18n: you must give a string to translate instead of "${typeof initialMsg}"`
    );
    return ""; // We don't return any message as we don't know what to send
  }

  const localMsg = i18n.t(initialMsg, value);

  // The translation does not exist, the default message is not very sexy
  // Instead we return the message we tried to translate
  if (missingTranslationRegex.test(localMsg) || localMsg === "") {
    const enMsg = i18n.t(initialMsg, { ...value, locale: "en" });
    if (missingTranslationRegex.test(enMsg)) return initialMsg;

    return enMsg;
  }

  return localMsg;
};

const getCountries = () => {
  return i18n.c;
};


const country = code => {
  if (!code) return "";
  if (!i18n.c) return code;
  if (!!i18n.c[code]) return i18n.c[code];

  return code;
};

const hello = code => {
  return i18n.h[code];
};

const weatherConvert = (kelvin, location_code='') => {
  const fahrenheit = ['US','BZ','BS','KY']
  if(fahrenheit.indexOf(location_code)>=0){
    return Math.round(((kelvin - 273.15)*1.8)+32) + '°F'
  }else{
    return Math.round(kelvin - 273.15) + '°C'
  }
}

const speedConvert = (mps,location_code='') => {
  if(miles.indexOf(location_code)>=0){
    return  Math.round(mps*2.237) +' mph'
  }else{
    return Math.round(mps*3.6) +' km/h'
  }
}

const countryWithMiles = (location_code) => {
  return miles.indexOf(location_code)>=0
}

const popRegions = _.shuffle([
  "US","BR","ID","TR","TW","IN","CN","PH","GB","KR","DE","VN","IT","RU","PL",
  "HK","MX","FR","JP","MY","CA","ES","NL","AU","SG","TH","AR","AE","NG","UA",
  "CO","PT","SA","IR","CL","BD","EG","PK","CZ","PE","RO","BE","HU","CH","SE",
  "ZA","AT","IE","NZ","MA","GR","AZ","DZ","FI","BY","RS","NO","EC","GH","VE",
  "OM","SK","IL","DK","HR","KE","IQ","QA","KZ","LT","DO","TN","NP","CR","LK",
  "BG","KW","MM","GM","UY","PR","SI","GT","BO","JO","PA","LB","BA","EE","PY",
  "UG","MO","LV","TT","SV","GE","BN","SD","HN"
]);

const wordCount = (text) => {
  if(!text || text==='') return 0;
  // Match latin, cyrillic, Malayalam letters and numbers
  // Source: https://github.com/jbrudvik/string-stats/blob/master/string-stats.js
	const common = '(\\d+)|[a-zA-Z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u0100-\u024F\u0374-\u058F\u05D0-\u05F4\u0622-\u0669\u066E-\u06D3\u06D5\u06EE-\u06FF\u0710-\u072F\u074D-\u074F\u0750-\u077F]+|';
	// Match Chinese Hànzì, the Japanese Kanji and the Korean Hanja
	const cjk = '\u2E80-\u2EFF\u2F00-\u2FDF\u3000-\u303F\u31C0-\u31EF\u3200-\u32FF\u3300-\u33FF\u3400-\u3FFF\u4000-\u4DBF\u4E00-\u4FFF\u5000-\u5FFF\u6000-\u6FFF\u7000-\u7FFF\u8000-\u8FFF\u9000-\u9FFF\uF900-\uFAFF';
	// Match Japanese Hiragana, Katakana, Rōmaji
	const jp = '\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\u3190-\u319F';
	// Match Korean Hangul
	const kr = '\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uAFFF\uB000-\uBFFF\uC000-\uCFFF\uD000-\uD7AF\uD7B0-\uD7FF';

  const pattern = new RegExp(
    common + '[' + cjk + jp + kr + ']',
    "g"
  );
  return (text.match(pattern) || []).length;
}

const charCount = (text) => {
  if(!text || text==='') return 0;
  return text.replace(/\s/g, '').length
}

const _export = {
  ...i18n,
  t: translateOrFallback,
  init,
  getCountries,
  hello,
  country,
  weatherConvert,
  speedConvert,
  countryWithMiles,
  randomCountry: (currentIndex)=>{
    global.log('popRegions current index: '+ currentIndex)
    if(!currentIndex && currentIndex!==0 )
    {
      const country = _.sample(popRegions)
      return { country, index: popRegions.indexOf(country)}
    }
    const newIndex = (currentIndex+1>=popRegions.length || currentIndex<0) ? 0 : currentIndex+1

    return {country: popRegions[newIndex], index: newIndex}
  },
  wordCount,
  charCount
}

export default _export
