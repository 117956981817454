import React from "react";
import I18n from "../I18n";
import Select from "react-select";
import { save, del } from "../api/drafts.api";
import { Modal } from "react-bootstrap";
import AppAlert from "../components/AppAlert";
import { toast } from "react-toastify";

export default class EditParagraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.item !== nextProps.item || this.props.currentTag !== nextProps.currentTag) {
      this.setState({
        tag: nextProps.item ? nextProps.item.tag : nextProps.currentTag,
        key: nextProps.item ? nextProps.item.key : null,
        body: nextProps.item ? nextProps.item.body : "",
        loading: false
      });
    }
  }

  _save = async () => {
    if (!!this.state.loading) return false;
    this.setState({ loading: true });
    global.log("_save", this.state);
    try {
      const updated = await save({
        token: this.props.me.token,
        payload: {
          key: this.state.key,
          tag: this.state.tag,
          body: this.state.body,
          channel: "web"
        }
      });
      global.log("saved paragraph", updated);
      if (this.props.onUpdate) this.props.onUpdate(updated);
      this.setState({ loading: false });

      toast.info("✔ " + I18n.t("AUTOMATCH_DRAFT_SAVED"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true
      });
    } catch (error) {
      global.log("_save error", error);

      this.setState({ loading: false });
    }
  };

  _onChange = tag => {
    global.log("onChange", tag);
    this.setState({
      tag: tag.value
    });
  };

  _onChangeBody = ev => {
    this.setState({
      body: ev.target.value
    });
  };

  _del = () => {
    this.setState({
      alert: {
        title: I18n.t("CONFIRM_REMOVE"),
        message: I18n.t("CONFIRM_DELETE_CANNOT_UNDONE"),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: () => {
          this.setState({ alert: null });
        },
        confirmLabel: I18n.t("DELETE"),
        confirmAction: this.confirmDel,
        danger: true
      }
    });
  };

  confirmDel = async () => {
    this.setState({ loading: true, alert: null });
    try {
      const updated = await del({
        token: this.props.me.token,
        key: this.state.key
      });

      global.log("saved paragraph", updated);
      if (this.props.onUpdate)
        this.props.onUpdate({
          key: this.state.key,
          updated_at_ms: updated.last_sync
        });
      toast.info("✔ " + I18n.t("AUTOMATCH_DRAFT_DISCARDED"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true
      });
    } catch (error) {
      global.log("_save error", error);

      this.setState({ loading: false });
    }
  };

  render() {
    const { darkMode, show } = this.props;
    const bgColor = !!darkMode ? " #2a2f39" : "#fff";

    const labels = this.props.labels.map(f => {
      return {
        label: f.name,
        value: f.id
      };
    })

    return (
      <Modal
        show={show}
        onHide={this.props.onHide}
        dialogClassName="paragraph"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <div className="w-100 position-relative">
            <h5 className="text-primary p-0 m-0">{I18n.t("MY_PARAGRAPHS")}</h5>
            <div className="position-absolute" style={{ top: -5, right: -5 }}>
              <button className="btn btn-default" onClick={this.props.onHide}>
                <i className="icon-close h5" />
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex h-100 flex-column">
            <div className="position-relative">
              <Select
                options={labels}
                isSearchable={false}
                placeholder={I18n.t("LABEL")}
                noOptionsMessage={() => I18n.t("NOT_FOUND")}
                onChange={this._onChange}
                defaultValue={
                  this.state.tag &&
                  this.props.labelKeys[this.state.tag] && {
                    label: this.props.labelKeys[this.state.tag].name,
                    value: this.state.tag
                  }
                }
                styles={{
                  input: styles => ({
                    ...styles,
                    marginLeft: 34
                  }),
                  placeholder: styles => ({
                    ...styles,
                    marginLeft: 34
                  }),
                  singleValue: (styles, { data }) => ({
                    ...styles,
                    marginLeft: 34,
                    color: null
                  }),
                  control: styles => ({
                    ...styles,
                    backgroundColor: null,
                    color: null,
                    borderColor: "#ddd"
                  }),
                  menu: styles => ({
                    ...styles,
                    backgroundColor: bgColor
                  })
                }}
                theme={
                  !!this.props.darkMode
                    ? theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral0: "#000",
                          primary25: "#222",
                          primary: "#333",
                          neutral80: "white",
                          neutral50: "white",
                          primary50: "#222",
                          neutral90: "white"
                        }
                      })
                    : null
                }
              />
              <div className="react-select-avatar">
                <i className="icon-tag p-2 d-block text-lighter" />
              </div>
            </div>
            <div className="flex-grow-1 mt-2">
              <textarea
                row={10}
                maxLength={3000}
                style={{ resize: "none", height: 320 }}
                className="form-control"
                onChange={this._onChangeBody}
                placeholder={I18n.t("MY_PARAGRAPHS_SHORT")}
                defaultValue={this.props.item && this.props.item.body}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer   className="bg-stable-darker">
          {this.state.loading ? (
            <div className="text-lighter text-center my-2 w-100">
              <small>
                <span
                  className="spinner-grow spinner-grow-sm mr-2 text-calm"
                  role="status"
                  aria-hidden="true"
                />
                {I18n.t("LOADING")}
              </small>
            </div>
          ) : (
            <div className="w-100 d-flex">
              { !!this.state.key && (
                <button
                  type="button"
                  className="btn btn-danger mr-2"
                  onClick={this._del}
                >
                  <i className="icon-trash-o" />
                </button>
              )}
              <div className="flex-grow-1">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={this._save}
                >
                  {this.props.item ? I18n.t("SAVE") : I18n.t("ADD")}
                </button>
              </div>
            </div>
          )}
        </Modal.Footer>
        <AppAlert alert={this.state.alert} />
      </Modal>
    );
  }
}
