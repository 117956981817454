import React from "react";
import I18n from "../I18n";
import { Modal } from "react-bootstrap";
import {
  reProfileMe,
  reKeyedItems
} from "../selectors";
import { connect } from "react-redux";
import StampCollection from "./StampCollection";
import ShowStamp from "./ShowStamp";
import { APP_URL} from '../config/ApiConfig'
import { updateExtendPref } from "../api/me.api"

class MyStampCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    this.props.handleClose();
  };

  showStamp = (item) => {
    this.setState({
      stamp: this.props.slowlyItems[item]
    })
  }

  closeStamp = () => {
    this.setState({
      stamp: null
    })
  }

  onToggle = async() => {
    const value = ! ((!!this.props.me.extend_pref && !!this.props.me.extend_pref['stamp_collection']) && this.props.me.extend_pref['stamp_collection'].value)

    this.props.saveExtendPref('stamp_collection', !!value ? 1 : 0);
    try {
      const result = await updateExtendPref({
        token: this.props.me.token,
        type: 'stamp_collection',
        value: !!value ? 1 : 0,
      });
      global.log('updateExtendPref success', result);
    } catch (error) {
      global.log('updateExtendPref failed', error);
    }
  };

  render() {
    const {
      show
    } = this.props;

    return (
        <Modal
          show={show}
          onHide={this.onClose}
          dialogClassName="profile"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="text-center p-0 w-100 position-relative">
              <h6 className="text-primary pt-1 mb-0">
                {I18n.t('STAMP_COLLECTION')}
              </h6>
              <span>
                { this.props.me.name }
              </span>
              <div
                className="position-absolute"
                style={{ top: 0, left: -5 }}
              >
                <a
                  className="btn btn-default"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={APP_URL + "/p/collection/" + this.props.me.hashid}
                >
                  <i className="icon-share h5" />
                </a>
              </div>
              <div
                className="position-absolute"
                style={{ top: 0, right: -5 }}
              >
                <button className="btn btn-default" onClick={this.onClose}>
                  <i className="icon-close h5" />
                </button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="stamp-collection" key="stamp-collection">
            <StampCollection
              onToggle={this.onToggle}
              toggleValue={(!!this.props.me.extend_pref && !!this.props.me.extend_pref['stamp_collection']) && this.props.me.extend_pref['stamp_collection'].value}
              showStamp={this.showStamp}
            />
          </Modal.Body>
          <ShowStamp stamp={this.state.stamp} closeStamp={this.closeStamp} />
        </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    slowlyItems: reKeyedItems(state)
  };
};

const saveExtendPref = function saveExtendPref(slug, value) {
  return {
    type: 'SAVE_EXTEND_PREF',
    slug,
    value,
  };
};

export default connect(mapStateToProps, {
  saveExtendPref
})(MyStampCollection);
